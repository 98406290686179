import moment from "moment";
import React from "react";
import Select from "../../../components/uielements/select";
import Modal from "../../../components/uielements/CustomModal";
import Input from "../../../components/uielements/input";
import Form from "../../../components/uielements/form";
import { OUTSTATION_BOOKING_TYPE, STEP_INTERVAL_BOOKING_TIME_PICKER } from "../../../utils/constants";
import CategoryDetailsModal from "../../components/categoryDetailsModal/CategoryDetailsModal";
import { getLocalstorageBooleanValue } from "../../../utils/helper-functions";
import { callAPI } from "../../apiutils";
import { API_CONSTANT,  GOOGLE_MAP_URL, GOOGLE_MAP_KEY, APPLICATION_ID } from "../../config";
// import { GOOGLE_MAP_URL } from "../../config";

const Option = Select.Option;
const FormItem = Form.Item;

export const getTimeStr = function (timeAsNumber) {
  const timeAsDecimal = timeAsNumber / 3600;
  const minutesAsDecimal = timeAsDecimal - Math.floor(timeAsDecimal);
  let minutes = parseInt(minutesAsDecimal * 60, 10);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  const hr = timeAsDecimal - minutesAsDecimal;
  // alert(minutes)
  if(minutes%5!==0){
    minutes+=1
  }

  return `${hr}:${minutes}`;
};

export const getInitialTime = function (
  timeInterval,
  delayAmount = 15,
  delayUnit = "minutes"
) {
  if(typeof window!= 'undefined'){
    // alert(parseInt(localStorage.getItem("deliveryTime")),  "ok")
    delayAmount = parseInt(localStorage.getItem("deliveryTime"))!='undefined' ? parseInt(localStorage.getItem("deliveryTime")) : 2;
  }
  const initDate = moment().utcOffset("+05:30").add(delayAmount, delayUnit);

  let minutes = parseInt(initDate.format("m"), 10);
  minutes = Math.ceil(minutes / timeInterval) * timeInterval;

  //This will set the new minutes in the moment. If any hour or day must be increased then moment will take care of it.
  initDate.set("minutes", minutes);

  // Fetch the hours and minutes from the updated moment
  const hours = initDate.format("HH");
  minutes = initDate.format("mm");

  return { initDate, initTime: `${hours}:${minutes}` };
};

export const getChangedMinimumTime = function (
  previousTime,
  previousDate,
  timeInterval,
  delayAmount = 15,
  delayUnit = "minutes"
) {

  console.log("Enters function")
  let changeInitTime = false;
  let changeInitTimeBack = false;
  console.log(delayAmount, delayUnit, "delay amount delayUnit");

  // this is the init date and time 
  const initDate = moment().utcOffset("+05:30").add(delayAmount, delayUnit);
  let minutes = parseInt(initDate.format("m"), 10);
  minutes = Math.ceil(minutes / timeInterval) * timeInterval;
  initDate.set("minutes", minutes);
  console.log(initDate.format("DD-MM-YYYY HH:mm"), 'init date and time')


  // current set time
  const previousDateString = previousDate.format("DD/MM/YYYY")
  const previousDateTimeString = previousDateString + " " + previousTime
  const previousDateTimeMoment = moment(previousDateTimeString, "DD/MM/YYYY HH:mm")


 /* 
  Four paramters to be the output of the function
  Current Set Date 
  Current Set Time  
  Init date
  Init Time 
 */

  let currentSetTime = null;
  let currentSetDate = null;
  let initTime = null;

  console.log(previousDateTimeMoment.isSame(initDate, 'days'), "days")
  // If the day is same or before the init time then change init date and init time to whatever is start init time
  // and if the day is ahead of the init date then init time is 00:00

  //This will set the new minutes in the moment. If any hour or day must be increased then moment will take care of it.
  
  console.log(moment(previousDateTimeMoment).format("DD-MM-YYYY HH:mm"), "Previous date time moment")
  console.log(moment(previousDateTimeMoment).isBefore(initDate, "minute"), moment(previousDateTimeMoment).isSame(initDate, "minute"))

  if(moment(previousDateTimeMoment).isBefore(initDate, "minute")){
    changeInitTime = true;
  }

  if(moment(previousDateTimeMoment).isAfter(initDate, "minute")){
    changeInitTimeBack = true;
  }

  // Fetch the hours and minutes from the updated moment
  let hours = "00"
  if (previousDateTimeMoment.isSame(initDate, 'days')) {
  hours = initDate.format("HH");
  minutes = initDate.format("mm");
  }else{
    hours = "00";
    minutes = "00";
  }

  console.log(changeInitTime, "changeInitTime")

  return { initDate, initTime: `${hours}:${minutes}`, changeInitTime: changeInitTime };
};

export const disabledDate = function (initDate) {
  return (current) => {
    return (
      current &&
      (current.isBefore(initDate, "days") ||
        current.isAfter(moment(initDate).add(30, "day")))
    );
  };
};

export const isInitTimeGreaterThanCurrentTime = function (
  initTime,
  currentTime
) {
  const initTimeValues = initTime.split(":");
  const initHours = parseInt(initTimeValues[0], 10);
  const initMinutes = parseInt(initTimeValues[1], 10);

  const currentTimeValues = currentTime.split(":");
  const currentHours = parseInt(currentTimeValues[0], 10);
  const currentMinutes = parseInt(currentTimeValues[1], 10);

  if (initHours > currentHours) {
    return true;
  }

  if (initHours === currentHours && initMinutes > currentMinutes) {
    return true;
  }

  return false;
};

export const renderVehicleListForCategory = function (
  vehicleList,
  vehicleListError,
  getFieldDecorator,
  categoryContainerObject,
  onCategoryChange,
  categoryString
) {
  let vehicleCategories,
    categoryDetailsComponent = (
      <span className="ant-input-group-addon">Category</span>
    );

  if (vehicleList) {
    if (vehicleList.length > 0) {
      // If the value is not valid, then pass undefined as the initialValue so that the placeholder is correctly displayed
      let initialCategoryValue;
      if (categoryContainerObject[categoryString].label) {
        initialCategoryValue = categoryContainerObject[categoryString];
      }

      vehicleCategories = getFieldDecorator(categoryString, {
        initialValue: initialCategoryValue,
        rules: [{ required: true }],
      })(
        <Select
          labelInValue
          onChange={onCategoryChange}
          placeholder="Select"
          className="select-error"
        >
          {vehicleList.map((vehicleDetails) => {
            let valueDisplay = ""
            if(vehicleDetails.displayValue){
              valueDisplay = vehicleDetails.displayValue.indexOf("Luxury") > -1
                ? vehicleDetails.displayValue.replace("Luxury ", "Lux ")
                : vehicleDetails.displayValue;
            }else{
              valueDisplay = "Car"
            }

            return (
              <Option
                key={vehicleDetails.value}
                value={vehicleDetails.value}
                className="fontSize14 vehicle_options"
              >
                {valueDisplay}
              </Option>
            );
          })}
        </Select>
      );

      categoryDetailsComponent = (
        <CategoryDetailsModal
          selectedCarCategory={categoryContainerObject}
          categoryString={categoryString}
        />
      );
    } else {
      vehicleCategories = (
        <Input className="wd-100" disabled value="No cars available" />
      );
    }
  } else if (vehicleListError) {
    vehicleCategories = (
      <Input className="wd-100" disabled value="Unable to fetch cars" />
    );
  } else {
    vehicleCategories = (
      <Input className="wd-100" disabled value="Fetching the avaiable cars" />
    );
  }

  return (
    <FormItem
      className={vehicleListError ? "select-error has-error" : "select-error"}
    >
      <span className="ant-input-wrapper ant-input-group paddingLeft10 paddingRight10">
        {categoryDetailsComponent}
        {vehicleCategories}
      </span>
    </FormItem>
  );
};

export const renderBookingPackagesForRental = function (
  bookingPackage,
  bookingPackageError,
  getFieldDecorator,
  fieldDecoratorOptions,
  onPackageChange
) {
  let bookingPackageOptions;
  if (bookingPackage) {
    if (bookingPackage.length > 0) {
      bookingPackageOptions = getFieldDecorator(
        "package",
        fieldDecoratorOptions
      )(
        <Select
          className="wd-100"
          placeholder="Select a package"
          onChange={onPackageChange}
        >
          {bookingPackage.map((item) => (
            <Option value={item}>{item.split("|")[0]}</Option>
          ))}
        </Select>
      );
    } else {
      bookingPackageOptions = (
        <Input className="wd-100" disabled value="No Packages" />
      );
    }
  } else if (bookingPackageError) {
    bookingPackageOptions = (
      <Input className="wd-100" disabled value="Unable to fetch packages" />
    );
  } else {
    bookingPackageOptions = (
      <Input className="wd-100" disabled value="Fetching packages" />
    );
  }

  return (
    <FormItem
      className={
        bookingPackageError ? "select-error has-error" : "select-error"
      }
    >
      <span className="ant-input-group-wrapper custom-date-time-wrapper paddingLeft10 paddingRight10">
        <span className="ant-input-wrapper ant-input-group ">
          <span className="ant-input-group-addon ">Packages</span>
          {bookingPackageOptions}
        </span>
      </span>
    </FormItem>
  );
};

export const validateLocation = function (
  rule,
  value,
  callBackToNotifyCompletion
) {
  if (value === "" || value === undefined) {
    callBackToNotifyCompletion([new Error()]);
    return;
  }
  callBackToNotifyCompletion();
};

export const validateOptionalNumberFields = function (
  rule,
  value,
  callBackToNotifyCompletion
) {
  if (value !== "") {
    const hoursPerDayNum = parseInt(value);
    if (hoursPerDayNum === 0) {
      callBackToNotifyCompletion([new Error()]);
      return;
    } else if (rule.max && hoursPerDayNum > rule.max) {
      callBackToNotifyCompletion([new Error()]);
      return;
    }
  }

  callBackToNotifyCompletion();
};

export const validateRequiredNumFields = function (
  rule,
  value,
  callBackToNotifyCompletion
) {
  const numMonthNumber = parseInt(value);
  if (isNaN(numMonthNumber) || numMonthNumber === 0) {
    callBackToNotifyCompletion([new Error()]);
    return;
  }
  if (rule.max) {
    if (value !== "") {
      const hoursPerDayNum = parseInt(value);
      if (hoursPerDayNum === 0) {
        callBackToNotifyCompletion([new Error("value")]);
        return;
      } else if (rule.max && hoursPerDayNum > rule.max) {
        callBackToNotifyCompletion([new Error("val")]);
        return;
      }
    }
  }

  callBackToNotifyCompletion();
};

export const populateDaysPerWeekInSummary = function (daysPerWeekArray) {
  let daysPerWeek = "-";
  if (daysPerWeekArray && daysPerWeekArray.length > 0) {
    daysPerWeek = "";
    daysPerWeekArray.forEach((day) => {
      daysPerWeek += `${day}, `;
    });

    //Remove the whitespace as well as the trailing comma.
    daysPerWeek = daysPerWeek.slice(0, daysPerWeek.length - 2);
  }

  return daysPerWeek;
};

export const onOutstationBooking = function (onOkCallback, errorMessage) {
  Modal.info({
    title: "Outstation",
    icon: null,
    className: "custom-modal modal-align-title width500",
    content: (
      <div className="font-size16">
        <div>
          <p>{errorMessage}</p>
        </div>
      </div>
    ),
    maskClosable: false,
    okText: "GOT IT",
    okButtonProps: { block: true },
    onOk: onOkCallback,
  });
};

export const parseAddress = function (addressToParse) {
  let parsedAddress = {};
  if (addressToParse) {
    parsedAddress = { ...addressToParse };
    if (parsedAddress.latitude) {
      parsedAddress.latitude = parseFloat(parsedAddress.latitude);
    }
    if (parsedAddress.longitude) {
      parsedAddress.longitude = parseFloat(parsedAddress.longitude);
    }
  }

  return parsedAddress;
};

export const calculateFareDetails = function (
  distanceProperties,
  searchCriteria
) {

  let dist = 0,
    addKmFare,
    numDays = 0,
    driverRate = 0,
    driverDays = 0,
    baseFare = searchCriteria.vehicleDetails.rate,
    time = 0;

  // Will be empty for the local/rental case
  if (distanceProperties) {
    //Because of this difference the distance can be negative
    dist =
      Math.ceil(distanceProperties[0].legs[0].distance.value / 1000) -
      searchCriteria.vehicleDetails.minimumKms;

    time =
      searchCriteria.vehicleDetails.minimumHours * 60 -
      Math.ceil(distanceProperties[0].legs[0].duration.value / 60);
  }

  addKmFare =
    (dist > 0 ? dist : 0) *
    (searchCriteria.vehicleDetails.ratePerKm
      ? searchCriteria.vehicleDetails.ratePerKm
      : 0);

  if (searchCriteria.bookingType === OUTSTATION_BOOKING_TYPE) {

    const pickupTimeMoment = moment(searchCriteria.pickupTimeStamp);

    const numHours = moment(searchCriteria.returnTimeStamp).diff(
      pickupTimeMoment,
      "hours",
      true
    );

    //For the bookings a grace period of 1 hour is provided before the next day's charges are applied
    // This will convert 24.2 hours to 25 hours
    numDays = Math.ceil((Math.ceil(numHours) - 1) / 24);

    if(searchCriteria && searchCriteria.vehicleDetails && searchCriteria.vehicleDetails.rate && searchCriteria.vehicleDetails.rate.minimumKms){

      const minimumKms = searchCriteria.vehicleDetails.rate.minimumKms * numDays;

      // For outstation the distance value will be doubled because you have to take a return jorney too
      //TODO will need to be changed to whether or not it is return because distanceProperties will always be there, added for safety for now
      if (distanceProperties) {
        dist =
          Math.ceil(distanceProperties[0].legs[0].distance.value / 1000) * 2 -
          minimumKms;
        addKmFare =
          (dist > 0 ? dist : 0) * searchCriteria.vehicleDetails.rate.ratePerKm;
      }

      baseFare = searchCriteria.vehicleDetails.rate.rate * numDays;

      // Go forward till 12 am and subtract the hours till today's end of day.
      const numHoursToMidNight = moment(pickupTimeMoment)
        .add(1, "days")
        .startOf("day")
        .diff(pickupTimeMoment, "hours", true);

      /*
        Calculate the remaining hours in the trip excluding the tim till midnight.
        That is if the booking was scheduled for 10 PM then discount 2 hours till midnight
        This aids in the driver calculation below.
      */
      const remainingTripHours = numHours - numHoursToMidNight;
      driverDays = 1;

      /*
        The driver charges are per day(00:00 to 23:59) rather than per booking day (pickup time + 24 hrs)
        The remiaining hours accounts for the time till midnight from the scheduled day of the booking
        Hence the total days that a driver will be driving can be calulated by dividing the remaining trip hours by 24 hours,
        because now the calculation becomes 00:00 to 23:59 for the remaining hours. Even if a minute more than 24 hours has passed
        add to the driver days
      */
      driverDays += Math.ceil(remainingTripHours / 24);
      driverRate = searchCriteria.vehicleDetails.rate.driverDayAllowance * driverDays;
    }else{
      const minimumKms = searchCriteria.vehicleDetails.minimumKms * numDays;

      // For outstation the distance value will be doubled because you have to take a return jorney too
      //TODO will need to be changed to whether or not it is return because distanceProperties will always be there, added for safety for now
      if (distanceProperties) {
        dist =
          Math.ceil(distanceProperties[0].legs[0].distance.value / 1000) * 2 -
          minimumKms;
        addKmFare =
          (dist > 0 ? dist : 0) * searchCriteria.vehicleDetails.ratePerKm;
      }

      baseFare = searchCriteria.vehicleDetails.rate * numDays;

      // Go forward till 12 am and subtract the hours till today's end of day.
      const numHoursToMidNight = moment(pickupTimeMoment)
        .add(1, "days")
        .startOf("day")
        .diff(pickupTimeMoment, "hours", true);

      /*
        Calculate the remaining hours in the trip excluding the tim till midnight.
        That is if the booking was scheduled for 10 PM then discount 2 hours till midnight
        This aids in the driver calculation below.
      */
      const remainingTripHours = numHours - numHoursToMidNight;
      driverDays = 1;

      /*
        The driver charges are per day(00:00 to 23:59) rather than per booking day (pickup time + 24 hrs)
        The remiaining hours accounts for the time till midnight from the scheduled day of the booking
        Hence the total days that a driver will be driving can be calulated by dividing the remaining trip hours by 24 hours,
        because now the calculation becomes 00:00 to 23:59 for the remaining hours. Even if a minute more than 24 hours has passed
        add to the driver days
      */
      driverDays += Math.ceil(remainingTripHours / 24);
      driverRate = searchCriteria.vehicleDetails.driverDayAllowance * driverDays;
    }
  }

  console.log(
    {
      rate: {
        addDist: dist > 0 ? dist : 0,
        numDays,
        driverDetails: {
          driverRate,
          driverDays,
        },
        total: driverRate + addKmFare + baseFare,
        transfer: searchCriteria.bookingType !== OUTSTATION_BOOKING_TYPE,
        addTime: time,
      },
    },
    addKmFare,
    baseFare
  );
  return {
    rate: {
      addDist: dist > 0 ? dist : 0,
      numDays,
      driverDetails: {
        driverRate,
        driverDays,
      },
      total: driverRate + addKmFare + baseFare,
      transfer: searchCriteria.bookingType !== OUTSTATION_BOOKING_TYPE,
      addTime: time,
    },
  };
};

export const parseGooglePlaceComponents = function (address_components) {
  const newAddressComponents = {};
  let type;

  if (address_components) {
    address_components.forEach((address_component) => {
      type = address_component.types[0];
      newAddressComponents[type] = address_component.long_name;
    });
  }

  return newAddressComponents;
};

export const createRequestForEventLongTerm = function (
  formValues,
  componentData,
  pickupDate,
  pickupTime,
  bookingType
) {
  const finalPickTime = pickupDate.format("YYYY-MM-DD") + " " + pickupTime;

  const { pickupAddress } = componentData;
  const currentAddressComponents = pickupAddress.addressComponents;
  const latitude = pickupAddress.latitude;
  const longitude = pickupAddress.longitude;
  const locality = currentAddressComponents.locality;

  const addressComponents = {
    locality,
    administrative_area_level_1:
      currentAddressComponents.administrative_area_level_1,
    postal_code: currentAddressComponents.postal_code,
    country: currentAddressComponents.country,
  };

  const inquiryData = {
    noOfCars: formValues.numberOfCars,
    vehicleCategory: formValues.vehicleCategory.key,
    vehicleCategoryDisplay: formValues.vehicleCategory.label,
    usage: formValues.usage,
    personalUse: !getLocalstorageBooleanValue("businessEnable"),
    bookerContact: localStorage.getItem("phoneNumber"),
    bookerEmailId: localStorage.getItem("emailAddress"),
    bookingType: bookingType,
    pickupTime: moment(finalPickTime, "YYYY-MM-DD H:mm", true).valueOf(),
    remark: formValues.remark,
    customerPhoneNumber: localStorage.getItem("phoneNumber"),
    customerName: localStorage.getItem("username"),
    pickupAddress: {
      fullTextAddress: pickupAddress.fullTextAddress,
      latitude,
      city: locality,
      longitude,
      addressComponents,
      coordinates: [longitude, latitude],
    },
  };

  if (formValues.noOfDays !== undefined || formValues.noOfDays !== null) {
    inquiryData.noOfDays = formValues.noOfDays;
  }

  if (formValues.noOfMonths !== undefined || formValues.noOfMonths !== null) {
    inquiryData.noOfMonths = formValues.noOfMonths;
  }

  if (formValues.kmPerMonth !== undefined || formValues.kmPerMonth !== null) {
    inquiryData.kmPerMonth = formValues.kmPerMonth;
  }

  if (formValues.hoursPerDay !== undefined || formValues.hoursPerDay !== null) {
    inquiryData.hoursPerDay = formValues.hoursPerDay;
  }

  if (componentData.daysPerWeek && componentData.daysPerWeek.length > 0) {
    inquiryData.daysPerWeek = componentData.daysPerWeek;
  }

  return inquiryData;
};

export const createAddressForSaving = function (addressInfo) {
  let addressComponentsToSave;
  if (addressInfo) {
    const { addressComponents } = addressInfo;
    if (addressComponents) {
      addressComponentsToSave = {
        locality: addressComponents.locality,
        administrative_area_level_1:
          addressComponents.administrative_area_level_1,
        postal_code: addressComponents.postal_code,
        country: addressComponents.country,
      };
    }
  }

  return addressComponentsToSave;
};

export const validateLocationsPickup = async (
  local,
) => {
  console.log(local);
  const BOUNDARY_API = `duty/boundary/${local.postal_code}`;
  let status = 200;
  let message = ""
  let res = await fetch(API_CONSTANT + BOUNDARY_API, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "sourceApp": "customerWeb",
      "applicationId": APPLICATION_ID
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((response) => {
    status = response.status;
    return response.json()
  });
  message = await res.message;
  let final_response = await {
    "message": res.message,
    "status": status
  }
  return final_response
};

export const getAddressComponents = async (
  data
) => {
  console.log(data);
  let BOUNDARY_API;
  // if(pickup && pickup.postal_code){
  //   BOUNDARY_API = `duty/allowed/${pickup.postal_code}/${local.postal_code}`;
  // }
  // else{
  //   BOUNDARY_API = `duty/allowed//${local.postal_code}`;
  // }
  // if(type){
  //   BOUNDARY_API+=`?type=${type}`
  // }
  BOUNDARY_API = `https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=${GOOGLE_MAP_KEY}`
  let status = 200;
  const res = await fetch(BOUNDARY_API, {
    method: "GET",
    headers: {
      "sourceApp": "customerWeb",
      // "applicationId": APPLICATION_ID
      // "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // body: JSON.stringify(data)
  }).then((response) => {
    status = response.status;
    return response.json();
  });
  let final_response = await {
    "message": res.message,
    "status": status
  }
  return final_response
};

export const validateLocationsDrop = async (
  local,
  pickup,
  type
) => {
  console.log(local);
  let BOUNDARY_API;
  if(pickup && pickup.postal_code){
    BOUNDARY_API = `duty/allowed/${pickup.postal_code}/${local.postal_code}`;
  }
  else{
    BOUNDARY_API = `duty/allowed//${local.postal_code}`;
  }
  if(type){
    BOUNDARY_API+=`?type=${type}`
  }
  let status = 200;
  console.log(APPLICATION_ID, "COMES HERE")
  const res = await fetch(API_CONSTANT + BOUNDARY_API, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "sourceApp": "customerWeb",
      "applicationId": APPLICATION_ID
    },
  }).then((response) => {
    status = response.status;
    return response.json();
  });

  let final_response = await {
    "message": res.message,
    "status": status
  }
  return final_response
};

export const calcValidReturnDateTime = (pickupDate, pickupTime, count=8, unit = 'hours') => {
  
  // console.log("----------Input function-------------------")
  // console.log(pickupDate, pickupTime,"check diff")
  // console.log(pickupDate, pickupTime, count, unit, moment(pickupDate).add(1, 'days'), moment(pickupDate).format("YYYY-MM-DD"),moment(pickupTime).format("H:mm"), "In function")
  let finalDateTime = moment(pickupDate).format("YYYY-MM-DD") + " " + pickupTime;
  let finalDateTimeString = moment(finalDateTime).add(count, 'hours').format("YYYY-MM-DD HH:mm")
  let finalDateString = moment(finalDateTime).add(count, 'hours').format("YYYY-MM-DD")
  let finalTimeString = moment(finalDateTime).add(count, 'hours').format("HH:mm")
  // console.log(finalTimeString, "time string")
  // console.log(finalDateTime,finalDateString, moment(finalDateTime).add(count, unit),finalTimeString, "Final")
  return { "date": moment(finalDateString), "time": finalTimeString}
}

export const calcAllTimeFields = (pickupInitDate="",pickupInitTime="",returnInitDate="", returnInitTime="",pickupDate="", pickupTime="", returnDate="", returnTime="") => {
  const timeFields = {
    "pickupTime": "",
    "pickupDate": "",
    "pickupStartTime": "",
    "returnStartTime": "",
    "returnTime": "",
    "returnDate": ""
  }

  if(typeof pickupDate==="object"){
    pickupInitDate = moment(pickupInitDate).format("YYYY-MM-DD")
  }else if(pickupDate==""){
    pickupInitDate = getInitialTime(STEP_INTERVAL_BOOKING_TIME_PICKER).initDate.format("YYYY-MM-DD")
  }

  if(typeof pickupTime==="object"){
    pickupInitTime = moment(pickupInitTime).format("HH:MM")
  }else if(pickupInitTime==""){
    pickupInitTime = getInitialTime(STEP_INTERVAL_BOOKING_TIME_PICKER).initTime
  }

  if(typeof returnInitDate==="object"){
    returnInitDate = moment(returnInitDate).format("YYYY-MM-DD")
  }else if(returnInitDate==""){
    returnInitDate = calcValidReturnDateTime(moment(pickupInitDate), getInitialTime(STEP_INTERVAL_BOOKING_TIME_PICKER).initTime).date.format("YYYY-MM-DD")
  }

  if(typeof returnInitTime==="object"){
    returnInitTime = moment(returnInitTime).format("HH:MM")
  }else if(returnTime==""){
    returnInitTime = calcValidReturnDateTime(moment(pickupInitDate), pickupInitTime).time
  }

  let returnInitFullTime = returnInitDate + " " + returnInitTime
  console.log("Initializing pickupDate: ", pickupInitDate)
  console.log("Initializing pickupTime: ", pickupInitTime)
  console.log("Initializing returnDate: ", returnInitDate)
  console.log("Initializing return Time: ", returnInitTime)

  // if(pickupDate==""){
  //   pic
  // }

}