import {API_CONSTANT, APPLICATION_ID} from './config';

const formQueryString = (queryParamObj) => {
  return Object.keys(queryParamObj)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParamObj[k]))
    .join('&');
}

const defaultDirectives = {
  jsonResponse: true
};

export const callAPI = async (url, method='GET', postData, queryParams, directives) => {
  directives = {...defaultDirectives, ...directives};

  if (queryParams) {
    url += `?${formQueryString(queryParams)}`
  }

  let options
  if (url.indexOf('registered') != -1) {
    options = {
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'text/html',
        'sourceApp': 'customerWeb',
        'apiToken': getLocalstorage('apiToken'),
        'username': getLocalstorage('phoneNumber'),
        'applicationId': APPLICATION_ID
      }
    };
  } else {
    options = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'sourceApp': 'customerWeb',
        'apiToken': getLocalstorage('apiToken'),
        'username': getLocalstorage('phoneNumber'),
        'applicationId': APPLICATION_ID
      }
    }
  }

  if(directives.additionalHeaders) {

    //Merge the provided headers with the default headers
    options.headers = {...options.headers, ...directives.additionalHeaders};
  }

  options['method'] = method;
  if (postData) {
    options['body'] = JSON.stringify(postData);
  }

  return await fetch(API_CONSTANT + url, options)
    .then(res => handleResponse(res, directives))
}

function handleResponse(res, directives) {
  if(res.ok) {
    if(res.headers.get("Content-Type") == "text/html;charset=UTF-8") {
      return res.text()
    }
    else {

      if(directives && directives.jsonResponse) {
        return res.json()
          .then(json => json);
      } else {
        return Promise.resolve();
      }
    }
  } else if(res.status === 404) {
    return Promise.resolve(Object.assign({}, res, {
      status: res.status,
      statusText: res.error,
    }));
  } else {
    return Promise.reject(Object.assign({}, res, {
      status: res.status,
      statusText: res.error
    }));
  }
  
}

function getLocalstorage(name) {
  return localStorage.getItem(name);
}
