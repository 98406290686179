import React, { Component, createRef } from 'react';
import Image2 from '../../image/static/features/AdvanceBooking_icon.png';
import Image3 from '../../image/static/features/NoCharges_icon.png';
import Image4 from '../../image/static/features/CarCategory_icon.png';
import Image5 from '../../image/static/features/AuditedCars_icon.png';
import Image6 from '../../image/static/features/VerifiedDrivers_icon.png';
import Image7 from '../../image/static/features/CarsDrivers_icon.png';
import Image8 from '../../image/static/features/SurgePricing_icon.png';
import Image9 from '../../image/static/features/Billing_icon.png';
import Image10 from '../../image/static/features/CustomerSupport_icon.png';
import Image11 from '../../image/static/features/BestPrice_icon.png';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class Features extends Component {
  constructor() {
    super();
    this.featuresContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.featuresContainer.current, 'feature');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.featuresContainer.current, 'feature');
  }

  handleFeatureContainerMount = (refVal) => {
    if(refVal) {
      this.featuresContainer.current = refVal;
    }
  }

  render() {
    return (
      <div className="bg-feature feature" id="feature">
        <div className="text-align-center color-Black">Features</div>
        <div className="feature__section-container" ref={this.handleFeatureContainerMount}>
          <div className='feature__section-container__row'>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="No Charges For Booking/Scheduling Your Cab Ride In Advance" data-src={Image2} />
              <p className="color-Black fS18 paddingTop20">Free Advance Booking</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="No Cancellation Charges On Cancelling Your Cab Booking" data-src={Image3} />
              <p className="color-Black fS18 paddingTop20">No Cancellation Charges<br />Till one hour prior to pickup</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Range Of Cars From Dzire, Etios, Innova, Erigta, Innova Crysta, City Etc. To Choose From For Your Airport, Local/Rentals, Outstation Ride" data-src={Image4} />
              <p className="color-Black fS18 paddingTop20">A range of car categories<br /> to choose from</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Each Car On Yellow Plate Marketplace Goes Through A 32 Point Check On Interior, Exterior And Facilities" data-src={Image5} />
              <p className="color-Black fS18 paddingTop20">Audited cars</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Each Of The Driver On Yellow Plate Car Rental Marketplace Is Police Verified" data-src={Image6} />
              <p className="color-Black fS18 paddingTop20">Verified drivers</p>
            </div>
          </div>
          <div className='feature__section-container__row'>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Drivers On Yellow Plate Go Through An Extensive Training On Keeping The Car Clean, Delivering Service Etc" data-src={Image7} />
              <p className="color-Black fS18 paddingTop20">Clean cars and courteous <br /> uniformed drivers</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Prices On Yellow Plate Car Rental Marketplace Shall Be Fixed And Customers Won't Have To Pay For Any Surge Prices In Case Of High Demand." data-src={Image8} />
              <p className="color-Black fS18 paddingTop20">No surge pricing</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Yellow Plate Car Rental Marketplace Bookings Have Point Ot Point Pricing And Transparent Billing. No Hidden Charges." data-src={Image9} />
              <p className="color-Black fS18 paddingTop20">Transparent door/point to <br /> door/point billing</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="We Are Here To Help. Contact Us 24/7, 365 Days A Year Via Email, Phone, WhatsApp Or Provided Contact Form" data-src={Image10} />
              <p className="color-Black fS18 paddingTop20">24/7 customer support</p>
            </div>
            <div className="margin10 padding20 text-align-center bcwhite feature__card-container">
              <img alt="Yellow Plate Provides Best Price Guarantee For Local/Rentals, Outstation, Events, Long Term Car Rental Bookings." data-src={Image11} />
              <p className="color-Black fS18 paddingTop20">Best price guarantee</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
