import { all } from 'redux-saga/effects';
import bookingSagas from './Booking/sagas';
import locationSagas from './location/location-saga';

export default function* devSaga() {
  yield all([
    bookingSagas(),
    locationSagas()
  ]);
}
