import logo from './logo.svg';
import './App.less';
import './style/styles.less'
import DashApp from './dashApp';

function App() {
  console.log(process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_API,  process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_STATIC)
  return (
    <div className="App">
      <DashApp />
    </div>
  );
}

export default App;
