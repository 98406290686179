import {
    HOME_TRANSFER_URL,
    HOME_RENTAL_URL,
    HOME_OUTSTATION_URL,
    HOME_EVENT_URL,
    HOME_LONG_TERM_URL,
    HOME_CUSTOMIZE_URL,
    SEARCH_CABS_TRANSFER_URL,
    SEARCH_CABS_RENTAL_URL,
    SEARCH_CABS_OUTSTATION_URL,
    SEARCH_CABS_EVENT_URL,
    SEARCH_CABS_LONG_TERM_URL,
    CONFIRM_BOOKING,
    CONFIRMED,
    SIGNUP_URL,
    TERMS_CONDITIONS,
    PRIVACY,
    PROFILE_EDIT,
    PROFILE_VIEW_URL,
    CONFIRMED_URL,
    CONFIRM_BOOKING_URL,
    MYBOOKING_URL,
    BOOKING_DETAILS_URL,
    PROFILE_EDIT_URL
  } from './constants';

  export const description =  {
    [HOME_TRANSFER_URL]: {
        meta:"India’s first car rental marketplace. Book cabs for city travel, airport transfers, outstation cabs, events, long term via marketplace or your own/company’s vendors.  ",
        title:"India’s first Car Rental Marketplace. Book Airport, Rental, Outstation Cabs ",
    },
    [HOME_CUSTOMIZE_URL]: {
        meta:"Custom made car rental solutions for employee transport, executives travel, hotels, event/MICE companies and retail customers via Yellow Plate car rental marketplace. ",
        title:"Customized Car Rental For City Tour, Outstation, Events, Weddings, Long Term.",
    },
    [HOME_EVENT_URL]: {
        meta:"For the first time in India enquire for any number of cabs for any duration for your/company’s events, meetings, conferences, incentives, weddings. ",
        title:"Car Rental for Meetings, Incentives, Conferences, Events or Weddings.",
    },
    [HOME_LONG_TERM_URL]: {
        meta:"First in India. Enquire for Long term car rental solutions for Companies, Hotels and Retail customers from Yellow Plate’s variable and fixed car rental packages.",
        title:"Long Term, Monthly, Yearly Car Rental for Hotels, Companies or Personal usage. " 
    },
    [HOME_OUTSTATION_URL]: {
        meta:"Affordable outstation cabs from Mumbai to Pune, Shirdi, Daman, Goa, Nashik, Lonavala, Alibag and many more destinations. Point to point pricing and no hidden charges.",
        title:"Book Affordable Outstation Cabs. Book Etios, Innova, Crysta for Outstation. ",
    },
    [HOME_RENTAL_URL]: {
        meta:"Most affordable car rental for city travel, meetings. Books cabs by hours or full or half day. Best price guarantee. Book cabs from marketplace or your own vendors.",
        title:"Book Cabs for City Travel By Hours or Half or Full Day. Lowest Price Guarantee",
    },
    [SEARCH_CABS_EVENT_URL]: {
        meta:"Book cabs for your events/MICE. Now enquire for any number of cars for any duration for your/company’s events, meetings, conferences, incentives, weddings.  ",
        title:"Book Any Number of Cabs for Events, Weddings ",
    },
    [SEARCH_CABS_LONG_TERM_URL]: {
        meta:"Car rental service on long term basis. Book cabs for a month or year. Hotels, Companies can get customised long term car rental solutions via the marketplace.  ",
        title:"Monthly, Yearly, Long Term Car rental from car rental agencies near you.",
    },
    [SEARCH_CABS_OUTSTATION_URL]: {
        meta:"Best car rental services for your outstation travel. Book affordable roundtrip outstation cabs from Mumbai to any destination.",
        title:"Affordable Outstation car rental from car rental agencies near you ",
    },
    [SEARCH_CABS_RENTAL_URL]: {
        meta:"Affordable cabs for Local city travel. Books cabs by hours or full or half day. Best price guarantee. Book Local rental cabs from the marketplace or your own vendors.",
        title:"Book cabs by hours for meetings, city travel, full or half day ",
    },
    [SEARCH_CABS_TRANSFER_URL]: {
        meta:"Assured Early morning or late night airport taxi service. Now book cabs for airport or travel anywhere within city via Yellow Plate car rental marketplace.",
        title:"Book cabs for city travel, early morning or late night airport transfer",
    },
    [SIGNUP_URL]: {
        meta:"Users booking cabs for personal or Business usage can sign up to avail Local/rental, Transfers, Outstation etc. car rental services form the marketplace or their own/company’s vendors.",
        title:"Register on India’s first car rental marketplace platform for booking cabs",
    },
    [TERMS_CONDITIONS]: {
        meta:"Read Yellow Plate car rental marketplace terms and conditions carefully to understand how you can use Yellow Plate car rental marketplace.",
        title:"Yellow Plate car rental marketplace Terms and Conditions",
    },
    [PRIVACY]: {
        meta:"Yellow Plate car rental marketplace is an enabling and empowering ecosystem for demand and supply stakeholders and has kept users privacy at the centre.  ",
        title:"Yellow Plate Car Rental India Marketplace Privacy Policy.",
    },
    [CONFIRM_BOOKING_URL]: {
        meta: "Confirm Booking to get assured of a hassle free and comfortable ride with our full assistance.",
        title: "Confirm booking to have a comfortable ride between pickup and drop location."
    },
    [CONFIRMED_URL]: {
        meta: "Confirm Booking to get assured of a hassle free and comfortable ride with our full assistance.",
        title: "Thank You for choosing Yellow Plate to serve you and provide best services from your own car rental marketplace."
    },
    [PROFILE_VIEW_URL]:{
        title: "Profile || Yellow Plate",
        meta: "Manage your profile on Yellow Plate."
    },
    [PROFILE_EDIT_URL]:{
        title: "Edit Profile || Yellow Plate",
        meta: "Edit your profile on Yellow Plate."
    },
    [BOOKING_DETAILS_URL]:{
        title: "Booking Details || Yellow Plate",
        meta: "View the bookings that you have created on Yellow Plate."
    },
    [MYBOOKING_URL]: {
        title: "My Bookings || Yellow Plate",
        meta: "Listed below are the details of your bookings with Yellow Plate."
    },
    "NOT_FOUND": {
        meta:"India’s first car rental marketplace. Book cabs for city travel, airport transfers, outstation cabs, events, long term via marketplace or your own/company’s vendors.",
        title:"Yellow Plate Page Not Found",
    },
}

export const setTitleMeta = function(path){
    if(!path){
        console.error('Path is a necessary parameter');
        return
    }
    document.title = description[path].title;
    document.querySelector('meta[name="description"]').setAttribute("content", description[path].meta); 
}


