import React, { Component } from 'react';
import {Col, Row} from 'antd';
import { Link } from 'react-router-dom';

export default class MobileSidebar extends Component {

  renderLink(optionDetails) {
    return (
      <div key={optionDetails.name + optionDetails.url} >
        <Link className={optionDetails.bookingType === this.props.bookingType ? 'mobilebar-active': 'mobilebar-opt'}
          to={{pathname: optionDetails.pathname, state: optionDetails.state}}
        >
          <span >
            <span className={optionDetails.bookingType === this.props.bookingType ? 'paddingLeft10 paddingRight10' : 'paddingLeft10 paddingRight10'}>
              { optionDetails.name!='LONG TERM HIRE'? optionDetails.name : 'LONGTERM' }
            </span>
          </span>
        </Link>
      </div>
    );
  }

  render() {
    const {sideBarOptions} = this.props;
    return (
      <div className={'side-bar--mobile-container'}>
        <Row className={'side-bar--mobile-content'}>
        {
            sideBarOptions.map(optionsDetails => {
              return (
                <Col className="side-bar--options">
                  {this.renderLink(optionsDetails)}
                </Col>
              )
            })
          }
        </Row>
      </div>
    );
  }
}
