const actions = {
  GET_MISSING_ROUTE_PROGRESS: 'GET_MISSING_ROUTE_PROGRESS',
  GET_MISSING_ROUTE_SUCCESS: 'GET_MISSING_ROUTE_SUCCESS',
  GET_MISSING_ROUTE_FAILURE: 'GET_MISSING_ROUTE_FAILURE',
  GET_MISSING_ROUTE_UNLOAD: 'GET_MISSING_ROUTE_UNLOAD',

  getMissingRoute: (tripLocationInfo) => ({
    type: actions.GET_MISSING_ROUTE_PROGRESS,
    tripLocationInfo
  }),
  getMissingRouteSuccess: (routeInfoSuccess) => ({
    type: actions.GET_MISSING_ROUTE_SUCCESS,
    routeInfoSuccess
  }),
  getMissingRouteFailure: (errorInfo) => ({
    type: actions.GET_MISSING_ROUTE_FAILURE,
    errorInfo
  }),
  getMissingRouteUnload: () => ({
    type: actions.GET_MISSING_ROUTE_UNLOAD
  })
};

export default actions;