import React, { Component, createRef } from 'react';
import fleetImage1 from '../../image/static/fleet/fleet_bg_1366.png';
import Image2 from '../../image/static/fleet/Hatchback_icon.png';
import Image3 from '../../image/static/fleet/ExecutiveSedan_icon.png';
import Image4 from '../../image/static/fleet/ExecutiveSUV_icon.png';
import Image5 from '../../image/static/fleet/PremiumSUV_icon.png';
import Image6 from '../../image/static/fleet/PremiumSedan_icon.png';
import Image7 from '../../image/static/fleet/SUV_icon.png';
import Image8 from '../../image/static/fleet/Sedan_icon.png';
import Image9 from '../../image/static/fleet/LuxurySedan_icon.png';
import Image10 from '../../image/static/fleet/LuxurySUV_icon.png';

import { Row } from 'antd';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class FleetSection extends Component {
  constructor() {
    super();

    this.fleetContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.fleetContainer.current, 'fleet');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.fleetContainer.current, 'fleet');
  }

  handleFeatureContainerMount = (refVal) => {
    if(refVal) {
      this.fleetContainer.current = refVal;
    }
  }

  render() {
    return (
      <div id="fleet" className="pos-relative overflow--hidden" ref={this.handleFeatureContainerMount}>
        <img
          alt="A Number Of Car Categories From Hatchback, Sedan, SUV, Luxury Etc., Available On Rent Via Yellow Plate Car Rental Marketplace "
          className={'img-height-full'}
          data-src={fleetImage1}
        />
        <Row className="position-abs zIndex9 right5p fleet-container">

          <div className="fS31 text-align-center paddingBtm10 color-white">Fleet</div>

          <div className="flex-column-align">

            <div className="flex-row-align">
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Hatchback I.E. Indica, Swift, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc." data-src={Image2} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Hatchback</p>
                <p className="text-align-center color-Black font-size12">Indica, Swift,etc.</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Sedan I.E. Dzire, Etios, Xcent, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc." data-src={Image8} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Sedan</p>
                <p className="text-align-center color-Black font-size12">Dzire, Xcent, Amaze, Etios,etc.</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Executive Sedan I.E. City, Sunny, Vento, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc." data-src={Image3} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Executive Sedan</p>
                <p className="text-align-center color-Black font-size12">Sunny, City, Scala, etc.</p>
              </div>
            </div>

            <div className="flex-row-align">
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Premium Sedan I.E. Altis, Jetta, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc. " data-src={Image6} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Premium Sedan</p>
                <p className="text-align-center color-Black font-size12">Camry, Altis, etc.</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Ertiga, Tavera, Enjoy, TUV, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc. " data-src={Image7} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">SUV</p>
                <p className="text-align-center color-Black font-size12">Ertiga, Enjoy, TUV, etc.</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Executive SUV I.E. Innova Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc." data-src={Image4} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Executive SUV</p>
                <p className="text-align-center color-Black font-size12">Innova</p>
              </div>
            </div>

            <div className="flex-row-align">
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Premium SUV I.E. Innova Crysta, XUV 500, Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc." data-src={Image5} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Premium SUV</p>
                <p className="text-align-center color-Black font-size12">XUV 500, Innova Crysta</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Luxury Sedans I.E., Mercedes, BMW, Audi Etc., On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc. " data-src={Image9} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Luxury Sedan</p>
                <p className="text-align-center color-Black font-size12">BMW, Mercedes, Audi</p>
              </div>
              <div className="border-common-black bcwhite fleet-image--container">
                <img alt="Luxury SUV I.E. Mercedes, BMW, Audi Etc., Cars On Rent For Airport, City Taxi, Local/Rentals, Outstation, Events, Long Term Etc. " data-src={Image10} className="center-img fleet-image" />
                <p className="paddingTop10 text-align-center color-Black fS13">Luxury SUV</p>
                <p className="text-align-center color-Black font-size12">BMW, Mercedes, Audi, Fortuner, etc.</p>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
