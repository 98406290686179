const actions = {
  POST_CONFIRM_CAB_BOOKING : 'POST_CONFIRM_CAB_BOOKING',
  POST_CONFIRM_CAB_BOOKING_SUCCESS: 'POST_CONFIRM_CAB_BOOKING_SUCCESS',
  POST_CONFIRM_CAB_BOOKING_FAILURE: 'POST_CONFIRM_CAB_BOOKING_FAILURE',
  POST_CANCEL_BOOKING:'POST_CANCEL_BOOKING',
	POST_CANCEL_BOOKING_SUCCESS:'POST_CANCEL_BOOKING_SUCCESS',
	POST_CANCEL_BOOKING_FAILURE:'POST_CANCEL_BOOKING_FAILURE',
	UNLOAD_CANCEL_BOOKING:'UNLOAD_CANCEL_BOOKING',
	POST_SIGNUP : "POST_SIGNUP",
  POST_SIGNUP_SUCCESS : "POST_SIGNUP_SUCCESS",
  POST_SIGNUP_FAILURE : "POST_SIGNUP_FAILURE",
  CLEAR_LOGIN_INFO: 'CLEAR_LOGIN_INFO',
	SEND_OTP:"SEND_OTP",
	SEND_OTP_SUCCESS:"SEND_OTP_SUCCESS",
	POST_CUSTOM_BOOKING:"POST_CUSTOM_BOOKING",
  POST_CUSTOM_BOOKING_SUCCESS:"POST_CUSTOM_BOOKING_SUCCESS",
  POST_CUSTOM_BOOKING_FAILURE:"POST_CUSTOM_BOOKING_FAILURE",
  CLEAR_CUSTOM_BOOKING: 'CLEAR_CUSTOM_BOOKING',
  GET_RENTAL_CAB_RESULT:'GET_RENTAL_CAB_RESULT',
  GET_RENTAL_CAB_RESULT_SUCCESS: 'GET_RENTAL_CAB_RESULT_SUCCESS',
  GET_RENTAL_CAB_RESULT_FAILURE: 'GET_RENTAL_CAB_RESULT_FAILURE',
  REGISTER_AND_SEND_OTP:"REGISTER_AND_SEND_OTP",
	REGISTER_AND_SEND_OTP_SUCCESS:"REGISTER_AND_SEND_OTP_SUCCESS",
	REGISTER_AND_SEND_OTP_FAILURE:"REGISTER_AND_SEND_OTP_FAILURE",
  GET_CUSTOMER_BOOKING: "GET_CUSTOMER_BOOKING",
	GET_CUSTOMER_BOOKING_SUCCESS: "GET_CUSTOMER_BOOKING_SUCCESS",
	GET_CUSTOMER_BOOKING_FAILURE: "GET_CUSTOMER_BOOKING_FAILURE",
  POST_EDIT_CUSTOMER: "POST_EDIT_CUSTOMER",
	POST_EDIT_CUSTOMER_SUCCESS: "POST_EDIT_CUSTOMER_SUCCESS",
	POST_EDIT_CUSTOMER_FAILURE: "POST_EDIT_CUSTOMER_FAILURE",
	UNLOAD_POST_EDIT_CUSTOMER: "UNLOAD_POST_EDIT_CUSTOMER",
  GET_CUSTOMER: "GET_CUSTOMER",
	GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
	GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",
  GET_BOOKING_BY_ID: 'GET_BOOKING_BY_ID',
  GET_BOOKING_BY_ID_SUCCESS: 'GET_BOOKING_BY_ID_SUCCESS',
  GET_BOOKING_BY_ID_FAILURE: 'GET_BOOKING_BY_ID_FAILURE',
  GET_ENQUIRY_BY_ID: 'GET_ENQUIRY_BY_ID',
  GET_ENQUIRY_BY_ID_SUCCESS: 'GET_ENQUIRY_BY_ID_SUCCESS',
  GET_ENQUIRY_BY_ID_FAILURE: 'GET_ENQUIRY_BY_ID_FAILURE',
  UNLOAD_BOOKING_ENQUIRY_DETAILS: 'UNLOAD_BOOKING_ENQUIRY_DETAILS',
  PATCH_USER: 'PATCH_USER',
  PATCH_USER_SUCCESS: 'PATCH_USER_SUCCESS',
  PATCH_USER_FAILURE: 'PATCH_USER_FAILURE',
  UNLOAD_PATCH_USER: 'UNLOAD_PATCH_USER',
  GET_SAVED_ADDRESS: 'GET_SAVED_ADDRESS',
  GET_SAVED_ADDRESS_SUCCESS: 'GET_SAVED_ADDRESS_SUCCESS',
  GET_SAVED_ADDRESS_FAILURE: 'GET_SAVED_ADDRESS_FAILURE',
  UNLOAD_SAVED_ADDRESS: 'UNLOAD_SAVED_ADDRESS',
  GET_BOOKING_PACKAGE: 'GET_BOOKING_PACKAGE',
  GET_BOOKING_PACKAGE_SUCCESS: 'GET_BOOKING_PACKAGE_SUCCESS',
  GET_BOOKING_PACKAGE_FAILURE: 'GET_BOOKING_PACKAGE_FAILURE',
  CLEAR_BOOKING_PACKAGE_CABS: 'CLEAR_BOOKING_PACKAGE_CABS',
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_VEHICLE_TYPE: 'GET_VEHICLE_TYPE',
  GET_VEHICLE_TYPE_SUCCESS: 'GET_VEHICLE_TYPE_SUCCESS',
  GET_VEHICLE_TYPE_FAILURE: 'GET_VEHICLE_TYPE_FAILURE',
  SAVE_BOOKING_DETAILS: 'SAVE_BOOKING_DETAILS',
  DELETE_BOOKING_DETAILS: 'DELETE_BOOKING_DETAILS',
  POST_CONTACT_US: 'POST_CONTACT_US',
  POST_CONTACT_US_SUCCESS: 'POST_CONTACT_US_SUCCESS',
  POST_CONTACT_US_FAILURE: 'POST_CONTACT_US_FAILURE',
  CLEAR_CONTACT_US: 'CLEAR_CONTACT_US',
  GET_CAR_PHOTOS: 'GET_CAR_PHOTOS',
  GET_CAR_PHOTOS_SUCCESS: 'GET_CAR_PHOTOS_SUCCESS',
  GET_CAR_PHOTOS_FAILURE: 'GET_CAR_PHOTOS_FAILURE',
  GET_CAR_DOCUMENTS: 'GET_CAR_DOCUMENTS',
  GET_CAR_DOCUMENTS_SUCCESS: 'GET_CAR_DOCUMENTS_SUCCESS',
  GET_CAR_DOCUMENTS_FAILURE: 'GET_CAR_DOCUMENTS_FAILURE',
  GET_CAR_FACILITIES: 'GET_CAR_FACILITIES',
  GET_CAR_FACILITIES_SUCCESS: 'GET_CAR_FACILITIES_SUCCESS',
  GET_CAR_FACILITIES_FAILURE: 'GET_CAR_FACILITIES_FAILURE',
  GET_DRIVER_FACILITIES: 'GET_DRIVER_FACILITIES',
  GET_DRIVER_FACILITIES_SUCCESS: 'GET_DRIVER_FACILITIES_SUCCESS',
  GET_DRIVER_FACILITIES_FAILURE: 'GET_DRIVER_FACILITIES_FAILURE',
  GET_CUSTOM_WEB_DATA: 'GET_CUSTOM_WEB_DATA',
  GET_CUSTOM_WEB_DATA_SUCCESS: 'GET_CUSTOM_WEB_DATA_SUCCESS', 
  GET_SERVICE_LOCATIONS: 'GET_SERVICE_LOCATIONS',
  GET_SERVICE_LOCATIONS_SUCCESS: 'GET_SERVICE_LOCATIONS_SUCESS',
  GET_SERVICE_LOCATIONS_FAILURE: 'GET_SERVICE_LOCATIONS_FAILURE',
  GET_SUPPORT_DATA: 'GET_SUPPORT_DATA',
  GET_SUPPORT_DATA_SUCCESS: 'GET_SUPPORT_DATA_SUCCESS',
  GET_LINKS: 'GET_LINKS',
  GET_LINKS_SUCCESS: 'GET_LINKS_SUCCESS',
  GET_LINKS_FAILURE: 'GET_LINKS_FAILURE',
  GET_LINK_INFO: 'GET_LINK_INFO',
  GET_LINK_INFO_SUCCESS: 'GET_LINK_INFO_SUCCESS',
  GET_LINK_INFO_FAILURE: 'GET_LINK_INFO_FAILURE',
  GET_CITIES_SERVED: 'GET_CITIES_SERVED',
  GET_CITIES_SERVED_SUCCESS: 'GET_CITIES_SERVED_SUCCESS',
  GET_CITIES_SERVED_FAILURE: 'GET_CITIES_SERVED_FAILURE',

  getServiceLocations: ()=>({
    type: actions.GET_SERVICE_LOCATIONS
  }),

  getServiceLocationsSuccess: (cities)=>({
    type: actions.GET_SERVICE_LOCATIONS_SUCCESS,
    cities
  }),

  getServiceLocationsFailure: (error)=>({
    type: actions.GET_SERVICE_LOCATIONS_FAILURE,
    error
  }),

  getCarPhotos: (registerationNumber)=>({
    type: actions.GET_CAR_PHOTOS,
    registerationNumber
  }),

  getCarPhotosSuccess: (carInfo)=>({
    type: actions.GET_CAR_PHOTOS_SUCCESS,
    carInfo
  }),

  getCarPhotosFailure: (error)=>({
    type: actions.GET_CAR_PHOTOS_FAILURE,
    error
  }),

  getCarDocuments: (registerationNumber)=>({
    type: actions.GET_CAR_DOCUMENTS,
    registerationNumber
  }),

  getCarDocumentsSuccess: (documents)=>({
    type: actions.GET_CAR_DOCUMENTS_SUCCESS,
    documents
  }),

  getCarDocumentsFailure: (error)=>({
    type: actions.GET_CAR_DOCUMENTS_FAILURE,
    error
  }),

  getCarFacilities: (registerationNumber)=>({
    type: actions.GET_CAR_FACILITIES,
    registerationNumber
  }),

  getCarFacilitiesSuccess: (registerationNumber)=>({
    type: actions.GET_CAR_FACILITIES_SUCCESS,
    registerationNumber
  }),

  getCarFacilitiesFailure: (error)=>({
    type: actions.GET_CAR_FACILITIES_FAILURE,
    error
  }),

  getDriverFacilities: (registerationNumber)=>({
    type: actions.GET_DRIVER_FACILITIES,
    registerationNumber
  }),

  getDriverFacilitiesSuccess: (registerationNumber)=>({
    type: actions.GET_DRIVER_FACILITIES_SUCCESS,
    registerationNumber
  }),

  getDriverFacilitiesFailure: (error)=>({
    type: actions.GET_DRIVER_FACILITIES_FAILURE,
    error
  }),

  getVehicleType: ()=>({
    type: actions.GET_VEHICLE_TYPE
  }),

  getVehicleTypeSuccess: (vehicleList) => ({
    type: actions.GET_VEHICLE_TYPE_SUCCESS,
    vehicleList
  }),

  getVehicleTypeFailure: (errorInfo) => ({
    type: actions.GET_VEHICLE_TYPE_FAILURE,
    errorInfo
  }),

  postContactUs: (contactUs)=>({
    type: actions.POST_CONTACT_US,
    contactUs
  }),

  postContactUsSuccess: (contactUs) => ({
    type: actions.POST_CONTACT_US_SUCCESS,
    contactUs
  }),

  postContactUsFailure: (errorInfo) => ({
    type: actions.POST_CONTACT_US_FAILURE,
    errorInfo
  }),

  clearContactUs: () => ({
    type: actions.CLEAR_CONTACT_US,
  }),
  getNotifications: () => ({
      type: actions.GET_NOTIFICATION
  }),

  getNotificationSuccess: (notificationList) => ({
      type: actions.GET_NOTIFICATION_SUCCESS,
      notificationList
  }),

  getCustomWebData: (applicationId) => ({
    type: actions.GET_CUSTOM_WEB_DATA,
    packageName: applicationId
  }),

  getCustomWebDataSuccess: (customWebData) => ({
    type: actions.GET_CUSTOM_WEB_DATA_SUCCESS,
    customWebData
  }),

  getSupportData: (applicationId) => ({
    type: actions.GET_SUPPORT_DATA,
    packageName: applicationId
  }),

  getSupportDataSuccess: (supportData) => ({
    type: actions.GET_SUPPORT_DATA_SUCCESS,
    supportData
  }),

  getBookingsById: (bookingId) => ({
    type: actions.GET_BOOKING_BY_ID,
    bookingId
  }),

  getBookingsByIdSuccess: (searchBookingResult) => ({
    type: actions.GET_BOOKING_BY_ID_SUCCESS,
    searchBookingResult
  }),

  getBookingsByIdFailure: (errorInfo) => ({
    type: actions.GET_BOOKING_BY_ID_FAILURE,
    errorInfo
  }),

  getEnquiryById: (bookingId) => ({
    type: actions.GET_ENQUIRY_BY_ID,
    bookingId
  }),

  getEnquiryByIdSuccess: (enquiryResult) => ({
      type: actions.GET_ENQUIRY_BY_ID_SUCCESS,
      enquiryResult
  }),

 getEnquiryByIdFailure: (errorInfo) => ({
    type: actions.GET_ENQUIRY_BY_ID_FAILURE,
    errorInfo
  }),

  unloadBookingDetails: () => ({
    type: actions.UNLOAD_BOOKING_ENQUIRY_DETAILS
  }),

  postConfirmCabBooking: (confirmCabBooking) => ({
    type: actions.POST_CONFIRM_CAB_BOOKING,
    confirmCabBooking
  }),
  postConfirmCabBookingSuccess: (confirmCabBooking) => ({
    type: actions.POST_CONFIRM_CAB_BOOKING_SUCCESS,
    confirmCabBooking
  }),
  postConfirmCabBookingFailure: (errorInfo) => ({
    type: actions.POST_CONFIRM_CAB_BOOKING_FAILURE,
    errorInfo
  }),
  postCancelBooking: (cancelCabBooking) => ({
    type: actions.POST_CANCEL_BOOKING,
    cancelCabBooking
  }),
  postCancelBookingSuccess: (cancelCabBooking) => ({
    type: actions.POST_CANCEL_BOOKING_SUCCESS,
    cancelCabBooking
  }),
  postCancelBookingFailure: (errorInfo) => ({
    type: actions.POST_CANCEL_BOOKING_FAILURE,
    errorInfo
  }),
  unloadCancelBooking: () => ({
    type: actions.UNLOAD_CANCEL_BOOKING
  }),
  postSignup: (signupDetails) => ({
    type: actions.POST_SIGNUP,
    signupDetails
  }),
  postSignupSuccess: (verifyOtpDetailResult) => ({
    type: actions.POST_SIGNUP_SUCCESS,
    verifyOtpDetailResult
  }),
  postSignupFailure: (errorInfo) => ({
    type: actions.POST_SIGNUP_FAILURE,
    errorInfo
  }),
  clearLoginInfo: () => ({
    type: actions.CLEAR_LOGIN_INFO
  }),
  sendOtp: (phoneNumber) => ({
    type: actions.SEND_OTP,
    phoneNumber
  }),
  sendOtpSuccess: (sendOtpDetailResult) => ({
    type: actions.SEND_OTP_SUCCESS,
    sendOtpDetailResult
  }),
	postCustomBooking: (customBookingCriteria) => ({
    type: actions.POST_CUSTOM_BOOKING,
    customBookingCriteria
  }),
  postCustomBookingSuccess: (customBookingCriteria) => ({
    type: actions.POST_CUSTOM_BOOKING_SUCCESS,
    customBookingCriteria
  }),
  postCustomBookingFailure: (errorInfo) => ({
    type: actions.POST_CUSTOM_BOOKING_FAILURE,
    errorInfo
  }),
  clearCustomBooking: () => ({
    type: actions.CLEAR_CUSTOM_BOOKING
  }),
  getRentalCabResult: (searchCabCriteria) => ({
    type: actions.GET_RENTAL_CAB_RESULT,
    searchCabCriteria
  }),
  getRentalCabResultSuccess: (searchCabResult) => ({
    type: actions.GET_RENTAL_CAB_RESULT_SUCCESS,
    searchCabResult
  }),
  getRentalCabResultFailure: (errorInfo) => ({
    type: actions.GET_RENTAL_CAB_RESULT_FAILURE,
    errorInfo
  }),
	registerAndSendOtp: (signupDetails) => ({
    type: actions.REGISTER_AND_SEND_OTP,
    signupDetails
  }),
  registerAndSendOtpSuccess: (signupDetailResult) => ({
    type: actions.REGISTER_AND_SEND_OTP_SUCCESS,
    signupDetailResult
  }),
  registerAndSendOtpFailure: (errorInfo) => ({
    type: actions.REGISTER_AND_SEND_OTP_FAILURE,
    errorInfo
  }),
	getCustomerBooking: (customerId) => ({
    type: actions.GET_CUSTOMER_BOOKING,
    customerId
  }),
  getCustomerBookingSuccess: (customerBookings) => ({
    type: actions.GET_CUSTOMER_BOOKING_SUCCESS,
    customerBookings
  }),
  getCustomerBookingFailure: (errorInfo) => ({
    type: actions.GET_CUSTOMER_BOOKING_FAILURE,
    errorInfo
  }),
	postEditCustomer: (customer) => ({
    type: actions.POST_EDIT_CUSTOMER,
    customer
  }),
  postEditCustomerSuccess: (customer) => ({
    type: actions.POST_EDIT_CUSTOMER_SUCCESS,
    customer
  }),
  postEditCustomerFailure: (errorInfo) => ({
    type: actions.POST_EDIT_CUSTOMER_FAILURE,
    errorInfo
  }),
  postEditCustomerUnload: () => ({
    type: actions.UNLOAD_POST_EDIT_CUSTOMER
  }),
	getCustomer: (customerNo) => ({
    type: actions.GET_CUSTOMER,
    customerNo
  }),
  getCustomerSuccess: (customerDetails) => ({
    type: actions.GET_CUSTOMER_SUCCESS,
    customerDetails
  }),
  getCustomerFailure: (errorInfo) => ({
    type: actions.GET_CUSTOMER_FAILURE,
    errorInfo
  }),
  patchUserDetails: (updateCustomer) => ({
    type: actions.PATCH_USER,
    updateCustomer
  }),
  patchUserSuccess: () => ({
    type: actions.PATCH_USER_SUCCESS
  }),
  patchUserFailure: (errorInfo) => ({
    type: actions.PATCH_USER_FAILURE,
    errorInfo
  }),
  unloadUserPatch: () => ({
    type: actions.UNLOAD_PATCH_USER
  }),
  getSavedAddress: (phoneNumber) => ({
    type: actions.GET_SAVED_ADDRESS,
    phoneNumber
  }),
  getSavedAddressSuccess: (savedAddress) => ({
    type: actions.GET_SAVED_ADDRESS_SUCCESS,
    savedAddress
  }),
  getSavedAddressFailure: (errorInfo) => ({
    type: actions.GET_SAVED_ADDRESS_FAILURE,
    errorInfo
  }),
  unloadSavedAddress: () => ({
    type: actions.UNLOAD_SAVED_ADDRESS
  }),
  getBookingPackage: (bookingType) => ({
    type: actions.GET_BOOKING_PACKAGE,
    bookingType
  }),
  clearBookingPackagesAndCabs: () => ({
    type: actions.CLEAR_BOOKING_PACKAGE_CABS
  }),
  getBookingPackageSuccess: (bookingPackage) => ({
    type: actions.GET_BOOKING_PACKAGE_SUCCESS,
    bookingPackage
  }),
  getBookingPackageFailure: (errorInfo) => ({
    type: actions.GET_BOOKING_PACKAGE_FAILURE,
    errorInfo
  }),
  saveBookingDetails: (bookingDetails) => ({
    type: actions.SAVE_BOOKING_DETAILS,
    bookingDetails
  }),
  deleteBookingDetails: (bookingType) => ({
    type: actions.DELETE_BOOKING_DETAILS,
    bookingType
  }),
  getLinks: (linksList) =>({
    type: actions.GET_LINKS,
    linksList
  }),
  getLinksSuccess: (linksList) =>({
    type: actions.GET_LINKS_SUCCESS,
    linksList
  }),
  getLinksFailure: (error) =>({
    type: actions.GET_LINKS_FAILURE,
    error
  }),
  getLinkInfo: (linkInfo) =>({
    type: actions.GET_LINK_INFO,
    linkInfo
  }),
  getLinkInfoSuccess: (linkInfo) =>({
    type: actions.GET_LINK_INFO_SUCCESS,
    linkInfo
  }),
  getLinkInfoFailure: (error) =>({
    type: actions.GET_LINK_INFO_FAILURE,
    error
  }),
  getCitiesServed: (partner) => ({
    type: actions.GET_CITIES_SERVED,
    partner
  }),
  getCitiesServedSuccess: (citiesServed) => ({
    type: actions.GET_CITIES_SERVED_SUCCESS,
    citiesServed
  }),
  getCitiesServedFailure: (error) => ({
    type: actions.GET_CITIES_SERVED_FAILURE,
    error
  })
}

export default actions;
