// (C) Copyright 2016-2017 Hewlett Packard Enterprise Development LP
import { API_CONSTANT, APPLICATION_ID } from '../customApp/config';
// import {TEXT_RESPONSE, API_TOKEN} from './constants';

const headersForAll={
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'applicationId': APPLICATION_ID
};

// const authenticationHeaderValues = () => ({
  
//   'partnerId': localStorage.getItem('userId'),
//   'username': localStorage.getItem('username'),
//   'apiToken': localStorage.getItem(API_TOKEN)
// });

const formQueryString = queryParamObj => {
  let queryParamString = '?';
  if(queryParamObj && typeof queryParamObj === 'object') {
    for(const key in queryParamObj) {

      //Add the & to add more string towards the end
      queryParamString += `${encodeURIComponent(key)}=${encodeURIComponent(queryParamObj[key])}&`;
    }
  }

  //Ignore the last character to ensure removal of the extra & at the end or the initial ?
  return queryParamString.slice(0, queryParamString.length - 1);
};

const _getHeaders= function(directives) {
  let headersToSend = (directives && directives.customHeaders) ? {...headersForAll, ...directives.customHeaders} : headersForAll;
  const authenticationInfo = {}
//   authenticationHeaderValues();

  /*
    Merge the authentication headers with the final set of headers.
    In case if some request must forcefully not send authentication info then that request can set the
    apiToken and username to null. The below merge will overwrite the header values for this request.
  */
  return {...authenticationInfo, ...headersToSend, sourceApp:'customerWeb', applicationId: APPLICATION_ID};
}

const getDataBody = function(dataArg) {
  let bodyData;

  //0 and an empty string could be a valid request body, hence allow those values
  if(dataArg !== undefined && dataArg !== null) {
    if(typeof dataArg === 'object') {
      bodyData = JSON.stringify(dataArg)
    } else {
      bodyData = dataArg
    }
  } else {

    //Send an ampty object as the request body instead of null or undefined
    bodyData = JSON.stringify({})
  }

  return bodyData
}

const _errorToJson = function (error,directives) {
  if(error.ok === false) {

    //Reject the successful promise to ensure that the client's catch is hit because this is an error case
    return (_processResponseBasedOnType(directives, error)).then(processedError => {

      //set the error status in the response so that the UI can make inferences as needed.
      processedError.errorCode = error.status;
      return Promise.reject(processedError);
    });
  }else {
    //For a client error error.ok is not received at all. This is a connection failure
    const errorToReturn ={
      errorCode: 440
    };
    return Promise.reject(errorToReturn);
  }
};

const toResponseType = function (response, responseType) {
  if (response) {

    //Confirm if the method for the response Type is available, if yes then simply call it
    if(response[responseType]) {
      response = response[responseType]();
    } else {

      //Response doe not have json conversion, simply send the response.
      response = Promise.resolve(response);      
    }
  } else {
    response = Promise.resolve({});
  }
  return response;
};

const _processStatus = function (response) {
  if (response.ok) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
};

const _processResponseBasedOnType = function(directives, response) {

  //Default response type is json.
  let responseType = 'json';
  if(directives) {

    //The default value for skip processing is false.
    if(directives.skipResponseProcessing) {
      return Promise.resolve(response);
    }else {

      if(directives.processResponseType === 'textResponse') {
        responseType = 'text';
      }
    }
  }

  return toResponseType(response, responseType)
    .then( response => response)
    .catch( () => Promise.reject(`Error converting the response to the provided ${responseType}`));
}

const _fetch = function (url, options, directives) {
  return window.fetch(url, options)
    .then(_processStatus)
    .catch((error) => _errorToJson(error,directives))
    .then(response => _processResponseBasedOnType(directives, response));
}

// Developer note:
//
// Custom directives can be passed in that determine various settings, documented below
//
//  directives : {
//    customHeaders: { object of headers }, // use the passed in headers instead of the local _headers
//    jsonResponse: false // do this to override the default behavior to serialize the json response and return it
//  }
//

export default {
  get(uri, directives, queryParams) {
    let options = { method: 'GET', headers: _getHeaders(directives) };
    options["headers"] = {...options['headers'], 'applicationId': APPLICATION_ID}
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${uri}${qpString}`, options, directives);
  },

  patch(uri, dataArg, directives, queryParams) {
    const data = getDataBody(dataArg);
    const options = { method: 'PATCH', body: data, headers: _getHeaders(directives) };
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${uri}${qpString}`, options, directives);
  },

  post(uri, dataArg, directives, queryParams) {
    const data = getDataBody(dataArg);
    const options = { method: 'POST', body: data, headers: _getHeaders(directives) };
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${uri}${qpString}`, options, directives);
  },

  put(uri, dataArg = {}, directives, queryParams) {
    const data = getDataBody(dataArg);
    const options = { method: 'PUT', body: data, headers: _getHeaders(directives) };
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${uri}${qpString}`, options, directives);
  },

  del(uri, directives, queryParams) {
    const options = { method: 'DELETE', headers: _getHeaders(directives) };
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${uri}${qpString}`, options, directives);
  },

  head(url, directives, queryParams) {
    const options = { method: 'HEAD', headers: _getHeaders(directives) };
    const qpString = formQueryString(queryParams)
    return _fetch(`${API_CONSTANT}${url}${qpString}`, options, directives);
  }
};
