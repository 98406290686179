import appCommonActions from './appCommonActions';

const initState = {
  googleMapsLoadProgress: undefined,
  googleMapsSuccess: undefined,
  googleMapsFailure: undefined
};

const handlers = {
  [appCommonActions.GOOGLE_MAPS_LOAD_IN_PROGRESS]: () => ({
    googleMapsLoadProgress: true,
    googleMapsSuccess: undefined,
    googleMapsFailure: undefined
  }),
  [appCommonActions.GOOGLE_MAPS_LOADED_SUCCESS]: () => ({
    googleMapsLoadProgress: undefined,
    googleMapsSuccess: true,
    googleMapsFailure: undefined
  }),
  [appCommonActions.GOOGLE_MAPS_LOADED_FAILURE]: () => ({
    loading: true,
    googleMapsFailure: true
  })
};

export default function (state = initState, action) {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }
  return { ...state, ...handler(state, action) };
};
