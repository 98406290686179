import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Input from "../../../components/uielements/input";
import Form from "../../../components/uielements/form";
import Modal from "../../../components/uielements/CustomModal";
import Button from "../../../components/uielements/button";
import Select from "../../../components/uielements/select";

import actions from "../../redux/Booking/actions";
import { DatePicker } from "antd";
import moment from "moment";
import TimePicker from "react-bootstrap-time-picker";
import { validateLocationsDrop, validateLocationsPickup } from '../helper/booking-utility-functions';
// import GetExactLocation from "../../components/GetExactLocation";
// import BusinessSwitch from "../business-booking/BusinessSwitch";

import {
  RENTAL_BOOKING_TYPE,
  SEARCH_CABS_RENTAL_URL,
  HOME_OUTSTATION_URL,
  HOME_RENTAL_URL,
  DATE_FORMAT,
  STEP_INTERVAL_BOOKING_TIME_PICKER,
} from "../../../utils/constants";

import {
  getTimeStr,
  getInitialTime,
  disabledDate,
  isInitTimeGreaterThanCurrentTime,
  parseAddress,
  renderBookingPackagesForRental,
  createAddressForSaving,
  getChangedMinimumTime
} from "../helper/booking-utility-functions";
import {
  addressPresent, 
  getLocalstorageBooleanValue
} from "../../../utils/helper-functions";
import { setTitleMeta } from "../../../utils/titleMetaRouteMapping";

const Option = Select.Option;
const FormItem = Form.Item;

const { getBookingPackage, getServiceLocations } = actions;

class Rentals extends Component {
  constructor(props) {
    super(props);
    const { initDate, initTime } = getInitialTime(
      STEP_INTERVAL_BOOKING_TIME_PICKER
    );
    this.disabledDate = disabledDate(initDate);
    const { state, locationGeoMain } = Rentals.createStateFromProps(
      props.bookingInfo,
      initDate,
      initTime
    );
    this.state = state;
    this.initTime = initTime;
    this.initDate = initDate;
    this.locationGeoMain = locationGeoMain;
    this.setTimePid = null;
  }

  componentDidMount() {
    const packageInfo = {
      bookingType: RENTAL_BOOKING_TYPE,
      personalUse: !getLocalstorageBooleanValue("businessEnable"),
      location: {
        destination: {
          addressLine: this.locationGeoMain.dropAddress.fullTextAddress,
          latitude: this.locationGeoMain.dropAddress.latitude,
          longitude: this.locationGeoMain.dropAddress.longitude,
          placeId: this.locationGeoMain.dropAddress.place_id,
          pincode: this.locationGeoMain.dropAddress.addressComponents && this.locationGeoMain.dropAddress.addressComponents.postal_code
        },
        mode: "driving",
        source: {
          addressLine: this.locationGeoMain.pickupAddress.fullTextAddress,
          latitude: this.locationGeoMain.pickupAddress.latitude,
          longitude: this.locationGeoMain.pickupAddress.longitude,
          placeId: this.locationGeoMain.pickupAddress.place_id,
          pincode: this.locationGeoMain.pickupAddress.addressComponents && this.locationGeoMain.pickupAddress.addressComponents.postal_code
        },
      },
    };
    if(addressPresent(this.locationGeoMain.pickupAddress)){
      this.props.getBookingPackage(packageInfo);
    }
    setTitleMeta(HOME_RENTAL_URL);
    this.props.getServiceLocations()
    this.setTimePid = setInterval(() => {
      const { initDate, initTime, changeInitTime } = getChangedMinimumTime(this.state.pickupTime, this.state.pickupDate, STEP_INTERVAL_BOOKING_TIME_PICKER,this.props.deliveryTime)
      // this.setState({pickupDate: initDate, pickupTime: initTime})
      // this.setState({startTime: initTime})
      // console.log('running in rental js')
      this.initDate = initDate;
      this.setState({startTime: initTime})
      if(changeInitTime){
        this.setState({pickupDate: initDate, pickupTime: initTime})
      }
    }, 2000);
  }



  componentDidUpdate(prevProps, prevState){
    if(prevState.visible!=this.state.visible && this.state.visible===false){
    const packageInfo = {
      bookingType: RENTAL_BOOKING_TYPE,
      personalUse: !getLocalstorageBooleanValue("businessEnable"),
      location: {
        destination: {
          addressLine: this.locationGeoMain.dropAddress.fullTextAddress,
          latitude: this.locationGeoMain.dropAddress.latitude,
          longitude: this.locationGeoMain.dropAddress.longitude,
          placeId: this.locationGeoMain.dropAddress.place_id,
          pincode: this.locationGeoMain.dropAddress.addressComponents && this.locationGeoMain.dropAddress.addressComponents.postal_code
        },
        mode: "driving",
        source: {
          addressLine: this.locationGeoMain.pickupAddress.fullTextAddress,
          latitude: this.locationGeoMain.pickupAddress.latitude,
          longitude: this.locationGeoMain.pickupAddress.longitude,
          placeId: this.locationGeoMain.pickupAddress.place_id,
          pincode: this.locationGeoMain.pickupAddress.addressComponents && this.locationGeoMain.pickupAddress.addressComponents.postal_code
        },
      },
    };

    if(addressPresent(this.locationGeoMain.pickupAddress)){
      this.props.getBookingPackage(packageInfo);
    }
    }
    if(this.props.deliveryTime && this.props.deliveryTime!= prevProps.deliveryTime){
      this.setPickupDateTime(this.state.pickupTime, this.state.pickupDate)
    }
  }

  static createStateFromProps(props, initDate, initTime) {
    const state = {
      visible: false,
      locationType: "",
      exactLocationGeo: {},
      selectedPackage: props.selectedPackage,
      pickupDate: initDate,
      pickupTime: initTime,
      startTime: initTime,
    };

    if (props.pickupDate) {
      state.pickupDate = moment(props.pickupDate);
    }

    if (props.pickupTime) {
      state.pickupTime = props.pickupTime;
    }

    if (props.startTime) {
      state.startTime = props.startTime;
    }

    const locationGeoMain = {
      pickupAddress: parseAddress(props.pickupAddress),
      dropAddress: parseAddress(props.dropAddress),
    };

    return { state, locationGeoMain };
  }

  componentWillUnmount() {
    const values = this.createValuesToSave();
    this.props.saveState(values);
    if(this.setTimePid){
      clearInterval(this.setTimePid)
    }
  }

  createValuesToSave() {
    const pickupDateStr = this.state.pickupDate.format("YYYY-MM-DD");
    console.log(this.locationGeoMain)
    return {
      pickupAddress: {
        latitude: this.locationGeoMain.pickupAddress.latitude,
        longitude: this.locationGeoMain.pickupAddress.longitude,
        fullTextAddress: this.locationGeoMain.pickupAddress.fullTextAddress,
        addressComponents: createAddressForSaving(
          this.locationGeoMain.pickupAddress
        ),
        place_id: this.locationGeoMain.pickupAddress.place_id,
      },
      dropAddress: {
        latitude: this.locationGeoMain.dropAddress.latitude,
        longitude: this.locationGeoMain.dropAddress.longitude,
        fullTextAddress: this.locationGeoMain.dropAddress.fullTextAddress,
        addressComponents: createAddressForSaving(
          this.locationGeoMain.dropAddress
        ),
        place_id: this.locationGeoMain.dropAddress.place_id,
      },
      pickupDate: pickupDateStr,
      pickupTime: this.state.pickupTime,
      startTime: this.state.startTime,
      selectedPackage: this.state.selectedPackage,
    };
  }

  searchSubmit = (e) => {
    e.preventDefault();
    const values = this.createValuesToSave();
    this.props.saveState(values);

    this.props.history.push({
      pathname: SEARCH_CABS_RENTAL_URL,
      state: values,
    });
  };

  handleConfirm = () => {
    this.setState({ visible: false });
    this.props.editClickHandler();
  };

  getLocation = async (locationGeoSelected, locationType) => {
    if (locationType === "pickup") {
      this.locationGeoMain.pickupAddress = locationGeoSelected;
      this.locationGeoMain.dropAddress = { fullTextAddress: "" };
      // this.validateLocations(
      //   this.locationGeoMain.pickupAddress.addressComponents,
      //   locationType
      // );
      let finalResponse = await validateLocationsPickup(
        this.locationGeoMain.pickupAddress.addressComponents,
      );
      if(finalResponse.status == 404){
        // this.setState({["cityPickError"]: true})
        this.onOutstationBooking(this, locationType, finalResponse.message);
      }
    } else if (locationType === "drop") {
      this.locationGeoMain.dropAddress = locationGeoSelected;

      let finalResponse = await validateLocationsDrop(
        this.locationGeoMain.dropAddress.addressComponents,
        this.locationGeoMain.pickupAddress.addressComponents,
        RENTAL_BOOKING_TYPE
      );
      if(finalResponse.status == 403 || finalResponse.status == 400){
        this.onOutstationBooking(this, locationType, finalResponse.message);
      }
    }
  };

  onPickLocationClick = (e) => {
    this.setState({
      locationType: "pickup",
      visible: true,
      exactLocationGeo: this.locationGeoMain.pickupAddress,
    });
    this.props.editClickHandler();
  };

  onDropLocationClick = (e) => {
    // alert(this.locationGeoMain.pickupAddress)
    console.log(this.locationGeoMain.pickupAddress)
    if(!this.locationGeoMain.pickupAddress || (this.locationGeoMain.pickupAddress.fullTextAddress=="" || this.locationGeoMain.pickupAddress.fullTextAddress===undefined)){
      alert("Please enter the pickup address")
      return
    }
    this.setState({
      locationType: "drop",
      visible: true,
      exactLocationGeo: this.locationGeoMain.dropAddress,
    });
    this.props.editClickHandler();
  };

  onTimeChange = (time) => {
    this.setState({ pickupTime: getTimeStr(time) });
  };

  onOutstationBooking = (self, locationType) => {
    if(locationType === "pickup"){
      Modal.confirm({
        title: "Local/Rental",
        icon: null,
        width: "500",
        className: "custom-modal modal-align-title reset-modal width500",
        content: (
          <div className="font-size16">
            <div>
              <p className="fS14 text-align-center">
                We do not serve in this location
              </p>
            </div>
          </div>
        ),
        maskClosable: true,
        okText: "CHANGE LOCATION",
  
        onOk() {
          self.hideCityError(locationType);
        },
        cancelText: "OK",
        onCancel() {
          self.hideCityError(locationType);
        },
      });
    }else{
    Modal.confirm({
      title: "Local/Rental",
      icon: null,
      width: "500",
      className: "custom-modal modal-align-title reset-modal width500",
      content: (
        <div className="font-size16">
          <div>
            <p className="fS14 text-align-center">
              Local is not allowed for selected location
            </p>
          </div>
        </div>
      ),
      maskClosable: true,
      okText: "CHANGE TO OUTSTATION",

      onOk() {
        self.proceedCityError(locationType);
      },
      cancelText: "CHANGE LOCATION",
      onCancel() {
        self.hideCityError(locationType);
      },
    });
    }
  };

  onDateChange = (date) => {
    this.setState({ pickupDate: date });

    if (date.isSame(this.initDate, "days")) {
      let { pickupTime } = this.state;
      if (isInitTimeGreaterThanCurrentTime(this.initTime, pickupTime)) {
        pickupTime = this.initTime;
      }

      this.setState({
        pickupTime,
        startTime: this.initTime,
      });
    } else {
      //Later day selected, set the start time to 00
      this.setState({
        startTime: "00:00",
      });
    }
  };

  setPickupDateTime = (pickupTime, pickupDate) => {
    const { initDate, initTime, changeInitTime } = getChangedMinimumTime(pickupTime, pickupDate, STEP_INTERVAL_BOOKING_TIME_PICKER,this.props.deliveryTime)
    // if(changeInitTime){
      // this.setState({pickupDate: initDate, pickupTime: initTime, startTime: initTime})
    // }else{
      // this.setState({startTime: initTime})
    // }
    this.initDate = initDate;
    this.setState({startTime: initTime})
    if(changeInitTime){
      this.setState({pickupDate: initDate, pickupTime: initTime})
    }
  }

  hideCityError = (locationType) => {
    if (locationType === "pickup") {
      this.locationGeoMain.pickupAddress = { fullTextAddress: "" };
    } else if (locationType === "drop") {
      this.locationGeoMain.dropAddress = { fullTextAddress: "" };
    }

    this.props.form.setFieldsValue({
      pickup: this.locationGeoMain.pickupAddress.fullTextAddress,
      drop: this.locationGeoMain.dropAddress.fullTextAddress,
    });
  };

  proceedCityError = (locationType) => {
    //Clear out the values so that upon unmount the correct values are saved.
    if (locationType === "pickup") {
      this.locationGeoMain.pickupAddress = { fullTextAddress: "" };
    } else if (locationType === "drop") {
      this.locationGeoMain.dropAddress = { fullTextAddress: "" };
    }

    this.props.history.push({
      pathname: HOME_OUTSTATION_URL,
    });
  };

  validateLocations = (local, locationType) => {
    if (
      local.locality !== "Mumbai" &&
      local.locality !== "Thane" &&
      local.locality !== "Kalyan" &&
      local.locality !== "Dombivli" &&
      local.locality !== "Navi Mumbai" &&
      local.locality !== "Mira Bhayandar" &&
      local.locality !== "Bhiwandi" &&
      local.locality !== "Ulhasnagar" &&
      local.locality !== "Vasai" &&
      local.locality !== "Virar" &&
      local.locality !== "Panvel" &&
      local.locality !== "Greater Mumbai"
    ) {
      this.onOutstationBooking(this, locationType);
    }
  };

  handlePackageChange = (value) => {
    this.setState({ selectedPackage: value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { bookingPackage } = this.props;
    return (
      <div>
        {/* {this.state.visible === true && (
          <Row className={"booking-form--home"}>
            <GetExactLocation
              onConfirm={this.handleConfirm}
              getLocation={this.getLocation}
              type={this.state.locationType}
              exactLocationGeo={this.state.exactLocationGeo}
              favouriteIcon={true}
            />
          </Row>
        )} */}

        {this.state.visible === false && (
          <div>
            <Row className={"booking-form--home"}>
              <Col className="booking-form" md={24} lg={24} sm={24} xs={24}>
                <Form>
                  <Row className="dark-wrapper paddingTop10">
                    <Col md={24}>
                      <FormItem>
                        {/* <Col md={10}>
                          <span className="ant-input-wrapper ant-input-group paddingLeft10">
                            <span className="ant-input-group-addon minWidth20">
                              Service City
                            </span>

                            {this.props.serviceLocationsList && getFieldDecorator("city", {
                              initialValue: this.props.serviceLocationsList[0]
                            })(
                              <Select>
                                {this.props.serviceLocationsList && this.props.serviceLocationsList.map(city => (
                                  <Option value={city}>{city}</Option>
                                ))}
                                
                              </Select>
                            )}
                            {!this.props.serviceLocationsList && <span>Loading...</span>}
                          </span>
                        </Col> */}
                        {/* <BusinessSwitch history={this.props.history} /> */}
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem>
                    <span className="ant-input-wrapper ant-input-group paddingLeft10 paddingRight10 marginTop10">
                      <span className="ant-input-group-addon ">From</span>
                      {getFieldDecorator("pickup", {
                        initialValue: this.locationGeoMain.pickupAddress
                          .fullTextAddress,
                      })(
                        <Input
                          className="ant-input ant-input-sm"
                          placeholder="Enter pickup location"
                          onClick={this.onPickLocationClick}
                        />
                      )}
                    </span>
                  </FormItem>

                  <FormItem>
                    <span className="ant-input-wrapper ant-input-group paddingLeft10 paddingRight10">
                      <span className="ant-input-group-addon ">
                        <span>{"To "}</span>
                        <span className={"fS13"}>(optional)</span>
                      </span>
                      {getFieldDecorator("drop", {
                        initialValue: this.locationGeoMain.dropAddress
                          .fullTextAddress,
                      })(
                        <Input
                          className="ant-input ant-input-sm"
                          placeholder="Enter drop location"
                          onClick={this.onDropLocationClick}
                        />
                      )}
                    </span>
                  </FormItem>
                  <FormItem>
                    <div className="flex-row-align marginLeft10 marginRight10 marginBtm10">
                      <div className="ant-input-group-addon label-calendar">
                        When
                      </div>
                      <div className="flex-xs-row-sm-col flex-grow--1 bcwhite date-time-border">
                        <div className="width-xs-100-sm-50 height44">
                          {getFieldDecorator("pickupDate", {
                            initialValue: this.state.pickupDate,
                          })(
                            <DatePicker
                              disabledDate={disabledDate(this.initDate)}
                              format={DATE_FORMAT}
                              onChange={this.onDateChange}
                              allowClear={false}
                              className="width100P"
                            />
                          )}
                        </div>
                        <div className="width-xs-100-sm-50 height44">
                          <TimePicker
                            onChange={this.onTimeChange}
                            start={this.state.startTime}
                            value={this.state.pickupTime}
                            className="pickupTime"
                            step={STEP_INTERVAL_BOOKING_TIME_PICKER}
                          />
                        </div>
                      </div>
                    </div>
                  </FormItem>

                  {addressPresent(this.locationGeoMain.pickupAddress) && renderBookingPackagesForRental(
                    bookingPackage,
                    this.props.bookingPackageFailure,
                    getFieldDecorator,
                    {
                      initialValue: this.state.selectedPackage,
                    },
                    this.handlePackageChange.bind(this)
                  )}
                  <FormItem className="marginLeft10 marginRight10">
                    <Button
                      className="search-button marginBtm10"
                      onClick={(event) => this.searchSubmit(event)}
                    >
                      SEARCH LOCAL/RENTAL CABS
                    </Button>
                  </FormItem>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
const RentalsForm = Form.create({})(Rentals);

function mapStateToProps(state) {
  const stateObj = state.bookings;
  return {
    bookingPackage: stateObj.bookingPackage,
    bookingPackageFailure: stateObj.bookingPackageFailure,
    serviceLocationsList: stateObj.serviceLocationsList,
    deliveryTime: stateObj.deliveryTime,
  };
}

export default connect(mapStateToProps, { getBookingPackage, getServiceLocations })(RentalsForm);
