import moment from "moment";
import {CUSTOM_WEB} from "../customApp/config";
import { TRANSFER_BOOKING_TYPE } from "./constants";
export const isToAndFromLocationSame = function(toLocation, fromLocation) {
  return (toLocation === fromLocation);
};

export const isUserLoggedIn = function() {
  let loggedIn = false;

  //TODO probably need to move to cookies to enable SSR authentication
  try {
    loggedIn = localStorage.getItem("isLoggedInFlag") === "true";
  }catch(error) {
    console.log(error);
  }

  return loggedIn;
};

export const createGoogleMapUrlFromKey = (apiKey) => {
  if(apiKey){
    return `https://maps.googleapis.com/maps/api/js?key=AIzaSyD2uLAOSaO79rd7zXUK_t1nEP5nRbRmuzo&libraries=places&callback=handleGoogleMapsReady`
  }else{
    console.error("Could not load api key")
  }
}

/**
 * While scrolling, ensure that the top is at least 50px above the bottom of the window
 * and that the bottom is at least 50px below the top of the window
*/
export const isElementInViewport = function (domElement) {
  const domElementCoords = domElement.getBoundingClientRect();
  return (
    (domElementCoords.bottom > 50 && domElementCoords.top <= ((window.innerHeight || document.documentElement.clientHeight) - 50))
  );
};

export const loadAllValidImages = function(containerElement) {
  const imagesToLoad = containerElement.getElementsByTagName('img');  
  for (let index=0; index < imagesToLoad.length; index++) {
    const imageToLoad = imagesToLoad[index];
    const {matchMediaQuery} = imageToLoad.dataset;
    //Do not load the image if the window inner width is less than width on which the image is viewed
    if(matchMediaQuery) {

      //If the supplied media query matches then do not load the image because it must be hidden
      if(window.matchMedia(matchMediaQuery).matches) {
        continue;
      }
    }

    //this image was already loaded, no need to replace SRC, simply move on to the next one
    if(imageToLoad.src) {
      continue;
    }

    if(imageToLoad.dataset.sizes) {
      imageToLoad.sizes = imageToLoad.dataset.sizes;
    }

    if(imageToLoad.dataset.srcSet) {
      imageToLoad.srcset = imageToLoad.dataset.srcSet;
    }

    imageToLoad.src = imageToLoad.dataset.src;
  }
};

export const loadGoogleMapsApi = function(googleMapsLoadedSuccess, googleMapsLoadProgress, mapsUri, loadInProgHandler, successHandler, errorHandler) {

  /*
    Do not add another script tag if the load is already in progress
  */
  if(googleMapsLoadedSuccess || googleMapsLoadProgress) {
    return;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;

  script.onload = function() {
    //TODO check how to detect errors in the Google maps
  };

  script.onerror = () => {
    errorHandler();
  };

  script.src = mapsUri;
  document.head.appendChild(script);
  loadInProgHandler();

  //Add callback to understand whether google maps has loaded or not
  window.handleGoogleMapsReady = () => {
    successHandler();
  };
};

export const detectIntersectionObserverFeature = function() {
  if('IntersectionObserver' in window && 'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype) {
    return true;
  }

  return false;
};

export const getLocalstorageBooleanValue =  function(name) {
  try{
  const isTrueSet = (localStorage.getItem(name) == 'true')
  return isTrueSet;
  }
  catch{
    return false
  }
}

export const addressPresent = function(pickupAddress, dropAddress = {}, checkBoth = false) {
  let flag = false;
  if(Object.keys(pickupAddress).length !== 0  && pickupAddress["addressLine"]!=""){
    if(pickupAddress.latitude!== undefined)flag = true;
  }
  if(checkBoth){
    if(Object.keys(dropAddress).length === 0 && dropAddress["addressLine"]!=""){
      flag = false;
    }
  }
  return flag;
}

export const payloadForPackages = function(searchCriteria, pickupTime, pickupDate, bookingType, bookingPackage, bookingPackages = []){
  let date = moment(pickupDate).format("M/D/YYYY")
  let finalDateTime = date + " " + pickupTime
  finalDateTime = moment(finalDateTime, "M/D/YYYY HH:mm").valueOf()
  let packageInfo = {
    bookingType: bookingType,
    personalUse: !getLocalstorageBooleanValue("businessEnable"),
    destination: {
      addressLine: searchCriteria.dropAddress.fullTextAddress,
      latitude: searchCriteria.dropAddress.latitude,
      longitude: searchCriteria.dropAddress.longitude,
      placeId: searchCriteria.dropAddress.place_id,
      pincode: searchCriteria.dropAddress.addressComponents && parseInt(searchCriteria.dropAddress.addressComponents.postal_code)
    },

    source: {
      addressLine: searchCriteria.pickupAddress.fullTextAddress,
      latitude: searchCriteria.pickupAddress.latitude,
      longitude: searchCriteria.pickupAddress.longitude,
      placeId: searchCriteria.pickupAddress.place_id,
      pincode: searchCriteria.pickupAddress.addressComponents && parseInt(searchCriteria.pickupAddress.addressComponents.postal_code)
    },
    "startTime": finalDateTime
    // startTime: 
  }
  if(bookingType === TRANSFER_BOOKING_TYPE){
    packageInfo["allPackages"] = bookingPackages
  }
  const searchParams = {
    data: packageInfo,
    query: {
    bookingType: bookingType,
    bookingPackage: bookingPackage,
    personalUse: !getLocalstorageBooleanValue("businessEnable"),
    from: parseInt(searchCriteria.pickupAddress.addressComponents.postal_code),
    to: searchCriteria.dropAddress.addressComponents ? searchCriteria.dropAddress.addressComponents.postal_code ? parseInt(searchCriteria.dropAddress.addressComponents.postal_code): '': ''
    }
  }


    return searchParams
}

export const isCustomWeb = function(){
  if(CUSTOM_WEB === "YES"){
    return true;
  }
  return false;
}