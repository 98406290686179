import React, { Component } from "react";
import { Row } from "antd";
import fb from "../../image/static/socials/Facebook_icon.png";
import ln from "../../image/static/socials/Linkedin_icon.png";
import yt from "../../image/static/socials/YouTube_icon.png";
import ps from "../../image/static/socials/playstore.png";
import tw from "../../image/static/socials/Twitter_icon.png";
import tb from "../../image/static/socials/tumblr_icon.png";
import ig from "../../image/static/socials/Instagram_icon.png";
import md from "../../image/static/socials/medium.png";
import {isCustomWeb} from "../../utils/helper-functions"
import Wa from "../../image/static/whatsapp.png";

import { connect } from "react-redux";

class SocialLinks extends Component {

  constructor() {
    super()
  }


  render() {
    const {customWebData} = this.props;
    return (
      <div className="socials">
        {!isCustomWeb() && <div>
        <Row>
          <a
            href="https://www.facebook.com/Yellow_Plate-1974413872580641/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fb} alt="Follow Yellow Plate On Facebook" />
          </a>
        </Row>
        <Row>
          <a href="https://twitter.com/yellow_plate" target="_blank" rel="noopener noreferrer">
            <img src={tw} alt="Follow Yellow Plate On Twitter" />
          </a>
        </Row>
        <Row>
          <a
            href="https://www.linkedin.com/company/yellowplate"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ln} alt="Follow Yellow Plate On LinkedIn" />
          </a>
        </Row>
        <Row>
          <a href="https://www.instagram.com/yellowplate.in/" target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="Follow Yellow Plate On Instagram" />
          </a>
        </Row>
        <Row>
          <a href="https://yellow-plate.tumblr.com/" target="_blank" rel="noopener noreferrer">
            <img src={tb} alt="Follow Yellow Plate On Tumbler" />
          </a>
        </Row>
        <Row>
          <a
            href="https://www.youtube.com/channel/UCrRXjH70ChHvlG3U1RpExiA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={yt} alt="Subscribe To Yellow Plate On YouTube" />
          </a>
        </Row>
        <Row>
          <a href="https://medium.com/@yellow_plate" target="_blank" rel="noopener noreferrer">
            <img src={md} alt="Follow Yellow Plate On Medium" />
          </a>
        </Row>
        <Row>
          <a
            href="https://play.google.com/store/apps/details?id=com.ideatransport.consumerapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ps}
              alt="Download Yellow Plate's Customer App From Play Store"
              className="social_links-playstore_icon"
             />
          </a>
        </Row>
        </div>}{
          isCustomWeb() &&
           <div>
        {customWebData.fbLink && <Row>
          <a
            href={customWebData.fbLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fb} alt="Follow Yellow Plate On Facebook" />
          </a>
        </Row>}
        {customWebData.twitterLink && <Row>
          <a href={customWebData.twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={tw} alt="Follow Yellow Plate On Twitter" />
          </a>
        </Row>}
        {customWebData.linkedinLink && <Row>
          <a
            href={customWebData.linkedinLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ln} alt="Follow Yellow Plate On LinkedIn" />
          </a>
        </Row>}
        {customWebData.instaLink && <Row>
          <a href={customWebData.instaLink} target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="Follow Yellow Plate On Instagram" />
          </a>
        </Row>}
        {false && customWebData.fbLink && <Row>
          <a href="https://yellow-plate.tumblr.com/" target="_blank" rel="noopener noreferrer">
            <img src={tb} alt="Follow Yellow Plate On Tumbler" />
          </a>
        </Row>}
        {customWebData.youtubeLink && <Row>
          <a
            href={customWebData.youtubeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={yt} alt="Subscribe To Yellow Plate On YouTube" />
          </a>
        </Row>}
        {customWebData.whatsappNumber && <Row>
          <a href={`https://wa.me/${customWebData.whatsappNumber}`} target="_blank" rel="noopener noreferrer">
            <img src={Wa} alt="Connect with us on whatsapp" />
          </a>
        </Row>}
        {customWebData.playStoreLink && <Row>
          <a
            href={customWebData.playStoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ps}
              alt="Download Yellow Plate's Customer App From Play Store"
              className="social_links-playstore_icon"
             />
          </a>
        </Row>}
        </div>

        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const stateObj = state.bookings;
  return {
    customWebData: stateObj.customWebData,
  };
}

export default connect(
  mapStateToProps,
  null
)(SocialLinks);