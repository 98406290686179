import React, {Component} from 'react';
import {FILE_PATH} from '../config'
import ModalImage from "react-modal-image";

export default class App extends Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        let srcImage = this.props.src;
        if(this.props.static){
            srcImage = FILE_PATH + this.props.src;
        }
        return (
            <div>
                <ModalImage       
                    small={srcImage}
                    large={srcImage}
                    // width={"50px"}
                    height={this.props.height ? this.props.height  : "auto"}
                    className={this.props.classForImage} 
                />
                <div>{this.props.imgName}</div>
            </div>
        )
    }


}