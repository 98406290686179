import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import DynamicImport from '../../helpers/DynamicImport';
import RestrictedRoute from '../../customApp/components/RestrictedRoute';
import Home from '../../customApp/containers/home/Home.js';
import 'antd/es/grid/style/css';

import {
  TRANSFER_BOOKING_TYPE,
  RENTAL_BOOKING_TYPE,
  OUTSTATION_BOOKING_TYPE,
  EVENT_BOOKING_TYPE,
  LONG_TERM_BOOKING_TYPE,
  CUSTOMIZE_BOOKING_TYPE,

  HOME_TRANSFER_URL,
  HOME_RENTAL_URL,
  HOME_OUTSTATION_URL,
  HOME_EVENT_URL,
  HOME_LONG_TERM_URL,
  HOME_CUSTOMIZE_URL,

  SEARCH_CABS_TRANSFER_URL,
  SEARCH_CABS_RENTAL_URL,
  SEARCH_CABS_OUTSTATION_URL,
  SEARCH_CABS_LONG_TERM_URL,
  SEARCH_CABS_EVENT_URL,

  AUTHENTICATE,
  SIGNUP_URL,

  CONFIRM_BOOKING,
  CONFIRM_BOOKING_URL,

  CONFIRMED,
  CONFIRMED_URL,

  MYBOOKING,
  MYBOOKING_URL,

  BOOKING_DETAILS,
  BOOKING_DETAILS_URL,

  PROFILE_VIEW,
  PROFILE_VIEW_URL,

  PROFILE_EDIT,
  PROFILE_EDIT_URL,

  IMAGE_FOR_BOOKING_TYPE,
  PRIVACY,
  TERMS_CONDITIONS

} from '../../utils/constants';

import transferGif from '../../image/car_video/Transfer.gif';
import customiseGif from '../../image/car_video/customise.gif';
import eventGif from '../../image/car_video/Events.gif';
import outstationGif from '../../image/car_video/outstation.gif';

/*
  Important: declare this constant's values over here because
  the imports are not working in the constants file.
*/
IMAGE_FOR_BOOKING_TYPE[TRANSFER_BOOKING_TYPE] = {imgSrc: transferGif,imgAlt: "Assured Early Morning Or Late Night Airport Taxi/Transfer Service."};
IMAGE_FOR_BOOKING_TYPE[RENTAL_BOOKING_TYPE] = {imgSrc: transferGif,imgAlt: "Most Affordable Car Rental For City Travel, Meetings. Books Cabs By Hours Or Full Or Half Day. "};
IMAGE_FOR_BOOKING_TYPE[OUTSTATION_BOOKING_TYPE] = {imgSrc: outstationGif,imgAlt: "Affordable Outstation Cabs From Mumbai"};
IMAGE_FOR_BOOKING_TYPE[EVENT_BOOKING_TYPE] = {imgSrc: eventGif,imgAlt: "Book Any Number Of Cabs For Meetings, Incentives, Conferences, Events Or Weddings "};
IMAGE_FOR_BOOKING_TYPE[LONG_TERM_BOOKING_TYPE] = {imgSrc: eventGif,imgAlt: "Long Term Car Rentals Solutions For Companies, Hotels, Retail Customers."};
IMAGE_FOR_BOOKING_TYPE[CUSTOMIZE_BOOKING_TYPE] = {imgSrc: customiseGif,imgAlt: "Custom Made Car Rental Solutions For Companies, Hotels, Events, Retail Customers For Airport Transfers, Local/Rentals, Outstation, Events/MICE, Long Term Etc. "};

class AppRouter extends React.Component {
  
  render() {
    console.log(this.props, "basePath")
    let { basePath } = this.props;
    if(basePath === '/') {
      basePath = '';
    }

    /*
      IMPORTANT. Do not remove any of the below comments added before the "import functions".
      These are magic comments which are parsed by loadable babel plugin
      to transform these functions into the required format
    */
    return (
      <Routes>
        <Route
          exact
          // key="home"
          path={`${basePath}/`}
          element={<Home bookingType={TRANSFER_BOOKING_TYPE} {...this.props} />}
          // render= { props => {
          //   console.log(props, "comes here"); 
          //   return (
          //     <Home bookingType={TRANSFER_BOOKING_TYPE} {...props}/>
          //   )
          // }}
        />
        {/* <Route
          exact
          key="home"
          path={`${basePath}${HOME_TRANSFER_URL}`}
          element={<Home />}
          // render= { props => {
          //   return (
          //     <DynamicImport
          //       importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
          //       bookingType={TRANSFER_BOOKING_TYPE}
          //     />
          //   )
          // }}
        /> */}

        {/* <Route
          exact
          key="home"
          path={`${basePath}${HOME_TRANSFER_URL}`}
          render= { props => {
            return (
              <DynamicImport
                importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
                bookingType={TRANSFER_BOOKING_TYPE}
              />
            )
          }}
        />
        <Route
          exact
          key="home"
          path={`${basePath}${'/'}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
              bookingType={RENTAL_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="home"
          path={`${basePath}${HOME_OUTSTATION_URL}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
              bookingType={OUTSTATION_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="home"
          path={`${basePath}${HOME_EVENT_URL}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
              bookingType={EVENT_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="home"
          path={`${basePath}${HOME_LONG_TERM_URL}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
              bookingType={LONG_TERM_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="home"
          path={`${basePath}${HOME_CUSTOMIZE_URL}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
              bookingType={CUSTOMIZE_BOOKING_TYPE}
            />
          )}
        />

        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SEARCH_CABS_TRANSFER_URL}`}
          render= { props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              bookingType={TRANSFER_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SEARCH_CABS_RENTAL_URL}`}
          render={props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              bookingType={RENTAL_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SEARCH_CABS_OUTSTATION_URL}`}
          render={props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              bookingType={OUTSTATION_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SEARCH_CABS_EVENT_URL}`}
          render={props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              bookingType={EVENT_BOOKING_TYPE}
            />
          )}
        />
        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SEARCH_CABS_LONG_TERM_URL}`}
          render={props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              bookingType={LONG_TERM_BOOKING_TYPE}
            />
          )}
        />

        <Route
          exact
          key="search-cabs"
          path={`${basePath}${SIGNUP_URL}`}
          render = {props => (
            <DynamicImport importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              furtherBookingType={AUTHENTICATE}
            />
          )}
        />

        <Route
          exact
          key="search-cabs"
          path={`${basePath}${CONFIRM_BOOKING_URL}`}
          render={ props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              furtherBookingType={CONFIRM_BOOKING}
            />
          )}
        />

        <Route
          exact
          key="search-cabs"
          path={`${basePath}${CONFIRMED_URL}`}
          render={ props => (
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/search-cabs/SearchCabs')} routeProps={props}
              furtherBookingType={CONFIRMED}
            />
          )}
        />
        <RestrictedRoute
          exact
          key="user-operation"
          path={`${basePath}${MYBOOKING_URL}`}
          component={
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/user-operation-container/UserOperationContainer')}
              operationType={MYBOOKING}
            />
          }
        />

        <RestrictedRoute
          exact
          key="user-operation"
          path={`${basePath}${BOOKING_DETAILS_URL}`}
          component={
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/user-operation-container/UserOperationContainer')}
              operationType={BOOKING_DETAILS}
            />
          }
        />

        <RestrictedRoute
          exact
          key="user-operation"
          path={`${basePath}${PROFILE_VIEW_URL}`}
          component={
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/user-operation-container/UserOperationContainer')}
              operationType={PROFILE_VIEW}
            />
          }
        />

        <RestrictedRoute
          exact
          key="user-operation"
          path={`${basePath}${PROFILE_EDIT_URL}`}
          component={
            <DynamicImport
              importComponent={ () => import('../../customApp/containers/user-operation-container/UserOperationContainer')}
              operationType={PROFILE_EDIT}
            />
          }
        />

        <Route
          exact
          key="t&c"
          path={`${basePath}${TERMS_CONDITIONS}`}
          render = {props => (
            <DynamicImport importComponent={ () => import('../../customApp/containers/TandC/t&c.js')} routeProps={props}>
            </DynamicImport>
          )}
        />
        <Route
          exact
          key="privacy"
          path={`${basePath}${PRIVACY}`}
          render = {props => (
            <DynamicImport importComponent={ () => import('../../customApp/containers/TandC/privacy.js')} routeProps={props}>
            </DynamicImport>
          )}
        />
        <Route
          exact
          key="home"
          path={`${basePath}/:city/:offeringType`}
          render= { props => {
            return (
              <DynamicImport
                importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
                bookingType={TRANSFER_BOOKING_TYPE}
              />
            )
          }}
        />
        <Route
          exact
          key="home"
          path={`${basePath}/:link`}
          render= { props => {
            return (
              <DynamicImport
                importComponent={ () => import('../../customApp/containers/home/Home.js')} routeProps={props}
                bookingType={TRANSFER_BOOKING_TYPE}
              />
            )
          }}
        />
        <Route
          key="not-found"
          render={props => (
            <DynamicImport importComponent={ () => import('../Page/404')} routeProps={props}>
            </DynamicImport>
          )}
        /> */}
      </Routes>
    );
  }
}

export default AppRouter;
