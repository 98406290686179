import { detectIntersectionObserverFeature, isElementInViewport, loadAllValidImages } from "./helper-functions";

export default class LazyLoadImage {
  constructor() {
    this.scrollListeners = {};
  }

  loadImagesIfElementInView(domElementToTest) {
    if(isElementInViewport(domElementToTest)) {
      loadAllValidImages(domElementToTest);
      return true;
    }
    return false;
  };

  handleScrollOrReizeEvent(section, domElementToTest) {

    //Clear out any pending timeouts for this section
    clearTimeout(this.scrollListeners[section].timer);
  
    //Give a little time for the scroll to stop before processing the scroll event
    this.scrollListeners[section].timer = setTimeout(() => {
  
      //Added for safety. If the section was deleted and this same handler was run again. Ignore it as nothing must be done
      if(this.scrollListeners[section] && this.loadImagesIfElementInView(domElementToTest)) {
  
        //Images loaded remove event listeners
        this.removeLazyLoadListener(section, domElementToTest);
      }
    }, 80);
  };

  addListenersForLazyLoading(domElementToTest, section) {
    if (detectIntersectionObserverFeature()) {
      if(!this.scrollListeners[section]) {
        const lazyImageObserver = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              loadAllValidImages(domElementToTest);
              lazyImageObserver.unobserve(domElementToTest);
            }
          });
        }, {
          threshold: 0.05
        });
        lazyImageObserver.observe(domElementToTest);

        this.scrollListeners[section] = {
          observer: lazyImageObserver
        };
      }

    } else {

      //Section not present, create all handlers for it
      if(!this.scrollListeners[section]) {
        this.scrollListeners[section] = {
          eventHandler: () => {
            this.handleScrollOrReizeEvent(section, domElementToTest);
          },
          timer: null
        };

        const lazyImageEventHandler = this.scrollListeners[section].eventHandler;
        window.addEventListener('scroll', lazyImageEventHandler);
        window.addEventListener('resize', lazyImageEventHandler);
      }
    }
  };

  addListenerForLazyLoadingImages(domElementToTest, section) {
    if(!this.loadImagesIfElementInView(domElementToTest)) {
      this.addListenersForLazyLoading(domElementToTest, section)
    }
  };

  removeLazyLoadListener(section, domElementToTest) {

    //Remove listenres only if the section details were saved
    if(this.scrollListeners[section]) {
      const lazyImageEventHandler = this.scrollListeners[section].eventHandler;
      if(lazyImageEventHandler) {
        window.removeEventListener('scroll', lazyImageEventHandler);
        window.removeEventListener('resize', lazyImageEventHandler);
        clearTimeout(this.scrollListeners[section].timer); 
      }
  
      if(this.scrollListeners[section].observer) {
        this.scrollListeners[section].observer.unobserve(domElementToTest) 
      }
  
      delete this.scrollListeners[section];
    }
  };
};
