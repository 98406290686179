import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { callAPI } from '../../apiutils'

const API_LIST = {
	GET_SEARCH_CAB_RESULT : 'searchCab/listBookingDetails',
  POST_CONFIRM_CAB_BOOKING: 'booking/add',
	POST_CANCEL_BOOKING:'booking/update',
	POST_SIGNUP : 'sms/otp/verifyOtpForCustomer',
	SEND_OTP : 'sms/otp/sendOTPForCustomer',
	POST_SEARCH_BOOKING : 'searchCab/add',
	POST_CUSTOM_BOOKING : 'enquiry/add',
  GET_RENTAL_CAB_RESULT: 'rate/fetchForPackage/v2',
	REGISTER_AND_SEND_OTP : 'sms/otp/registerAndSendOtp',
	GET_CUSTOMER_BOOKING : 'booking/getCustomerBooking',
	POST_EDIT_CUSTOMER: 'customer/update',
	GET_CUSTOMER: 'customer/registered',
  GET_BOOKING_BY_ID: 'booking/find',
  PATCH_USER: 'customer/',
  GET_SAVED_ADDRESS: 'customer/fetchAddress',
  GET_BOOKING_PACKAGE: 'rate/distance/packages/v2',
  GET_ENQUIRY_BY_ID: 'enquiry/',
  GET_NOTIFICATION: 'notification/unread',
  GET_VEHICLE_LIST: 'static/list?type=VEHICLE_TYPE',
  POST_CONTACT_US: 'enquiry/contactUs',
  GET_CAR_PHOTOS: 'vehicle/fetch',
  GET_CAR_DOCUMENTS: 'docs/docStatus',
  GET_CAR_FACILITIES: '',
  GET_DRIVER_FACILITIES: '',
  GET_CUSTOM_WEB: 'partner/config/package',
  GET_PARTNER_SERVICE_LOCATIONS: 'partner/offering-cities',
  GET_SUPPORT_CONTACT_DATA: 'support/contact',
  GET_LINKS_LIST: 'seo',
  GET_LINK_INFO: 'seo/getByLink',
  GET_CITIES_SERVED: 'seo/getCitiesServed',
  GET_RELATED_LINKS: 'seo/getRelatedLinks'
}

/**
 * Temporary fix to remove all nulls from pickupLocation and pickupAddress
 * as well as dropLocation and dropAddress
 * Will be removed once fixed by the backend
 * Modifies the value on reference hence no need to return anything
*/
const removeNullFromBookingAddress = function(bookingToModify) {
  if(bookingToModify) {
    const nullReplaceRegex = /null/gi;
    if(bookingToModify.dropLocation) {
      bookingToModify.dropLocation = bookingToModify.dropLocation.replace(nullReplaceRegex,'').trim();
    }
    if(bookingToModify.dropAddress && bookingToModify.dropAddress.fullTextAddress) {
      bookingToModify.dropAddress.fullTextAddress = bookingToModify.dropAddress.fullTextAddress.replace(nullReplaceRegex,'').trim();
    }

    if(bookingToModify.pickupLocation) {
      bookingToModify.pickupLocation = bookingToModify.pickupLocation.replace(nullReplaceRegex,'').trim();
    }
    if(bookingToModify.pickupAddress && bookingToModify.pickupAddress.fullTextAddress) {
      bookingToModify.pickupAddress.fullTextAddress = bookingToModify.pickupAddress.fullTextAddress.replace(nullReplaceRegex,'').trim();
    }
  }
};

function* getServiceLocations(){
  try{
    const serviceLocations = yield call(callAPI.bind(this, API_LIST.GET_PARTNER_SERVICE_LOCATIONS,'GET',null,null));
    yield put(actions.getServiceLocationsSuccess(serviceLocations));
  }catch(error){
    yield put(actions.getServiceLocationsFailure(error));
  }
}

function* getCarPhotos(data){
  try {
    const carInfo = yield call(callAPI.bind(this, API_LIST.GET_CAR_PHOTOS,'GET',null,data.registerationNumber));
    yield put(actions.getCarPhotosSuccess(carInfo));
  } catch (error) {
    yield put(actions.getCarPhotosFailure(error));
  }
}

function* getCarDocuments(data){
  try {
    const carInfo = yield call(callAPI.bind(this, API_LIST.GET_CAR_DOCUMENTS,'GET',null,data.registerationNumber));
    yield put(actions.getCarDocumentsSuccess(carInfo));
  } catch (error) {
    yield put(actions.getCarDocumentsFailure(error));
  }
}

function* getCarFacilities(data){
  try {
    const carInfo = yield call(callAPI.bind(this, API_LIST.GET_CAR_FACILITIES,'GET',null,data.registerationNumber));
    yield put(actions.getCarDocumentsSuccess(carInfo));
  } catch (error) {
    yield put(actions.getCarDocumentsFailure(error));
  }
}

function* getDriverFacilities(data){
  try {
    const carInfo = yield call(callAPI.bind(this, API_LIST.GET_DRIVER_FACILITIES,'GET',null,data.registerationNumber));
    yield put(actions.getCarDocumentsSuccess(carInfo));
  } catch (error) {
    yield put(actions.getCarDocumentsFailure(error));
  }
}

function* postContactUs(data) {
  let contactUs
  try {
    if (data) {
      contactUs = yield call(callAPI.bind(this,API_LIST.POST_CONTACT_US, 'POST', data.contactUs))
    }
    yield put(actions.postContactUsSuccess(contactUs));
  } catch (error) {
    yield put(actions.postContactUsFailure(error));
  }
}

function* getVehicleType() {
  try {
    const vehicleList = yield call(callAPI.bind(this, API_LIST.GET_VEHICLE_LIST));
    yield put(actions.getVehicleTypeSuccess(vehicleList));
  } catch (error) {
    yield put(actions.getVehicleTypeFailure(error));
  }
}

function* getNotifications(data) {
  try {
    let notificationList;
    if (data) {
      notificationList = yield call(callAPI.bind(this, API_LIST.GET_NOTIFICATION))
    }
    yield put(
      actions.getNotificationSuccess(notificationList)
    );
  } catch (error) {
    yield put(actions.getNotificationSuccess([]));
  }
}

function* getCustomWebData(data) {
  try {
    let customWebData;
    if (data) {
      customWebData = yield call(callAPI.bind(this, API_LIST.GET_CUSTOM_WEB, 'GET', null, data.packageName, null))
    }
    yield put(
      actions.getCustomWebDataSuccess(customWebData)
    );
  } catch (error) {
    yield put(actions.getCustomWebDataSuccess({}));
  }
}

function* getSupportData(data) {
  try {
    let supportData;
    if (data) {
      supportData = yield call(callAPI.bind(this, API_LIST.GET_SUPPORT_CONTACT_DATA, 'GET', null, data.packageName, null))
    }
    yield put(
      actions.getSupportDataSuccess(supportData)
    );
  } catch (error) {
    yield put(actions.getSupportDataSuccess({}));
  }
}

function* getSavedAddress(data) {
  try {
    let savedAddressResult;
    if (data) {
      savedAddressResult = yield call(callAPI.bind(this, API_LIST.GET_SAVED_ADDRESS, 'GET', null, {phoneNumber:data.phoneNumber}))
    }
    yield put(actions.getSavedAddressSuccess(savedAddressResult));
  } catch (error) {
    yield put(actions.getSavedAddressFailure(error));
  }
}

function* getBookingPackage(data) {
  try {
    let bookingPackage = {
      "packages": []
    };
    if (data) {
      bookingPackage = yield call(callAPI.bind(this, API_LIST.GET_BOOKING_PACKAGE,'POST', data.bookingType, null ))
    }
    yield put(actions.getBookingPackageSuccess(bookingPackage));
  } catch (error) {
    yield put(actions.getBookingPackageFailure(error));
  }
}

function* getBookingsById(data) {
  let bookingDetailResult;
  try {
    if (data) {
      bookingDetailResult = yield call(callAPI.bind(this, API_LIST.GET_BOOKING_BY_ID, 'GET', null, {id: data.bookingId}))
      //TODO remove once fixed by the back-end
      removeNullFromBookingAddress(bookingDetailResult);
    }
    yield put(actions.getBookingsByIdSuccess(bookingDetailResult));
  } catch (error) {
    yield put(actions.getBookingsByIdFailure(error));
  }
}

function* getEnquiryById(data) {
  try {
    const bookingDetailResult = yield call(callAPI.bind(this, API_LIST.GET_ENQUIRY_BY_ID+data.bookingId))
    //TODO remove once fixed by the back-end
    removeNullFromBookingAddress(bookingDetailResult);
    yield put(actions.getEnquiryByIdSuccess(bookingDetailResult));
  } catch (error) {
    yield put(actions.getEnquiryByIdFailure(error));
  }
}

function* postConfirmCabBooking(data) {
  try {
    const bookingType = data.confirmCabBooking.bookingType;
    const confirmCabBooking = yield call(callAPI.bind(this, API_LIST.POST_CONFIRM_CAB_BOOKING, 'PUT', data.confirmCabBooking));

    yield put(actions.postConfirmCabBookingSuccess(confirmCabBooking));
    yield put(actions.deleteBookingDetails(bookingType));

  } catch (error) {
    yield put(actions.postConfirmCabBookingFailure(error));
  }
}

function* postCancelBooking(data) {
  try {
    let cancelCabBookingResponse;
    if (data) {
      cancelCabBookingResponse = yield call(callAPI.bind(this, `booking/cancelBooking`, 'POST', data.cancelCabBooking))
    }
    yield put(actions.postCancelBookingSuccess(cancelCabBookingResponse));
  } catch (error) {
    yield put(actions.postCancelBookingFailure(error));
  }
}

function* postSignup(data) {
	let signupDetailResult;
  try {
    if (data) {
      signupDetailResult = yield call(callAPI.bind(this, API_LIST.POST_SIGNUP, 'POST', null, data.signupDetails))
    }
    yield put (actions.postSignupSuccess(signupDetailResult));
  } catch (error) {
    if(error && error.status !== 400) {
      yield put(actions.postSignupFailure(error));
    } else {
      yield put (actions.postSignupSuccess(signupDetailResult));
    }
  }
}

//TODO need to check this API's use
function* sendOtp(data) {
	let signupDetailResult;
  try {
    if (data) {
      signupDetailResult = yield call(callAPI.bind(this, API_LIST.SEND_OTP+'?phoneNumber='+data.phoneNumber))
    }
    yield put(actions.sendOtpSuccess(signupDetailResult));
  } catch (error) {
    yield put(actions.sendOtpSuccess(signupDetailResult));
  }
}

function* postCustomBooking(data) {
  try {
    const bookingType = data.customBookingCriteria.bookingType;
    const customBookingCriteria = yield call(
      callAPI.bind(this, API_LIST.POST_CUSTOM_BOOKING, 'POST', [data.customBookingCriteria])
    );

    yield put(actions.postCustomBookingSuccess(customBookingCriteria));
    yield put(actions.deleteBookingDetails(bookingType));
  } catch (error) {
    yield put(actions.postCustomBookingFailure(error));
  }
}

function* getRentalCabResult(data) {
  try {
    let searchCabResult
    if (data) {
      searchCabResult = yield call(callAPI.bind(this, API_LIST.GET_RENTAL_CAB_RESULT, 'POST', data.searchCabCriteria.data,data.searchCabCriteria.query))
    }
    yield put(actions.getRentalCabResultSuccess(searchCabResult));
  } catch (error) {
    yield put(actions.getRentalCabResultFailure(error));
  }
}

function* registerAndSendOtp(data) {
	let signupDetailResult;
  try {
    if (data) {
      signupDetailResult = yield call(callAPI.bind(this, API_LIST.REGISTER_AND_SEND_OTP, 'POST', data.signupDetails))
    }

    yield put (actions.registerAndSendOtpSuccess(signupDetailResult));
  } catch (error) {
    if(error && error.status !== 409) {
      yield put(actions.registerAndSendOtpFailure(error));
    } else {
      yield put (actions.registerAndSendOtpSuccess(signupDetailResult));
    }
  }
}

function* getCustomerBooking(data) {
  try {
    let customerBookings
    if (data) {
      //TODO remove sourceApp once fixed from the backend
      customerBookings = yield call(callAPI.bind(this, API_LIST.GET_CUSTOMER_BOOKING, 'GET', null, {customerId: data.customerId,categorize:false, sourceApp: 'customerWeb'}))
      //TODO remove once fixed by the back-end
      if(customerBookings) {
        if(customerBookings.allBookings) {
          customerBookings.allBookings.forEach(customerCabBooking => {
            removeNullFromBookingAddress(customerCabBooking);
          });
        }
        if(customerBookings.enquiries) {
          customerBookings.enquiries.forEach(customerCabEnquiry => {
            removeNullFromBookingAddress(customerCabEnquiry);
          });
        }
      }
    }
    yield put(actions.getCustomerBookingSuccess(customerBookings));
  } catch (error) {
    yield put(actions.getCustomerBookingFailure(error));
  }
}

function* getLinks(data) {
  try{
    if(data){
      let linkList = yield call(callAPI.bind(this, API_LIST.GET_LINKS_LIST, 'GET',  null, data.linksList, data.linksList))
      if ( linkList.length == 0){
        linkList =         [
          {
            "canonicalLink": "string",
            "category": "OUTSTATION",
            "childLinks": [
              "string"
            ],
            "createdDate": "2022-06-12T14:18:58.210Z",
            "description": "Airport cabs",
            "id": "string",
            "link": "airport-cabs",
            "page": 0,
            "paragraph": "Airport cabs in Mumbai",
            "parentLink": [
              "string"
            ],
            "partnerId": "string",
            "version": 0
          },
          {
            "canonicalLink": "string2",
            "category": "OUTSTATION",
            "childLinks": [
              "string"
            ],
            "createdDate": "2022-06-12T14:18:58.210Z",
            "description": "string2",
            "id": "string",
            "link": "string2",
            "page": 0,
            "paragraph": "string2",
            "parentLink": [
              "string"
            ],
            "partnerId": "string",
            "version": 0
          },
          {
            "canonicalLink": "string3",
            "category": "OUTSTATION",
            "childLinks": [
              "string"
            ],
            "createdDate": "2022-06-12T14:18:58.210Z",
            "description": "mumbai to pune",
            "id": "string",
            "link": "mumbai/mumbai-to-pune",
            "page": 0,
            "paragraph": "Mumbai to pune",
            "parentLink": [
              "string"
            ],
            "partnerId": "string",
            "version": 0
          }
        ]
      }
      //TODO remove once fixed by the back-end
      // removeNullFromBookingAddress(bookingDetailResult);
      console.log(linkList)
      yield put(actions.getLinksSuccess(linkList));
    }
  }
  catch (error){
    console.log(error, "error list")
    yield put(actions.getLinksFailure(error));
  }
}

function* getLinkInfo(data) {
  try{
    if(data){
      let linkList = yield call(callAPI.bind(this, API_LIST.GET_LINK_INFO, 'GET',  null, data.linkInfo, data.linkInfo))

      //TODO remove once fixed by the back-end
      // removeNullFromBookingAddress(bookingDetailResult);
      console.log(linkList)
      if(linkList.length > 0){
        yield put(actions.getLinkInfoSuccess(linkList[linkList.length - 1]));
      }else{
        yield put(actions.getLinkInfoSuccess({
          "page": 1
        }));
      }
      
    }
  }
  catch (error){
    console.log(error, "error list")
    yield put(actions.getLinkInfoFailure(error));
  }
}

function* getCitiesServed(data) {
  try{
    if(data){
      console.log(data, "data in getCitiesServed")
      let citiesServed = []
      if(data.partner.page!=3){
       citiesServed = yield call(callAPI.bind(this, API_LIST.GET_CITIES_SERVED, 'GET',  null, data.partner, data.partner))
      }else{
        citiesServed = yield call(callAPI.bind(this, API_LIST.GET_RELATED_LINKS, 'GET',  null, {
          partnerId: data.partner.partnerId, 
          link: data.partner.link
        }, data.partner))
      }
      console.log(citiesServed, "citiesServed")
      yield put(actions.getCitiesServedSuccess(citiesServed));
    }
  }
  catch (error){
    console.log(error, "error list")
    yield put(actions.getCitiesServedFailure([]));
  }
}



function* postEditCustomer(data) {
  try {
    let customer
    if (data) {
      customer = yield call(callAPI.bind(this, API_LIST.POST_EDIT_CUSTOMER, 'POST', data.customer))
    }
    yield put(actions.postEditCustomerSuccess(customer));
  } catch (error) {
    yield put(actions.postEditCustomerFailure(error));
  }
}

function* patchUserDetails(data) {
  try {
    if (data) {
      let name = ''
      if(data.updateCustomer.type === 'removeAddress'){
        name = `${data.updateCustomer.name}/`
      }
      if(data.updateCustomer.type === 'address'){
        name = `${localStorage.getItem('phoneNumber')}/`
      }
      if(data.updateCustomer.type === 'updateFavAddress'){
        name = `${localStorage.getItem('phoneNumber')}/${data.updateCustomer.addressName}/`
      }
      yield call(callAPI.bind(
        this, API_LIST.PATCH_USER+name+data.updateCustomer.type, 'POST',
        data.updateCustomer.type!=='updateFavAddress'?[data.updateCustomer]:data.updateCustomer, null, {jsonResponse: false}
      ))
    }
    yield put(actions.patchUserSuccess());
  } catch (error) {
    yield put(actions.patchUserFailure(error));
  }
}

function* getCustomer(data) {
  try {
    let customer
    if (data) {
      customer = yield call(callAPI.bind(this, API_LIST.GET_CUSTOMER, 'GET', null, {phoneNumber: data.customerNo}))
    }

    let customerDetails
    if(customer == 'Registered') {
      customerDetails = yield call(callAPI.bind(this, API_LIST.SEND_OTP, 'GET', null, {phoneNumber: data.customerNo}))
    } else {
      customerDetails = customer
    }

    yield put(actions.getCustomerSuccess(customerDetails));
  } catch (error) {
    yield put(actions.getCustomerFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.POST_CONFIRM_CAB_BOOKING, postConfirmCabBooking),
		takeEvery(actions.POST_CANCEL_BOOKING, postCancelBooking),
		takeEvery(actions.POST_SIGNUP, postSignup),
		takeEvery(actions.SEND_OTP, sendOtp),
		takeEvery(actions.POST_CUSTOM_BOOKING, postCustomBooking),
    takeEvery(actions.GET_RENTAL_CAB_RESULT, getRentalCabResult),
		takeEvery(actions.REGISTER_AND_SEND_OTP, registerAndSendOtp),
		takeEvery(actions.GET_CUSTOMER_BOOKING, getCustomerBooking),
		takeEvery(actions.POST_EDIT_CUSTOMER, postEditCustomer),
		takeEvery(actions.GET_CUSTOMER, getCustomer),
    takeEvery(actions.GET_BOOKING_BY_ID, getBookingsById),
    takeEvery(actions.GET_ENQUIRY_BY_ID, getEnquiryById),
    takeEvery(actions.PATCH_USER, patchUserDetails),
    takeEvery(actions.GET_SAVED_ADDRESS, getSavedAddress),
    takeEvery(actions.GET_BOOKING_PACKAGE, getBookingPackage),
    takeEvery(actions.GET_NOTIFICATION, getNotifications),
    takeEvery(actions.GET_VEHICLE_TYPE,getVehicleType),
    takeEvery(actions.POST_CONTACT_US, postContactUs),
    takeEvery(actions.GET_CAR_PHOTOS, getCarPhotos),
    takeEvery(actions.GET_CAR_DOCUMENTS, getCarDocuments),
    takeEvery(actions.GET_CAR_FACILITIES, getCarFacilities),
    takeEvery(actions.GET_DRIVER_FACILITIES, getDriverFacilities),
    takeEvery(actions.GET_CUSTOM_WEB_DATA, getCustomWebData),
    takeEvery(actions.GET_SUPPORT_DATA, getSupportData),
    takeEvery(actions.GET_SERVICE_LOCATIONS, getServiceLocations),
    takeEvery(actions.GET_LINKS, getLinks),
    takeEvery(actions.GET_LINK_INFO, getLinkInfo),
    takeEvery(actions.GET_CITIES_SERVED, getCitiesServed)
  ])
}
