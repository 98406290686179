import React, { Component } from 'react';
import { isCustomWeb } from '../../utils/helper-functions'

import Image1 from '../../image/bottom/CarOwner_icon.png';
import Image2 from '../../image/bottom/CarRental_icon.png';
import Image3 from '../../image/bottom/FleetAggregators_icon.png';
import Image4 from '../../image/bottom/TourOperators_icon.png';
import Image5 from '../../image/bottom/TravelCompanies_icon.png';
import Image6 from '../../image/bottom/Airlines_icon.png';
import Image7 from '../../image/bottom/Events_icon.png';
import Image8 from '../../image/bottom/Hotels_icon.png';
import Image9 from '../../image/bottom/Corporate_icon.png';
import Copowerred from '../../image/static/copowerred-logo-new.png';
// import Copowerred from '../../image/static/Group 61707.png';

export default class NavBottom extends Component {
  render() {
    return (
      <div>
        <div className="stakeholder-wrapper overflow--hidden">
          <div className="stakeholder-left wd-100">
            <div className="stakeholder-text color-white">
              {isCustomWeb() ? <div className="flex-xs-row-sm-col flex-center-content stake">
                {typeof window !== "undefined" && window.innerWidth <= 1024 && <div style={{
                  fontWeight: "bold",
                  fontSize: "6.3vw",
                  color: "#ffdd00"
                }} className="stakeholder--height"><div className="stakeholder--header">BOOK CABS ALL OVER INDIA! </div></div>}
                <div >#DigitalIndia #VocalForLocal</div>
                {/* {typeof window !== "undefined" && window.innerWidth >= 1024 && <img className="stakeholder--img" src={Copowerred} height="45" />} */}
                </div> : "#DigitalIndia #VocalForLocal"}
              </div>
          </div>
        </div>
      </div>
    );
  }
}
