import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import CustomSidebar from '../../customsidebar';
import { Layout } from 'antd';
import IntroSection from '../../../containers/staticSections/introSection';
import Features from '../../../containers/staticSections/features';
import Offering from '../../../containers/staticSections/offering';
import FleetSection from '../../../containers/staticSections/fleet';
import AboutSection from '../../../containers/staticSections/about';
import CustomAboutSection from '../../../containers/staticSections/customAbout';
import TeamSection from '../../../containers/staticSections/team';
import DownloadSection from '../../../containers/staticSections/downloadApp';
// import Contact from '../../../containers/staticSections/contact';
import HelpSection from '../../../containers/staticSections/help';
import NavBottom from '../../../containers/NavBottom/NavBottom';
import { BackTop } from 'antd';
import { Row, Col } from 'antd';
import Social from '../../../containers/staticSections/socialLinks';
import CustomSocialMedia from '../../../containers/staticSections/customSocialMedia';
// import DynamicImport from '../../../helpers/DynamicImport';
import bookingActions from '../../redux/Booking/actions';
import CitiesServed from '../../../containers/seoSections/CitiesServed';
import {
  HOME_TRANSFER_URL,
  HOME_RENTAL_URL,
  HOME_OUTSTATION_URL,
  HOME_EVENT_URL,
  HOME_LONG_TERM_URL,
  HOME_CUSTOMIZE_URL,

  TRANSFER_BOOKING_TYPE,
  RENTAL_BOOKING_TYPE,
  OUTSTATION_BOOKING_TYPE,
  EVENT_BOOKING_TYPE,
  LONG_TERM_BOOKING_TYPE,
  CUSTOMIZE_BOOKING_TYPE,

  IMAGE_FOR_BOOKING_TYPE,
} from '../../../utils/constants';
import { isCustomWeb } from '../../../utils/helper-functions'
import Mobilebar from '../../mobilebar';
import { BookingTextDescription } from "../../components/BookingTextDescription";
import Rentals from '../Booking/rentals';

const { saveBookingDetails } = bookingActions;

class Home extends Component {

  constructor(props) {
    super(props);
    const { bookingStatesToSave, editClicked } = Home.constructStateFromProps(props.bookingDetails);

    this.state = {
      editClicked
    };

    this.bookingStatesToSave = bookingStatesToSave;

    this.sideBarOptionsForHome = [
      { name: 'RIDE', pathname: HOME_TRANSFER_URL, bookingType: TRANSFER_BOOKING_TYPE },
      { name: 'LOCAL/RENTALS', pathname: HOME_RENTAL_URL, bookingType: RENTAL_BOOKING_TYPE },
      { name: 'OUTSTATION', pathname: HOME_OUTSTATION_URL, bookingType: OUTSTATION_BOOKING_TYPE },
      { name: 'EVENTS/BULK', pathname: HOME_EVENT_URL, bookingType: EVENT_BOOKING_TYPE },
      { name: 'LONG TERM HIRE', pathname: HOME_LONG_TERM_URL, bookingType: LONG_TERM_BOOKING_TYPE },
      { name: 'CUSTOMIZE', pathname: HOME_CUSTOMIZE_URL, bookingType: CUSTOMIZE_BOOKING_TYPE }
    ];
    this.animatedImage = createRef();
    this.resizeInterval = null;
  }

  static constructStateFromProps(props) {
    const bookingStatesToSave = {
      [TRANSFER_BOOKING_TYPE]: props[TRANSFER_BOOKING_TYPE] ? props[TRANSFER_BOOKING_TYPE] : {},
      [RENTAL_BOOKING_TYPE]: props[RENTAL_BOOKING_TYPE] ? props[RENTAL_BOOKING_TYPE] : {},
      [OUTSTATION_BOOKING_TYPE]: props[OUTSTATION_BOOKING_TYPE] ? props[OUTSTATION_BOOKING_TYPE] : {},
      [EVENT_BOOKING_TYPE]: props[EVENT_BOOKING_TYPE] ? props[EVENT_BOOKING_TYPE] : {},
      [LONG_TERM_BOOKING_TYPE]: props[LONG_TERM_BOOKING_TYPE] ? props[LONG_TERM_BOOKING_TYPE] : {},
      [CUSTOMIZE_BOOKING_TYPE]: props[CUSTOMIZE_BOOKING_TYPE] ? props[CUSTOMIZE_BOOKING_TYPE] : {}
    };

    const editClicked = {
      [TRANSFER_BOOKING_TYPE]: false,
      [RENTAL_BOOKING_TYPE]: false,
      [OUTSTATION_BOOKING_TYPE]: false,
      [EVENT_BOOKING_TYPE]: false,
      [LONG_TERM_BOOKING_TYPE]: false,
      [CUSTOMIZE_BOOKING_TYPE]: false
    };

    return { bookingStatesToSave, editClicked };
  }

  componentDidMount() {
    // this.historyListenerUnregister = this.props.history.listen((location) => {
      // this.checkHashUrlAndNavigate(location.hash);
    // });

    // Handle initial mount navigation
    this.checkHashUrlAndNavigate(this.props.location.hash);
    if (!this.loadAnimatedImage()) {
      window.addEventListener('resize', this.processResizeEvent);
    }
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.bookingType !== this.props.bookingType) {
    //   this.loadAnimatedImage();
    // }
  }

  componentWillUnmount() {
    // this.historyListenerUnregister();
    clearTimeout(this.resizeInterval);
    window.removeEventListener('resize', this.processResizeEvent);
  }

  /**
   * Loads the animated image if the match media query is unsatisifeid. Prevent loading of this image on mobile to save bandwidth
   * @returns true if the match media query was NOT satisfied, that is the screen size was valid for the image. False otherwise
   */
  loadAnimatedImage() {
    const { matchMediaQuery, src, alt } = this.animatedImage.current.dataset;

    /*
      If the current src on the img and the src on the data image is not the same, then a new src value is needed
      Hence go ahead and set the value if the media query is satisfied.
    */
    if (src !== this.animatedImage.current.src) {

      //If the supplied media query matches then do not load the image because it must be hidden
      if (window.matchMedia(matchMediaQuery).matches) {
        return false;
      }
      this.animatedImage.current.src = src;
      this.animatedImage.current.alt = alt;
    }
    return true;
  }

  processResizeEvent = () => {

    // Wait for some time so that the dom can be stable
    clearTimeout(this.resizeInterval);
    this.resizeInterval = setTimeout(() => {

      //matchMediaQuery unsatisified. No need for listening to window resize any longer
      if (this.loadAnimatedImage()) {
        window.removeEventListener('resize', this.processResizeEvent);
      }
    }, 80);
  }

  checkHashUrlAndNavigate(hash) {
    if (hash) {
      const domNodeForHash = document.getElementById(hash.slice(1));

      // Scroll to the node if it exists, if not then simply ignore the link
      if (domNodeForHash) {
        domNodeForHash.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  saveState(type) {
    return (stateToSave) => {
      this.bookingStatesToSave[type] = stateToSave;
      this.props.saveBookingDetails(this.bookingStatesToSave);
    }
  }

  handleEditClick(type) {
    return () => {
      const editClicked = { ...this.state.editClicked };
      editClicked[type] = !this.state.editClicked[type];
      this.setState({ editClicked });
    };
  }

  renderBookingForm = ()  => {
    let bookingForm, bookingText;
    const { match, location, history } = this.props;
    switch(this.props.bookingType) {
            case RENTAL_BOOKING_TYPE:
        bookingText = (
          <BookingTextDescription bigHeading={"Book full-day or half-day rental cabs"} subHeading={'via our Car Rental marketplace'}/>
         );
        bookingForm = (
          <Rentals 
            bookingInfo={this.bookingStatesToSave[RENTAL_BOOKING_TYPE]}
            editClickHandler={this.handleEditClick(RENTAL_BOOKING_TYPE)}
            saveState = {this.saveState(RENTAL_BOOKING_TYPE)}
            routeProps = {{match, location, history}}

          />
          // <div></div>
          // <DynamicImport key="rental" importComponent={/* #__LOADABLE__ */ () => import('../Booking/rentals.js')} routeProps = {{match, location, history}}
          //   bookingInfo={this.bookingStatesToSave[RENTAL_BOOKING_TYPE]} heightForLoader={'30vh'}
          //   saveState = {this.saveState(RENTAL_BOOKING_TYPE)} editClickHandler={this.handleEditClick(RENTAL_BOOKING_TYPE)}
          // />
        );
        break;
    }

    return {bookingForm, bookingText};
  }

  // renderBookingform() {
  //   let bookingForm, bookingText;
  //   const { match, location, history } = this.props;

  //   /*
  //     IMPORTANT. Do not remove any of the below comments added before the "import functions".
  //     These are magic comments which are parsed by loadable babel plugin
  //     to transform these functions into the required format
  //   */
  //   switch(this.props.bookingType) {
  //     case TRANSFER_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={'Book Airport or City Taxi'} subHeading={'via our car rental marketplace'}/>
  //        );
  //       bookingForm = (
  //         <DynamicImport key="transfer" importComponent={/* #__LOADABLE__ */ () => import('../Booking/airport.js')}
  //           routeProps = {{match, location, history}} bookingInfo={this.bookingStatesToSave[TRANSFER_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(TRANSFER_BOOKING_TYPE)} editClickHandler={this.handleEditClick(TRANSFER_BOOKING_TYPE)}
  //         />
  //       );
  //       break;
  //     case RENTAL_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={"Book full-day or half-day rental cabs"} subHeading={'via our Car Rental marketplace'}/>
  //        );
  //       bookingForm = (
  //         <DynamicImport key="rental" importComponent={/* #__LOADABLE__ */ () => import('../Booking/rentals.js')} routeProps = {{match, location, history}}
  //           bookingInfo={this.bookingStatesToSave[RENTAL_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(RENTAL_BOOKING_TYPE)} editClickHandler={this.handleEditClick(RENTAL_BOOKING_TYPE)}
  //         />
  //       );
  //       break;

  //     case OUTSTATION_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={'Best Rates for Outstation Cab/Taxi'} subHeading={'via our Car Rental marketplace'}/>
  //         );
  //       bookingForm = (
  //         <DynamicImport key="outstation" importComponent={/* #__LOADABLE__ */ () => import('../Booking/outstation.js')} routeProps = {{match, location, history}}
  //           bookingInfo={this.bookingStatesToSave[OUTSTATION_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(OUTSTATION_BOOKING_TYPE)} editClickHandler={this.handleEditClick(OUTSTATION_BOOKING_TYPE)}
  //         />
  //       );
  //       break;
  //     case EVENT_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={'Book Cabs for events'} subHeading={'via our Car Rental marketplace'}/>
  //        );
  //       bookingForm = (
  //         <DynamicImport key="eventBooking" importComponent={/* #__LOADABLE__ */ () => import('../Booking/eventBooking.js')} routeProps={{ match, location, history }}
  //           bookingInfo={this.bookingStatesToSave[EVENT_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(EVENT_BOOKING_TYPE)} editClickHandler={this.handleEditClick(EVENT_BOOKING_TYPE)}
  //         />
  //       );
  //       break;
  //     case LONG_TERM_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={'Long Term Car Rentals'} subHeading={'via our Car Rental marketplace'}/>
  //        );
  //       bookingForm = (
  //         <DynamicImport key="longtermhire" importComponent={/* #__LOADABLE__ */ () => import('../Booking/longTermHire.js')} routeProps={{ match, location, history }}
  //           bookingInfo={this.bookingStatesToSave[LONG_TERM_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(LONG_TERM_BOOKING_TYPE)} editClickHandler={this.handleEditClick(LONG_TERM_BOOKING_TYPE)}
  //         />
  //       );
  //       break;
  //     case CUSTOMIZE_BOOKING_TYPE:
  //       bookingText = (
  //         <BookingTextDescription bigHeading={'Custom made Car Rental Solutions!'} subHeading={'We shall customize it for you'}/>
  //        );
  //       bookingForm = (
  //         <DynamicImport key="customize" importComponent={/* #__LOADABLE__ */ () => import('../Booking/customize.js')} routeProps={{ match, location, history }}
  //           bookingInfo={this.bookingStatesToSave[CUSTOMIZE_BOOKING_TYPE]} heightForLoader={'30vh'}
  //           saveState = {this.saveState(CUSTOMIZE_BOOKING_TYPE)}
  //         />
  //       );
  //       break;
  //     default:
  //       break;
  //   }

  //   return {bookingForm, bookingText};
  // }

  render() {

    console.log(this.renderBookingForm, "this.renderBookingForm")
    const { bookingForm, bookingText } = this.renderBookingForm();
    console.log(this.props, "location")
    const { match, location, history } = this.props;
    // console.log(this.props, "location")

    return (
      <Layout>
        <Row className="home-page--container">
          <Row className="home-page--content-container">
            <Col xs={24} md={0}>
              <Row>
                <Col>
                  <Mobilebar sideBarOptions={this.sideBarOptionsForHome} bookingType={this.props.bookingType} />
                </Col>
              </Row>
            </Col>

            <div className="side-bar--container-home">
              <CustomSidebar sideBarOptions={this.sideBarOptionsForHome} bookingType={this.props.bookingType} classForSideBar={'customSidebar'} />
            </div>
            <Col className="booking-form--text_description-container">
              {!this.state.editClicked[this.props.bookingType] && bookingText}
            </Col>
            <Col className="booking-form--home-container">
              {/* {bookingForm} */}
            </Col>

            <Col className="gif-container--home text-align-center">
              {/* data-match-media-query mimics the media query defined in the CSS file*/}
              <img
                className="gif-container--image"
                data-src={IMAGE_FOR_BOOKING_TYPE[this.props.bookingType].imgSrc}
                data-match-media-query="screen and (max-width: 1300px)"
                data-alt={IMAGE_FOR_BOOKING_TYPE[this.props.bookingType].imgAlt}
                ref={this.animatedImage}
              />
            </Col>

            {typeof window !== "undefined" && window.innerWidth >= 1024 && <Col className="socials--home-container">
              {isCustomWeb() ? <CustomSocialMedia /> : <Social />}
            </Col>}

          </Row>

          
          <Row className="nav-bottom--home">
            <NavBottom />
            {typeof window !== "undefined" && window.innerWidth < 1024 && <Col className="socials--home-container">
              {isCustomWeb() ? <CustomSocialMedia /> : <Social />}
            </Col>}
          </Row>

        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <BackTop />
            {!isCustomWeb() && <IntroSection />}
            <Features />
            <Offering pathname={location && location.pathname} />
            <CitiesServed pathname={location && location.pathname} />
            <FleetSection />
            {!isCustomWeb() && <AboutSection />}
            {!isCustomWeb() && <TeamSection />}
            {isCustomWeb() && <CustomAboutSection />}
            
            <DownloadSection downloadLink={""} />
            {/* <Contact /> */}
            <HelpSection />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default connect(state => {
  const stateObj = state.bookings;
  return {
    bookingDetails: stateObj.bookingDetails
  };
}, {
  saveBookingDetails
})(Home)
