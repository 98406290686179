export const API_CONSTANT = `${process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_API}/api/`
export const DATE_FORMAT = "MMM Do YYYY";
export const TIMESTAMP_FORMAT = "MMMM Do YYYY, h:mm:ss a"
export const GOOGLE_MAP_URL =  `https://maps.googleapis.com/maps/api/js?key=AIzaSyD2uLAOSaO79rd7zXUK_t1nEP5nRbRmuzo&libraries=places&callback=handleGoogleMapsReady`
export const FILE_PATH = `${process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_STATIC}/uploads/`
export const FILE_UPLOAD_ACTION =`${process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_API}/api/file/uploadCustomerProfile`
export const FILE_PATH_NOTIFY = `${process.env.REACT_APP_CUSTOMER_WEB_DOMAIN_STATIC}/static/notifications`
export const GOOGLE_MAP_KEY = returnGoogleMapsKey()
export const APPLICATION_ID = `${process.env.REACT_APP_APPLICATION_ID}`
export const CUSTOM_WEB = `YES`

function returnGoogleMapsKey() {
    if(typeof window !== "undefined" && localStorage){
        return "AIzaSyD2uLAOSaO79rd7zXUK_t1nEP5nRbRmuzo"
        // localStorage.getItem("apiKey")
    }
    return "AIzaSyD2uLAOSaO79rd7zXUK_t1nEP5nRbRmuzo"
}