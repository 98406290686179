import React, { Component, createRef } from 'react';
import Image1 from '../../image/static/about/AboutUs_graphic.png';
import Image2 from '../../image/static/about/YellowPlateBrand_image.png';
import Image3 from '../../image/static/about/YelloPlate_logo.png';
import Rest from '../../utils/Rest'
import {FILE_PATH} from "../../customApp/config"
import ImageViewer from "../../customApp/components/ImageViewer";

import { Row } from 'antd';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class AboutSection extends Component {

  constructor() {
    super();
    this.aboutContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
    this.state = {
        aboutUs: "",
        aboutUsGallery: {}
    }
    this.aboutUsComponent = React.createRef(); 
  }

  async aboutus(){
    const x  = await Rest.get('partner/config/about-us', {
        customHeaders: {
          'Accept': 'text/html',
          'Content-Type': 'text/html',
        },
        processResponseType: 'textResponse'
      })
    return x
  }
  async componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.aboutContainer.current, 'about');
    const aboutUsInfo = await Rest.get('partner/config/about-us', {
        customHeaders: {
          'Accept': 'text/html',
          'Content-Type': 'text/html',
        },
        processResponseType: 'textResponse'
    })

    const aboutUsGallery = await Rest.get('partner/config/gallery');
    // this.aboutus()
    // console.log(x)
    this.setState({aboutUs: aboutUsInfo.replace(/\n/g, "<br />"), aboutUsGallery: aboutUsGallery})
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.aboutContainer.current, 'about');
  }

  handleAboutContainerMount = (refVal) => {
    if(refVal) {
      this.aboutContainer.current = refVal;
    }
  }

  stringToHTML = function (str) {
    var dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  };

  componentDidUpdate(prevProps, prevState){
    if(prevState.aboutUs!==this.state.aboutUs && this.aboutUsComponent){
      this.aboutUsComponent.current.innerHTML = this.state.aboutUs
    }
  }

  render() {
    return (
      <div id="aboutUs" className="about-us--container bcwhite" ref={this.handleAboutContainerMount}>
        <div className="color-Black">About Us</div><br />

        <div className="fS18e marginTop10 marginBtm20" ref={this.aboutUsComponent}>
            {/* {this.state.aboutUs && this.stringToHTML(this.state.aboutUs)} */}
            {/* {this.state.aboutUs} */}
        </div>

        <div className="fS18e marginTop10 about-us--gallery custom-scrollbar">
            {this.state.aboutUsGallery && this.state.aboutUsGallery["videos"] && this.state.aboutUsGallery["videos"].length > 0 && 
                 this.state.aboutUsGallery["videos"].map(video => 
                        (<iframe src={`https://www.youtube.com/embed/${video.split("/")[video.split("/").length - 1]}`} width="200" height="200" style={{padding: "10px 0px"}} />)
                    )
            }
            {this.state.aboutUsGallery && this.state.aboutUsGallery["photos"] && this.state.aboutUsGallery["photos"].length > 0 && 
                 this.state.aboutUsGallery["photos"].map(image => 
                        // (<img src={`${FILE_PATH}${image}`}  width="400" height="300" style={{margin: "10px 0px"}}  />)
                        (<div className="aboutUs--img"><ImageViewer src={image} imgName={""} static={true} classForImage={"padding10"} /></div>)
                    )
            }
        </div>

      </div>
    );
  }
}
