import React, { Component, createRef } from 'react';
import Image1 from '../../image/static/intro-section/cab_background.jpg';
import Image2 from '../../image/static/intro-section/cab_background_mobile_1000.jpg';
import Image3 from '../../image/static/intro-section/cab_background_mobile_800.jpg';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class IntroSection extends Component {

  constructor() {
    super();
    this.introContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.introContainer.current, 'intro');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.introContainer.current, 'intro');
  }

  handleIntroContainerMount = (refVal) => {
    if(refVal) {
      this.introContainer.current = refVal;
    }
  }

  render() {
    return (
      <div className="pos-relative overflow--hidden" ref={this.handleIntroContainerMount}>
        <div className="intro--container display--flex">
          <div className="color-Black intro--title">Enabling and Empowering Supply and Demand Stakeholders in the Car/Cab/Taxi Rental Market</div>
          <div className="color-Black fS18e">
            Yellow Plate via its technology platform is enabling millions of businesses including intermediaries
            engaged in providing car rental services; leading to demand segments receiving tech-enabled
            services from their own service providers. Yellow Plate subsequently empowers every stakeholder
            across supply and demand segment by inter-connecting and integrating with its marketplace.
            <br />
            <br />
            Customers can create a personal booking via the website or/and customer app and get cars/cabs
            from the marketplace. They can alternatively create a business booking and get cars from their
            own/company’s registered vendors; eliminating manual inefficiencies, billing issues and bringing
            transparency.
          </div>
        </div>

        {/*
          Hack to get the optimized image displayed on mobile devices.
          For a max-width of 1100px the alternate image must be shown, the below rules will apply
          Browser will download 800px image for all devices with device-pixel ratio as 1;
          this sacrifices image quality for those who view the site on desktops from 800px to 1100px.
          Browser will download either the 1000px image or 800px image for mobiles/tablets
          as long as the device's device-pixel ratio is approximately less than 3.5.
          TODO change the design so that responsiveness can be better maintained rather
          than coupling HTML and css and making unscalabale assumptions.
        */}
        <img
          alt="Digitising, Enabling And Empowering Supply And Demand Stakeholders I.E. Car Rental Operators/Agencies/Companies, Tour Operators, Travel Agents/Companies, Corporates, Hotels, Events/MICE Companies, Retail Customers Connected With Car Rental Segment/Industry In India. "
          className={'intro--img'}
          data-src-set={`${Image1} 1366w, ${Image2} 1000w, ${Image3} 800w`}
          data-sizes={'(max-width: 1100px) 300px, 1366px'}
          data-src={Image1}
        />

      </div>
    );
  }
}
