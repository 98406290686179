import React, { Component, Fragment, createRef } from 'react';
import Vinit from '../../image/static/team/Vinit-Tulsyan.jpg';
import Aman from '../../image/static/team/Aman.jpg';
import Ashfaque from '../../image/static/team/Ashfaque.jpg';
import Nischal from '../../image/static/team/Nischal.jpg';
import ln from '../../image/static/linkedin-sign.svg';
import Amit from '../../image/static/team/Amit.jpg';

import { Row, Col } from 'antd';
import Modal from '../../components/uielements/CustomModal';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class TeamSection extends Component {
  constructor() {
    super()
    this.state ={
      visible: false
    };
    this.teamsContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.teamsContainer.current, 'team');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.teamsContainer.current, 'team');
  }

  handleTeamContainerMount = (refVal) => {
    if(refVal) {
      this.teamsContainer.current = refVal;
    }
  }

  openProfile =() => {
    this.setState({ visible: true });
  }

  hideModal = () => {
    this.setState({ visible: false });
  }

  renderModalContent() {
    return (
      <Modal
        visible={this.state.visible}
        className="custom-modal"
        footer=""
        width='80%'
        onCancel={this.hideModal}
      >
        <Row className="padding20">
          <Col md={6} >
            <img alt="Vinit Tulsyan - Founder And CEO" className="width100P marginBtm2" src={Vinit} />
          </Col>
          <Col className="paddingLeft35" md={18}>
            <div className="fS16 color-Black">
              <div className="fS31 fwb paddingBtm10">Vinit Tulsyan <span><a href="https://www.linkedin.com/in/vinittulsyan/" target="_blank" rel="noopener noreferrer" className="team_linkedin-container pos-relative"><img src={ln} alt="Follow me on Linked In"/></a></span></div>
              <div className="fS24 paddingBtm20">Founder CEO</div>

              Vinit’s Journey is defined by unimaginable dreams, impeccable planning, and on-ground execution to
              create scalable and sustainable enterprises by focusing on segments/solutions necessarily required
              by a billion people.
              <br />
              <br />
              Vinit is a corporate strategy professional turned entrepreneur having experience across functions I.e.
              business planning/strategy, team building and development, business development, operations,
              finance, due diligence, diversification, capital markets, M&amp;A, IPO, Legal etc. across multiple
              segments/domains I.e. Startups, Education, Employability/Skill Development, Transportation,
              Hospitality, Travel, Healthcare, Real Estate, Infrastructure, Agri and Retail etc.
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="fS16 color-Black" md={24}>
            He believes ‘organizing the massive unorganized’ entrepreneurial class’ could lead to the creation of
            one of India’s largest companies.
            <br />
            <br />
            Coming from rural India, he thought of solving ‘accessibility’ and ‘affordability’ challenges concerning
            India’s education by 1) having one school in each of India’s 6,000 blocks and 2) providing the best
            quality, English medium, tech-enabled education at cost, the mass population could afford. In
            partnership with one of the world’s largest education companies, Vinit embarked on this journey,
            resulting in 370 Dream India Schools, over 200,000 students, 9,000 academic and non-academic staff
            spread across 250 district headquarters including divisional headquarters and capital cities
            <br />
            <br />
            Prior to Dream India Schools, Vinit as CEO built one of India’s largest education and training/skill
            development companies having a presence across 7 states.
            <br />
            <br />
            Vinit, with support from an amazing team, has now embarked upon a new entrepreneurial journey
            to create an eco-system for the necessity transport/commercial vehicles segment.
          </Col>
        </Row>
      </Modal>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderModalContent()}
        <div className="bcwhite team-container" ref={this.handleTeamContainerMount}>
          <div className="text-align-center color-Black">Our Team</div>
          <div className="padding40 team-container__image-container">
            <div onClick={this.openProfile} className="pos-relative team-container__image-container__details pointer">
              <img alt="Vinit Tulsyan - Founder And CEO" className="marginBtm2" data-src={Vinit}/>
              <div className="position-abs btm-0 zIndex9 gradient width100P paddingTop60 color-white padding5 paddingLeft5">
                <p className="fS24"> Vinit Tulsyan <span><a href="https://www.linkedin.com/in/vinittulsyan/" target="_blank" rel="noopener noreferrer" className="team_linkedin-container pos-relative"><img alt="Vinit Tulsyan - Founder And CEO" src={ln} /></a></span></p>
                <p className="fS18e">Founder CEO</p>
                <p className="fS17">Strategy, Team Building, Execution >></p>
              </div>
            </div>
            <div className="pos-relative team-container__image-container__details">
              <img alt="Ashfaque Ahmed - Director" className="marginBtm2" data-src={Ashfaque} />
              <div className="position-abs btm-0 zIndex9 gradient width100P paddingTop60 color-white padding5 paddingLeft5">
                <p className="fS24">Ashfaque Ahmed</p>
                <p className="fS18e">Director</p>
                <p className="fS17">Banking & Finance</p>
              </div>
            </div>
            <div className="pos-relative team-container__image-container__details">
              <img alt="Nischal - Co-founder" className="marginBtm2" data-src={Nischal}/>
              <div className="position-abs btm-0 zIndex9 gradient width100P paddingTop60 color-white padding5 paddingLeft5">
                <p className="fS24">Nischal </p>
                <p className="fS18e">Co-founder</p>
                <p className="padding10"></p>
              </div>
            </div>
            <div className="pos-relative team-container__image-container__details">
              <img alt="Aman - Co-founder" className="marginBtm2" data-src={Aman}/>
              <div className="position-abs btm-0 zIndex9 gradient width100P paddingTop60 color-white padding5 paddingLeft5">
                <p className="fS24">Aman </p>
                <p className="fS18e">Co-founder</p>
                <p className="padding10"></p>
              </div>
            </div>
            <div className="pos-relative team-container__image-container__details">
              <img alt="Amit Srivastava - Operations" className="marginBtm2" data-src={Amit}/>
              <div className="position-abs btm-0 zIndex9 gradient width100P paddingTop60 color-white padding5 paddingLeft5">
                <p className="fS24">Kirti Poojari</p>
                <p className="fS18e">Project Manager</p>
                <p className="padding10"></p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
