import React, { Component, createRef } from 'react';
import Image2 from '../../image/static/download-app/MobileApp_screens.png';
import Image3 from '../../image/static/download-app/google-play-badge.png';
import LazyLoadImage from '../../utils/LazyLoadImage';
import {connect} from 'react-redux'

class DownloadSection extends Component {
  constructor() {
    super();
    this.downloadAppContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.downloadAppContainer.current, 'downloadApp');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.downloadAppContainer.current, 'downloadApp');
  }

  handleDownloadContainerMount = (refVal) => {
    if(refVal) {
      this.downloadAppContainer.current = refVal;
    }
  }

  render() {
    return (
      <div id="downloadApp" className="download-app-container bg-feature" ref={this.handleDownloadContainerMount}>
        <div className="download-app-container__text">
          <div className="download-app-container__text__title color-Black">Download the {this.props.customWebData.businessName} App</div>
          <div>
            <a href={this.props.customWebData.playStoreLink} target="_blank" rel="noopener noreferrer">
            <img alt="Download Yellow Plate Customer App From Play Store." data-src={Image3} className={"download-app-container__text__download-image"} />
            </a>
          </div>
        </div>
        <div class="download-app-container__image">
          <img alt="Book Airport, Local/Rental, Outstation Cabs Via Yellow Plate Customer App, Available On Play Store. " data-src={Image2} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const stateObj = state.bookings;
  return {

    customWebData: stateObj.customWebData,
  };
}

export default connect(
  mapStateToProps,
  null,
)(DownloadSection);