import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './location-actions';
import { callAPI } from '../../apiutils';

const API_LIST = {
	GET_MISSING_ROUTE : 'geoLocation/nearByLocation'
};

function* getMissingRoute(data) {
  try {
    const routeInfo = yield call(callAPI.bind(this, API_LIST.GET_MISSING_ROUTE, 'POST', data.tripLocationInfo));
    yield put(actions.getMissingRouteSuccess(routeInfo));
  } catch (error) {
    yield put(actions.getMissingRouteFailure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(actions.GET_MISSING_ROUTE_PROGRESS, getMissingRoute)
  ])
}
