import { isCustomWeb } from "./helper-functions";

function returnPhoneNumber(){
  if(typeof window !== "undefined" && localStorage){
    if(true) {
      if(localStorage.getItem("basicInfo")!=undefined){
        return JSON.parse(localStorage.getItem("basicInfo"))['phoneNumber']
      }else{
        return ""
      }
    }else{
      return '+91 7045279411';
    }
  }
  return ""
}
export const TRANSFER_BOOKING_TYPE = 'TRANSFER';

export const RENTAL_BOOKING_TYPE = 'LOCAL';

export const OUTSTATION_BOOKING_TYPE = 'OUTSTATION';

export const EVENT_BOOKING_TYPE = 'Event';

export const LONG_TERM_BOOKING_TYPE = 'Long Term';

export const CUSTOMIZE_BOOKING_TYPE = 'Customize';

//TODO this will be used and its URL will have to be changed
const TRANSFER_URL = '/car-rental-for-city-travel';

const RENTAL_URL = '/car-rental-for-city-travel';

const OUTSTATION_URL = '/rent-cabs-for-outstation-travel';

const EVENT_URL = '/rent-cabs-for-event';

const LONG_TERM_URL = '/rent-cabs-for-longterm';

const CUSTOMIZE_URL = '/customized-car-rental-solutions';

const SEARCH_TRANSFER_URL = '/airport-taxi';

const SEARCH_RENTAL_URL = '/hourly-car-rental';

const SEARCH_OUTSTATION_URL = '/outstation-car-rental';

const SEARCH_EVENT_URL = '/event-car-rental';

const SEARCH_LONG_TERM_URL = '/monthly-car-rental';

export const SEARCH_CABS_BASE_PATH = '/search-cabs';

export const HOME_URL = '/';

/*
  The HOME_TRANSFER_URL deliberately points to slash.
  This ensures the customer web loads at <domain>
  This keeps the first visit without changing any URLs
  TODO change this to transfer when the transfer link is added with new content
  TODO for now it will remain like this for backward compatibility with other pages
*/
export const HOME_TRANSFER_URL = `${TRANSFER_URL}`;

export const HOME_RENTAL_URL = `${HOME_URL}`;

export const HOME_OUTSTATION_URL = `${OUTSTATION_URL}`;

export const HOME_EVENT_URL = `${EVENT_URL}`;

export const HOME_LONG_TERM_URL = `${LONG_TERM_URL}`;

export const HOME_CUSTOMIZE_URL = `${CUSTOMIZE_URL}`;

export const SEARCH_CABS_TRANSFER_URL = `${SEARCH_CABS_BASE_PATH}${SEARCH_TRANSFER_URL}`;

export const SEARCH_CABS_RENTAL_URL = `${SEARCH_CABS_BASE_PATH}${SEARCH_RENTAL_URL}`;

export const SEARCH_CABS_OUTSTATION_URL = `${SEARCH_CABS_BASE_PATH}${SEARCH_OUTSTATION_URL}`;

export const SEARCH_CABS_EVENT_URL = `${SEARCH_CABS_BASE_PATH}${SEARCH_EVENT_URL}`;

export const SEARCH_CABS_LONG_TERM_URL = `${SEARCH_CABS_BASE_PATH}${SEARCH_LONG_TERM_URL}`;

export const AUTHENTICATE = 'AUTHERNTICATE';

export const SIGNUP_URL = '/car-rental-marketplace-account';

export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';

export const CONFIRM_BOOKING_URL = '/ConfirmBooking';

export const CONFIRMED = 'CONFIRMED';

export const CONFIRMED_URL = '/Confirmed';

export const MYBOOKING = 'MYBOOKING';

export const MYBOOKING_URL = '/myBooking';

export const BOOKING_DETAILS = 'BOOKING_DETAILS';

export const BOOKING_DETAILS_URL = '/booking-details';

export const PROFILE_VIEW = 'PROFILE_VIEW';

export const PROFILE_VIEW_URL = '/profile';

export const PROFILE_EDIT = 'PROFILE_EDIT';

export const PROFILE_EDIT_URL = '/editProfile';

export const DATE_FORMAT = "ddd, MMM Do YYYY";

export const STEP_INTERVAL_BOOKING_TIME_PICKER = 15;

//IMPORTANT: DEclare it here, the value is initialized later because the import was not working here.
export const IMAGE_FOR_BOOKING_TYPE = {
};

export const HELPLINE_NUMBER = returnPhoneNumber() 

export const HOME_ADDRESS_TYPE = 'home';

export const OFFICE_ADDRESS_TYPE = 'office';

export const OTHER_ADDRESS_TYPE = 'other';

export const LONG_TERM_DAYS_PER_WEEK = [
  'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'
];

export const DEFAULT_USER_PROFILE_IMAGE = "3b584e61-426a-427a-9da8-142abf39872f.png";

export const PRIVACY = `/car-rental-marketplace-privacy`;

export const TERMS_CONDITIONS = `/car-rental-marketplace-terms-conditions`;

export const NOTES_OF_FARE =  ["Point to Point Billing I.e. Pickup location to drop location.", "Transfer booking is only for transfers within city…ges can’t be changed after booking is confirmed. ", "Additional fare applicable as per above mentioned rates for usage beyond the selected package.", "Toll/Parking shall be added to final bill.", "Additional GST may be applicable.", "Minimum cancellation charges of Rs. 499 applicable… cancelled post vehicle reporting or pickup time."]

export const EMAIL_ID = 'enquiry@yellowplate.in'

export const HELPLINE_NUMBER_2 = '+91 7045247293'

export const CANCELLED = 'CANCELLED'

export const SCHEDULED = 'CREATED'

export const INPROGRESS = "STARTED"

export const COMPLETED = "COMPLETED"

export const ACCEPTED_UNASSIGNED = "ACCEPTED_UNASSIGNED"

export const ACCEPTED_ASSIGNED = 'ACCEPTED_ASSIGNED'

export const ASSIGNED = 'ASSIGNED'