import actions from './actions';

const initState = {
  booking: [],
  searchCabResult: undefined,
  searchCabResultFailure: undefined,
  confirmCabBooking: undefined,
  confirmCabBookingFailure: undefined,
  cancelCabBooking: undefined,
  cancelCabBookingFailure: undefined,
  signupDetails: {},
  signupDetailResult: undefined,
  signupDetailResultFailure: undefined,
  verifyOtpDetailResult: undefined,
  verifyOtpDetailResultFailure: undefined,
  phoneNumber: '',
  otp: '',
  customBookingCriteria: '',
  customBookingCriteriaFailure: undefined,
  customerBookings: undefined,
  customerBookingsFailure: undefined,
  customerId: '',
  customerEdit: undefined,
  customerEditFailure: undefined,
  customerDetails: "",
  customerDetailsFailure: undefined,
  newCustomerDetails: undefined,
  newCustomerDetailsFailure: undefined,
  bookingId: '',
  updateCustomer: '',
  savedAddress: '',
  savedAddressFailure: undefined,
  bookingPackage: '',
  bookingPackageFailure: undefined,
  bookingType: '',
  enquiryResult: undefined,
  enquiryResultFailure: undefined,
  notificationList: '',
  searchBookingResult: undefined,
  searchBookingResultFailure: undefined,
  vehicleList: undefined,
  vehicleListError: undefined,
  bookingDetails: {},
  contactUs: {},
  contactUsFailure: undefined,
  customWebData: {},
  cardData: undefined,
  cardDataSuccess: undefined,
  serviceLocationsList: undefined,
  serviceLocationsLoading: undefined,
  supportData: undefined,
  links: undefined,
  linkInfo:  {},
  linksList: [],
  citiesServed: [],
  deliveryTime: 15
};

const handlers = {
  // [actions.GET_CAR_DOCUMENTS]: (initState, action) => {
  //   console.log(initState)
  // return ({
  //   loading: true,
  //   cardDataSuccess: {...initState.cardDataSuccess}
  // })},
  [actions.GET_LINKS]: (_, action) => {
    return ({
      linksLoading: true,
      linksList: []
    })
  },
  [actions.GET_LINKS_SUCCESS]: (_, action) => {
    console.log(action)
    return ({
      linksLoading: false,
      linksList: action.linksList
    })
  },
  [actions.GET_LINKS_FAILURE]: (_, action) => {
    return ({
      linksLoading: false,
      linksList: []
    })
  },
  [actions.GET_LINK_INFO]: (_, action) => {
    return ({
      linkInfo: {}
    })
  },
  [actions.GET_LINK_INFO_SUCCESS]: (_, action) => {
    return ({
      linkInfo: action.linkInfo
    })
  },
  [actions.GET_LINK_INFO_FAILURE]: (_, action) => {
    return ({
      linkInfo: {}
    })
  },
  [actions.GET_CITIES_SERVED]: (_, action) => {
    return ({
      citiesServed: []
    })
  },
  [actions.GET_CITIES_SERVED_SUCCESS]: (_, action) => {
    return ({
      citiesServed: action.citiesServed
    })
  },
  [actions.GET_CITIES_SERVED_FAILURE]: (_, action) => {
    return ({
      citiesServed: []
    })
  },
  [actions.GET_SERVICE_LOCATIONS]: (initState, action) => {
    return ({
      serviceLocationsLoading: true,
      serviceLocationsList: undefined
    })
  },
  [actions.GET_SERVICE_LOCATIONS_SUCCESS]: (initState, action) => {
    return ({
      serviceLocationsLoading: false,
      serviceLocationsList: action.cities
    })
  },
  [actions.GET_CAR_DOCUMENTS_SUCCESS]: (_, action) => {
  return ({
    loading: false,
    cardDataSuccess:  {...initState.cardDataSuccess, documents: action.documents}  
  })},
  [actions.GET_CAR_PHOTOS_SUCCESS]: (initState, action) => {
  return ({
    loading: false,
    cardDataSuccess: {...initState.cardDataSuccess, carInfo: action.carInfo} 
  })},
  [actions.CLEAR_CONTACT_US]: () => ({
    contactUs: {},
    contactUsFailure: undefined
  }),
  [actions.POST_CONTACT_US]: () => ({
    loading: true,
    contactUs: undefined,
    contactUsFailure: undefined
  }),
  [actions.POST_CONTACT_US_SUCCESS]: (_, action) => ({
    loading: false,
    contactUs: action.contactUs
  }),
  [actions.POST_CONTACT_US_FAILURE]: (_, action) => ({
    loading: false,
    contactUs: {},
    contactUsFailure: action.errorInfo
  }),

  [actions.GET_VEHICLE_TYPE]: () => ({
    loading: true,
    vehicleList: undefined,
    vehicleListError: undefined
  }),
  [actions.GET_VEHICLE_TYPE_SUCCESS]: (_, action) => ({
    loading: false,
    vehicleList: action.vehicleList
  }),
  [actions.GET_VEHICLE_TYPE_FAILURE]: (_, action) => ({
    loading: false,
    contactUs: action.errorInfo
  }),

  [actions.POST_CONFIRM_CAB_BOOKING]: () => ({
    loading: true,
    confirmCabBooking: undefined,
    confirmCabBookingFailure: undefined
  }),
  [actions.POST_CONFIRM_CAB_BOOKING_SUCCESS]: (_, action) => ({
    loading: false,
    confirmCabBooking: action.confirmCabBooking
  }),
  [actions.POST_CONFIRM_CAB_BOOKING_FAILURE]: (_, action) => ({
    loading: false,
    confirmCabBookingFailure: action.errorInfo
  }),

  [actions.POST_CANCEL_BOOKING]: () => ({
    loading: true,
    cancelCabBooking: undefined,
    cancelCabBookingFailure: undefined
  }),
  [actions.POST_CANCEL_BOOKING_SUCCESS]: (_, action) => ({
    loading: false,
    cancelCabBooking: action.cancelCabBooking
  }),
  [actions.POST_CANCEL_BOOKING_FAILURE]: (_, action) => ({
    loading: false,
    cancelCabBookingFailure: action.errorInfo
  }),
  [actions.UNLOAD_CANCEL_BOOKING]: () => ({
    loading: false,
    cancelCabBooking: undefined,
    cancelCabBookingFailure: undefined
  }),

  [actions.POST_SIGNUP]: () => ({
    loading: true,
    signupDetails: {},
    verifyOtpDetailResult: undefined,
    verifyOtpDetailResultFailure: undefined
  }),
  [actions.POST_SIGNUP_SUCCESS]: (_, action) => ({
    loading: false,
    verifyOtpDetailResult: action.verifyOtpDetailResult
  }),
  [actions.POST_SIGNUP_FAILURE]: (_, action) => ({
    loading: false,
    verifyOtpDetailResultFailure: action.errorInfo
  }),
  [actions.CLEAR_LOGIN_INFO]: () => ({
    verifyOtpDetailResult: undefined,
    verifyOtpDetailResultFailure: undefined,
    signupDetailResult: undefined,
    signupDetailResultFailure: undefined,
    signupDetails: {},
    customerDetails: '',
    customerDetailsFailure: undefined
  }),

  [actions.SEND_OTP]: () => ({
    loading: true,
    phoneNumber: '',
    signupDetailResult: undefined
  }),
  [actions.SEND_OTP_SUCCESS]: (_, action) => ({
    loading: false,
    signupDetailResult: action.signupDetailResult
  }),

  [actions.POST_CUSTOM_BOOKING]: () => ({
    loading: true,
    customBookingCriteria: '',
    customBookingCriteriaFailure: undefined
  }),
  [actions.POST_CUSTOM_BOOKING_SUCCESS]: (_, action) => ({
    loading: false,
    customBookingCriteria: action.customBookingCriteria
  }),
  [actions.POST_CUSTOM_BOOKING_FAILURE]: (_, action) => ({
    loading: false,
    customBookingCriteriaFailure: action.errorInfo
  }),
  [actions.CLEAR_CUSTOM_BOOKING]: () => ({
    customBookingCriteria: '',
    customBookingCriteriaFailure: undefined
  }),

  [actions.GET_RENTAL_CAB_RESULT]: (_, action) => ({
    loading: true,
    searchCabCriteria: action.searchCabCriteria,
    searchCabResult: undefined,
    searchCabResultFailure: undefined
  }),
  [actions.GET_RENTAL_CAB_RESULT_SUCCESS]: (_, action) => ({
    loading: false,
    searchCabResult: action.searchCabResult
  }),
  [actions.GET_RENTAL_CAB_RESULT_FAILURE]: (_, action) => ({
    loading: false,
    searchCabResultFailure: action.errorInfo
  }),

  [actions.REGISTER_AND_SEND_OTP]: () => ({
    loading: true,
    signupDetails: {},
    signupDetailResultFailure: undefined
  }),
  [actions.REGISTER_AND_SEND_OTP_SUCCESS]: (_, action) => ({
    loading: false,
    signupDetailResult: action.signupDetailResult
  }),
  [actions.REGISTER_AND_SEND_OTP_FAILURE]: (_, action) => ({
    loading: false,
    signupDetailResultFailure: action.errorInfo
  }),

  [actions.GET_CUSTOMER_BOOKING]: () => ({
    loading: true,
    customerBookings: undefined,
    customerBookingsFailure: undefined,
    customerId: ''
  }),
  [actions.GET_CUSTOMER_BOOKING_SUCCESS]: (_, action) => ({
    loading: false,
    customerBookings: action.customerBookings
  }),
  [actions.GET_CUSTOMER_BOOKING_FAILURE]: (_, action) => ({
    loading: false,
    customerBookingsFailure: action.errorInfo
  }),

  [actions.POST_EDIT_CUSTOMER]: () => ({
    loading: true,
    customerEdit: undefined,
    customerEditFailure: undefined
  }),
  [actions.POST_EDIT_CUSTOMER_SUCCESS]: (_, action) => ({
    loading: false,
    customerEdit: action.customer
  }),
  [actions.POST_EDIT_CUSTOMER_FAILURE]: (_, action) => ({
    loading: false,
    customerEditFailure: action.errorInfo
  }),
  [actions.UNLOAD_POST_EDIT_CUSTOMER]: () => ({
    loading: false,
    customerEdit: undefined,
    customerEditFailure: undefined
  }),

  [actions.GET_CUSTOMER]: () => ({
    loading: true,
    customerDetails: '',
    customerDetailsFailure: undefined,
    customerId: ''
  }),
  [actions.GET_CUSTOMER_SUCCESS]: (_, action) => ({
    loading: false,
    customerDetails: action.customerDetails
  }),
  [actions.GET_CUSTOMER_FAILURE]: (_, action) => ({
    loading: false,
    customerDetailsFailure: action.errorInfo
  }),

  [actions.GET_BOOKING_BY_ID]: (_, action) => ({
    loading: true,
    searchBookingResult: undefined,
    searchBookingResultFailure: undefined,
    bookingId: action.bookingId
  }),
  [actions.GET_BOOKING_BY_ID_SUCCESS]: (_, action) => ({
    loading: false,
    searchBookingResult: action.searchBookingResult
  }),
  [actions.GET_BOOKING_BY_ID_FAILURE]: (_, action) => ({
    loading: false,
    searchBookingResultFailure: action.errorInfo
  }),

  [actions.GET_ENQUIRY_BY_ID]: (_, action) => ({
    loading: true,
    enquiryResult: undefined,
    enquiryResultFailure: undefined,
    bookingId: action.bookingId
  }),
  [actions.GET_ENQUIRY_BY_ID_SUCCESS]: (_, action) => ({
    loading: false,
    enquiryResult: action.enquiryResult
  }),
  [actions.GET_ENQUIRY_BY_ID_FAILURE]: (_, action) => ({
    loading: false,
    enquiryResultFailure: action.errorInfo
  }),
  [actions.UNLOAD_BOOKING_ENQUIRY_DETAILS]: () => ({
    loading: false,
    enquiryResult: undefined,
    enquiryResultFailure: undefined,
    searchBookingResult: undefined,
    searchBookingResultFailure: undefined,
    bookingId: ''
  }),

  [actions.PATCH_USER]: (_, action) => ({
    loading: true,
    updateCustomer: action.updateCustomer,
    newCustomerDetails: undefined,
    newCustomerDetailsFailure: undefined
  }),
  [actions.PATCH_USER_SUCCESS]: () => ({
    loading: false,
    newCustomerDetails: true
  }),
  [actions.PATCH_USER_FAILURE]: (_, action) => ({
    loading: false,
    newCustomerDetailsFailure: action.errorInfo
  }),
  [actions.UNLOAD_PATCH_USER]: () => ({
    loading: false,
    newCustomerDetails: undefined,
    newCustomerDetailsFailure: undefined
  }),

  [actions.GET_SAVED_ADDRESS]: (_, action) => ({
    loading: true,
    phoneNumber: action.phoneNumber,
    savedAddress: '',
    savedAddressFailure: undefined
  }),
  [actions.GET_SAVED_ADDRESS_SUCCESS]: (_, action) => ({
    loading: false,
    savedAddress: action.savedAddress
  }),
  [actions.GET_SAVED_ADDRESS_FAILURE]: (_, action) => ({
    loading: false,
    savedAddressFailure: action.errorInfo
  }),
  [actions.UNLOAD_SAVED_ADDRESS]: () => ({
    savedAddressFailure: undefined,
    savedAddress: ''
  }),

  [actions.GET_BOOKING_PACKAGE]: (_, action) => ({
    loading: true,
    bookingType: action.bookingType,
    bookingPackage: '',
    bookingPackageFailure: undefined
  }),
  [actions.GET_BOOKING_PACKAGE_SUCCESS]: (_, action) => ({
    loading: false,
    bookingPackage: action.bookingPackage.packages,
    deliveryTime: action.bookingPackage.deliveryTime
  }),
  [actions.GET_BOOKING_PACKAGE_FAILURE]: (_, action) => ({
    loading: false,
    bookingPackageFailure: action.errorInfo
  }),
  [actions.CLEAR_BOOKING_PACKAGE_CABS]: () => ({
    deliveryTime: 15,
    bookingPackageFailure: undefined,
    bookingPackage: '',
    searchCabResult: undefined,
    searchBookingResultFailure: undefined
  }),

  [actions.GET_NOTIFICATION]: () => ({
    loading: true
  }),
  [actions.GET_NOTIFICATION_SUCCESS]: (_, action) => ({
    loading: false,
    notificationList: action.notificationList
  }),

  [actions.GET_CUSTOM_WEB_DATA]: (_, action) => ({
    loading: true,
    packageName: action.packageName,
    customWebData: {}
  }),
  [actions.GET_CUSTOM_WEB_DATA_SUCCESS]: (_, action) => ({
    loading: false,
    customWebData: action.customWebData
  }),

  [actions.GET_SUPPORT_DATA]: (_, action) => ({
    loading: true,
    packageName: action.packageName,
    supportData: {}
  }),
  [actions.GET_SUPPORT_DATA_SUCCESS]: (_, action) => ({
    loading: false,
    supportData: action.supportData
  }),

  [actions.SAVE_BOOKING_DETAILS]: (_, action) => ({
    bookingDetails: { ...action.bookingDetails }
  }),
  [actions.DELETE_BOOKING_DETAILS]: (state, action) => {
    let { bookingDetails } = state;

    // If the booking type is provided delete its details, otherwise clear out all details
    if (action.bookingType) {
      delete bookingDetails[action.bookingType];
    } else {
      bookingDetails = {};
    }

    return { bookingDetails };
  }
};

export default function bookingReducer(state = initState, action) {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }
  return { ...state, ...handler(state, action) };
};
