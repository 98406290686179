import React, { Component } from 'react';
import { Layout } from 'antd';
// import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
// import { LocaleProvider } from 'antd';
// import AppLocale from '../../languageProvider/index';
// import { getCurrentLanguage } from '../../containers/LanguageSwitcher/config';
import {PRIVACY,TERMS_CONDITIONS} from '../../utils/constants';
import {APPLICATION_ID} from '../../customApp/config'
import Rest from '../../utils/Rest';
import actions from "../../customApp/redux/Booking/actions";
import { connect } from "react-redux";
import {Helmet} from "react-helmet";

import {useLocation} from "react-router-dom";

const { getCustomWebData, getSupportData, getLinkInfo } = actions;

// const currentAppLocale = AppLocale[getCurrentLanguage().locale];

export class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggleNavbar: false
    };
  }

  componentDidMount(){
    console.log(process.env.CUSTOMER_WEB_DOMAIN_API, "app")
    this.props.getCustomWebData({packageName: APPLICATION_ID});
    this.props.getSupportData({packageName: APPLICATION_ID})
    const { pathname } = this.props.location;
    
    if(typeof window!="undefined"){
      window.addEventListener('popstate',(event) => {
        this.handleLayoutClick(event)
      })
    }
  }

  componentDidUpdate(prevProps){
    if(typeof window!="undefined" && prevProps.supportData && this.props.supportData && prevProps.supportData.deliveryTime !== this.props.supportData.deliveryTime){
      if(this.props.supportData){
        localStorage.setItem("deliveryTime", this.props.supportData.deliveryTime)
      }
    }
    if(typeof window!="undefined" && prevProps.customWebData && this.props.customWebData && prevProps.customWebData.apiKey !== this.props.customWebData.apiKey || this.props.location.pathname!=prevProps.location.pathname){
      if(this.props.supportData){
        localStorage.setItem("apiKey", "AIzaSyD2uLAOSaO79rd7zXUK_t1nEP5nRbRmuzo")
      }
      const { pathname } = this.props.location;
      this.props.getLinkInfo({partnerId: this.props.customWebData.partnerId, link: pathname.substring(1)})
    }
  }

  handleLayoutClick = (event) => {
    event.preventDefault();
    this.setState({ toggleNavbar: !this.state.toggleNavbar})
  }

  render() {
    console.log(this.props)
    const { pathname } = this.props.location
    let showing=true;
    if(this.props.location.pathname.indexOf(TERMS_CONDITIONS)>-1 || this.props.location.pathname.indexOf(PRIVACY)>-1){
      if(this.props.location.search.indexOf("android")>-1){
        showing=false;
      }
    }
    return (
      // <LocaleProvider locale={currentAppLocale.antd}>
        <Layout className="layout-container">
          <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${this.props.customWebData.gaCode}`}></script>

          </Helmet>
          <Helmet>
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', ${this.props.customWebData.gaCode});`}
          </script>
          </Helmet>
          {/* {showing && <Topbar location={this.props.location} toggleNavbar={this.state.toggleNavbar} />} */}
          <Layout >
            <AppRouter basePath={pathname} {...this.props}/>
          </Layout>
        </Layout>
      // </LocaleProvider>
    );
  }
}

// export default ;
function mapStateToProps(state) {
  const stateObj = state.bookings;
  return {
    customWebData: stateObj.customWebData,
    supportData: stateObj.supportData,
    linkInfo: stateObj.linkInfo
  };
}

function Main(props){
  
  const { pathname } = useLocation();
  
  return <App location={useLocation()} {...props}/>
}

export class AppNew extends Component {
  render() {
    console.log(this.props, "AppNew")
    return <Main {...this.props}/>
  }
}
export default connect(
  mapStateToProps,
  { getCustomWebData, getSupportData, getLinkInfo }
)(AppNew);