import React, { Component, createRef } from 'react';
// import Image2 from '../../image/static/offerings/Transfer_image.png';
// import Image3 from '../../image/static/offerings/Local_image.png';
// import Image4 from '../../image/static/offerings/Outstation_image.png';
// import Image5 from '../../image/static/offerings/Events_image.png';
// import Image6 from '../../image/static/offerings/Longterm_image.png';
// import Image7 from '../../image/static/offerings/Customize_icon.png';
import LazyLoadImage from '../../utils/LazyLoadImage';
import { connect } from "react-redux";
import actions from "../../customApp/redux/Booking/actions";
import { Link } from 'react-router-dom';
import {
  HOME_TRANSFER_URL,
  HOME_RENTAL_URL,
  HOME_OUTSTATION_URL,
  HOME_EVENT_URL,
  HOME_LONG_TERM_URL,
  HOME_CUSTOMIZE_URL,
  HOME_URL,
} from "../../utils/constants"
const { getCitiesServed } = actions;

class CitiesServed extends Component {
  constructor() {
    super();
    this.state = {
      allCategories: {},
      page: null
    }
    this.offeringContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    // if (typeof window != undefined && localStorage && window.location.pathname && this.props.customWebData && this.props.customWebData.partnerId) {
    //     this.props.getCitiesServed({ partnerId: this.props.customWebData.partnerId });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.citiesServed, "cities in update")
    if(this.props.pathname!=prevProps.pathname || this.props.customWebData!= prevProps.customWebData ||  this.props.linkInfo && this.props.linkInfo.page!= prevProps.linkInfo.page && Object.keys(this.props.linkInfo).length > 0){
      if (this.props.linkInfo.page && this.props.linkInfo.page != 3 && typeof window != undefined && localStorage && window.location.pathname && this.props.customWebData && this.props.customWebData.partnerId) {
        this.props.getCitiesServed({ partnerId: this.props.customWebData.partnerId, page: this.props.linkInfo.page });
      }
      if(this.props.linkInfo && this.props.linkInfo.page == 3){
        this.props.getCitiesServed({ partnerId: this.props.customWebData.partnerId, page: this.props.linkInfo.page, link: this.props.pathname.substring(1) });
      }
    }
  }

  render() {
    // console.log(this.props.citiesServed, "cities render")
    return (
      <div id="offering" className="offering bg-feature" ref={this.handleOfferingContainerMount}>
        <div className="text-align-center color-Black">{ this.props.linkInfo && this.props.linkInfo.page == 3 ? "Other servies" : "Cities Served"}</div>
        {this.props.citiesServed &&
          
             (
              <div>
                <div className="offerings_category_page--2-container">
                  {Array.isArray(this.props.citiesServed) &&
                    this.props.citiesServed.map(city => (
                      // <a className="color-Black" href={eachLink.link}>
                      <Link
                        to={{
                          pathname: `/${city.link}`,
                        }}
                        className="color-Black offering__links"
                      >
                        <div className={``}>
                          <div className="fS18e">{city.description}</div>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </div>
            )
        
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const stateObj = state.bookings;
  const appCommonState = state.appCommonReducer;

  return {
    customWebData: stateObj.customWebData,
    citiesServed: stateObj.citiesServed,
    linkInfo: stateObj.linkInfo,
  };
}

export default connect(
  mapStateToProps,
  { getCitiesServed }
)(CitiesServed);
