import React from 'react';
import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import App from './containers/App/App';

const PublicRoutes = ({ history }) => {
  return (
    // <App />
    <Router history={history}>
      <App />
    {/* //   <Routes>
    //     <Route
    //       exact
    //       path={'/'}
    //       element={App}
    //     />
    //   </Routes> */}

    </Router>
  );
};

export default PublicRoutes;
