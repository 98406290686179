import React, { Component, createRef } from 'react';
import Image1 from '../../image/static/footer/footer_bg_1366.png';
import Image2 from '../../image/static/footer/footer_bg_949.png';
import Image3 from '../../image/static/footer/footer_bg_604.png';
import Image4 from '../../image/static/footer/YelloPlate_footer_logo.png';
import Copowered from '../../image/static/copowerred-logo-new.png';
import {PRIVACY, TERMS_CONDITIONS} from '../../utils/constants'; 
import { isCustomWeb } from '../../utils/helper-functions';
import { Row, Col } from 'antd';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class HelpSection extends Component {
  constructor() {
    super();
    this.footerContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.footerContainer.current, 'footer');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.footerContainer.current, 'footer');
  }

  handleFooterContainerMount = (refVal) => {
    if(refVal) {
      this.footerContainer.current = refVal;
    }
  }

  render() {
    return (
      <div ref={this.handleFooterContainerMount}>
        <Row>
          <Col className="mobile-background sm-help-links" xs={24} md={24}>   
            <div className=" color-white padding100">
              <p className="fS42 paddingBtm30 color-white">Get Help</p>
              <p className="paddingBtm30 fS17e borderBtm-common">Support is just a few taps away. Please fill the above Contact us form.</p>
              <Row className="paddingTop30 borderBtm-common paddingBtm30 fS17e">
                <Col md={2}><a className="color-white" href="#offering">Offerings</a></Col>
                <Col md={2}><a className="color-white" href="#feature">Features</a></Col>
                <Col md={2}><a className="color-white" href="#fleet">Fleet</a></Col>
                <Col md={2}><a className="color-white" href="#aboutUs">About Us</a></Col>
                <Col md={3}><a className="color-white" href="#downloadApp">Download App</a></Col>
                <Col md={2}><a className="color-white" href="#contactUs">Contact Us</a></Col>
              </Row>
              <Row className="paddingTop30 help--sub-section-allignment">
                {!isCustomWeb() && <Col md={5}><img alt="Yellow Plate : India’s First Car Rental Marketplace." data-src={Image4} /></Col>}
                {!isCustomWeb() && <Col md={8}><p className="fS17e">Copyright &#169; 2019 Idea Transport World Pvt. Ltd.</p></Col>}
                <Col md={isCustomWeb() ? 1: 0}><p className="fS17e" ><img src={Copowered} height="45" className="marginBtm5"/></p></Col>
                <Col md={isCustomWeb() ?  11: 5}><p className="fS17e" ><a href={TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer" className="color-white" >Terms & Conditions</a></p></Col>
                <Col md={!isCustomWeb() ? 12: 6}><p className="fS17e" ><a href={PRIVACY} target="_blank" rel="noopener noreferrer" className="color-white" >Privacy Policy</a></p></Col>
              </Row>
            </div>
            <Row>
              <Col md={24} xs={24}>
                <img
                  alt="Car Rental Marketplace"
                  data-src-set={`${Image1} 1366w, ${Image2} 949w, ${Image3} 604w`}
                  data-src={Image1}
                  className="help_us-img"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
