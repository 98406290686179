import React, { Component } from 'react';
import {Col} from 'antd';
import { Link } from 'react-router-dom';

export default class CustomSidebar extends Component {

  renderLink(optionDetails) {
    return (
      <div key={optionDetails.name + optionDetails.url} className="side-bar--padding">
        <Link className={optionDetails.bookingType === this.props.bookingType ? ' sidebar-active': 'sidebar-opt'}
          to={{pathname: optionDetails.pathname, state: optionDetails.state}}
        >
          <span className="sidebar-icon">
            <span className={optionDetails.bookingType === this.props.bookingType ? 'paddingLeft35 fS16' : 'paddingLeft10 fS14'}>
              { optionDetails.name }
            </span>
          </span>
        </Link>
      </div>
    );
  }

  render() {
    const {classForSideBar, sideBarOptions} = this.props;
    return (
      <Col xs={0} md={24} sm={24} lg={24}>
        <div className={classForSideBar}>
          {
            sideBarOptions.map(optionsDetails => {
              return this.renderLink(optionsDetails);
            })
          }
        </div>
      </Col>
    );
  }
}
