import actions from './location-actions';

const initState = {
  tripLocationProg: undefined,
  tripLocationInfo: undefined,
  tripLocationError: undefined
};

const handleMissingRouteInProg = (_, action) => ({
  tripLocationProg: true,
  tripLocationInfo: undefined,
  tripLocationError: undefined
});

const handleMissingRouteSuccess = (_, action) => ({
  tripLocationProg: undefined,
  tripLocationInfo: action.routeInfoSuccess,
  tripLocationError: undefined
});

const handleMissingRouteFailure = (_, action) => ({
  tripLocationProg: undefined,
  tripLocationInfo: undefined,
  tripLocationError: action.errorInfo
});

const handleMissingRouteUnload = () => ({
  tripLocationProg: undefined,
  tripLocationInfo: undefined,
  tripLocationError: undefined
});

const getHandlerForAction = function(actionType) {
  let handler;
  switch(actionType) {
    case actions.GET_MISSING_ROUTE_PROGRESS:
      handler = handleMissingRouteInProg;
      break;
    case actions.GET_MISSING_ROUTE_SUCCESS:
      handler = handleMissingRouteSuccess;
      break;
    case actions.GET_MISSING_ROUTE_FAILURE:
      handler = handleMissingRouteFailure;
      break;
    case actions.GET_MISSING_ROUTE_UNLOAD:
      handler = handleMissingRouteUnload;
      break;
    default:
      handler = null;
      break;
  }

  return handler;
};

export default function (state = initState, action) {
  const handler = getHandlerForAction(action.type);
  if (!handler) {
    return state;
  }
  return { ...state, ...handler(state, action) };
};
