import React, { Component, createRef } from 'react';
import Image1 from '../../image/static/about/AboutUs_graphic.png';
import Image2 from '../../image/static/about/YellowPlateBrand_image.png';
import Image3 from '../../image/static/about/YelloPlate_logo.png';

import { Row } from 'antd';
import LazyLoadImage from '../../utils/LazyLoadImage';

export default class AboutSection extends Component {

  constructor() {
    super();
    this.aboutContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    this.lazyLoadImage.addListenerForLazyLoadingImages(this.aboutContainer.current, 'about');
  }

  componentWillUnmount() {
    this.lazyLoadImage.removeLazyLoadListener(this.aboutContainer.current, 'about');
  }

  handleAboutContainerMount = (refVal) => {
    if(refVal) {
      this.aboutContainer.current = refVal;
    }
  }

  render() {
    return (
      <div id="aboutUs" className="about-us--container bcwhite" ref={this.handleAboutContainerMount}>
        <div className="color-Black">About Us</div><br />
        <div className="about-us--text-image-container">
          <div>
            <img data-src={Image1} alt="Yellow Plate Is On A Mission To Digitize, Enable And Empower Supply, Demand, Services And Product Stakeholders Connected With Commercial Vehicles Across Both Passengers And Goods Transport Segment In India. The First Phase Includes Digitising, Enabling And Empowering Demand And Supply Stakeholders In The Car Rental Segment In India."/>
          </div>

          <div className="fS18e">
            We wondered why most start-ups build technology, Aggregate Supply, and Demand and aim to
            become the largest platform/vendor in the market, aided by scalable technology, large manpower,
            incentives to supply and discounts to customers; leading to substantial losses, single-digit market
            share and large operational and quality issues.
            <br /><br />
            We decided to reverse this trend for the necessity Transportation/Commercial vehicles segment.
            <br /><br />
            We debated, can we create an inclusive platform to technologically enable and empower India’s
            massive unorganized supply stakeholders involved in Transportation business; leading to more
            choices, better delivery and substantial cost savings for demand segment.
            <br /><br />
            Yellow Plate is a result of this thought.
            <br /><br />
          </div>
        </div>

        <div className="fS18e marginTop10">

          Yellow Plate is solving one of the most complex challenges concerning commercial cars. The segment
          is unorganized involving multiple stakeholders across supply, demand, services, and trade.
          
          Yellow Plate is organizing this massively unorganized commercial car rental segment. It via its
          technology platform is enabling millions of businesses including intermediaries engaged in providing
          car rental services; leading to demand segments receiving tech-enabled services from their own
          service providers. Yellow Plate subsequently empowers every stakeholder across supply and
          demand segment stakeholders by inter-connecting and integrating with its marketplace.
          <br /><br />

          This enables supply segment stakeholders to adapt to changing market dynamics, offer technology-
          enabled services to their customers, increase bookings, commission and incentive income and get
          new customers. In addition, they can improve collaboration via effective networking to further
          increase supply, demand, commission and incentive incomes. 
          <br /><br />
          Demand segment stakeholders get increased convenience, more vendors choices offering tech-
          enabled services and an ability to engage with the service provider(s) of its choice at the most
          competitive prices; leading to substantial cost savings. 

        </div>

        <Row type="flex" className="bg-brand brand--container">
          <div className="brand--decription">
            <div className="fS31">Our Brand Name</div>
            <div className="fS18e">
              Yellow Plate is synonyms with commercial vehicles. Every commercial vehicle in India bears Yellow
              Number plate. Since we are creating an ecosystem to enable and empower all stakeholders
              connected with commercial cars across demand, supply, services or trade etc., there could not have
              been a better brand name than ‘Yellow Plate’.
            </div>
            <div className="paddingTop10"><img data-src={Image3} alt="Yellow Plate : India’s First Car Rental Marketplace."/></div>
          </div>
          <div className="brand--image">
            {/* data-match-media-query mimcs the media query defined in the CSS */}
            <img alt="Every Commercial Vehicle In India Has Yellow Number Plate. Its The Only Common Link Across All Commercial Vehicle Within Both Passengers And Goods Transport. We Are Solving Complex Challenges Within Commercial Vehicles/Transport Segment Via An Enabling And Empowering Eco-System. We Named It Yellow Plate." data-src={Image2} data-match-media-query="screen and (max-width: 1100px)" className="wd--100P-ht--100P" />
          </div>
        </Row>

      </div>
    );
  }
}
