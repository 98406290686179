import React, { Component } from 'react';
// import { Icon } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons"

import Modal from '../../../components/uielements/CustomModal';

export default class CategoryDetailsModal extends Component {

  constructor(props) {
    super(props);

    //Common for Luxury Base,Mid & High
    const luxurySedan = ["BMW", "Mercedes", "Audi"];

    this.carCategories = {
      "hatchback": ["Indica", "Swift"],
      "sedan": ["Dzire", "Xcent", "Amaze", "Etios"],
      "executivesedan": ["Sunny", "City", "Scala"],
      "premiumsedan": ["Camry", "Altis"],
      "SUV": ["Ertiga", "Enjoy", "TUV"],
      "executivesuv": ["Innova"],
      "premiumsuv": ["XUV 500", "Innova Crysta"],
      "luxurysedan - base": luxurySedan,
      "luxurysedan - mid": luxurySedan,
      "luxurysedan - high": luxurySedan,
      "luxurysuv": ["BMW", "Mercedes", "Audi", "Fortuner"]
    }
  }

  handleClick = () => {
    const {selectedCarCategory, categoryString} = this.props;
    const carsForCategory = this.carCategories[selectedCarCategory[categoryString].key];

    let modalContent;
    if(carsForCategory) {
      modalContent = (
        <div className="font-size16">
          <div className="borderBtm-common">
            <span>{selectedCarCategory[categoryString].label}</span>
          </div>
          <div className="paddingTop10">
            <ul className="list--circle marginLeft10 fS13 inline-block text-align-left">
              {
                carsForCategory.map(carName => <li key={carName}>{carName}</li>)
              }
            </ul>
          </div>
        </div>
      );
    } else {
      modalContent = (
        <div className="font-size16">
          <span> Select a car category to view the cars available in that category </span>
        </div>
      );
    }

    Modal.info({
      title: "Car Details",
      icon: null,
      className: "custom-modal modal-align-title",
      content: modalContent,
      maskClosable: true,
      okText: "OK",
      okButtonProps: { block: true }
    });
  }

  render() {
    return (
      <span className="ant-input-group-addon">
        Category <InfoCircleOutlined className="pointer" type="info-circle-o" onClick={this.handleClick}></InfoCircleOutlined>
      </span>
    )
  }
}
