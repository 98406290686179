import React, { Component, createRef } from 'react';
// import Image2 from '../../image/static/offerings/Transfer_image.png';
// import Image3 from '../../image/static/offerings/Local_image.png';
// import Image4 from '../../image/static/offerings/Outstation_image.png';
// import Image5 from '../../image/static/offerings/Events_image.png';
// import Image6 from '../../image/static/offerings/Longterm_image.png';
// import Image7 from '../../image/static/offerings/Customize_icon.png';
import LazyLoadImage from '../../utils/LazyLoadImage';
import { connect } from "react-redux";
import actions from "../../customApp/redux/Booking/actions";
import { Link } from 'react-router-dom';
import {
  HOME_TRANSFER_URL,
  HOME_RENTAL_URL,
  HOME_OUTSTATION_URL,
  HOME_EVENT_URL,
  HOME_LONG_TERM_URL,
  HOME_CUSTOMIZE_URL,
  HOME_URL,
} from "../../utils/constants"
const { getLinks } = actions;

class Offering extends Component {
  constructor() {
    super();
    this.state = {
      allCategories: {},
      page: 1
    }
    this.offeringContainer = createRef();
    this.lazyLoadImage = new LazyLoadImage();
  }

  componentDidMount() {
    if (typeof window != undefined && localStorage && window.location.pathname && this.props.customWebData && this.props.customWebData.partnerId) {

      const pathname = window.location.pathname;
      console.log(window.location.pathname, "pathname", pathname.split("/"), pathname.split("/").length)
      //   if(pathname!="", pathname!=HOME_URL && pathname!==HOME_LONG_TERM_URL && pathname!==HOME_TRANSFER_URL && pathname!=HOME_OUTSTATION_URL && pathname!=HOME_RENTAL_URL && pathname.split("/").length==1){
      //     this.props.getLinks({page: this.state.page, partnerId: localStorage.getItem('userId') });
      //     // this.setState({page: 2})
      //   }
      // else{
      //   if(pathname=="/"){
      //     this.props.getLinks({page: this.state.page, partnerId: localStorage.getItem('userId') });
      //     // this.setState({page: 1})
      //   }
      //   this.props.getLinks({page: this.state.page, partnerId: localStorage.getItem('userId') });
      //   // this.setState({page: 3})
      // }
      if (pathname.split("/").length == 2) {
        if (pathname != "", 
            pathname != HOME_URL && 
            pathname !== HOME_LONG_TERM_URL && 
            pathname !== HOME_TRANSFER_URL && 
            pathname != HOME_OUTSTATION_URL && 
            pathname != HOME_RENTAL_URL && 
            pathname != HOME_EVENT_URL
          ) {
          if(this.props.linkInfo && this.props.linkInfo.page)
          this.props.getLinks({ page: this.props.linkInfo.page, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
          this.setState({ page: this.props.linkInfo.page })
        } else {
          this.props.getLinks({ page: 1, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
          this.setState({ page: 1 })
        }
      } else {
        if (this.props.linkInfo) {
          this.props.getLinks({ page: this.props.linkInfo.page, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
          this.setState({ page: this.props.linkInfo.page })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let allCategoriesLinks = {}
    if (this.props.linksList != prevProps.linksList && this.props.linksList && this.props.linksList.length > 0) {
      this.props.linksList.map(link => {
        if (allCategoriesLinks[link["description"]] == undefined) {
          allCategoriesLinks[link["description"]] = [link]
        } else {
          allCategoriesLinks[link["description"]].push(link)
        }

      })
      console.log(allCategoriesLinks, "allCategoriesLinks")
      this.setState({
        allCategories: allCategoriesLinks
      })
    }
    if (this.props.pathname != prevProps.pathname || this.props.customWebData.partnerId!=prevProps.customWebData.partnerId || this.state.page != prevState.page) {
      if (typeof window != undefined && localStorage && window.location.pathname && this.props.customWebData && this.props.customWebData.partnerId) {

        const pathname = window.location.pathname;
        if (pathname.split("/").length == 2) {
          if (pathname != "", 
              pathname != HOME_URL && 
              pathname !== HOME_LONG_TERM_URL && 
              pathname !== HOME_TRANSFER_URL && 
              pathname != HOME_OUTSTATION_URL && 
              pathname != HOME_RENTAL_URL &&
              pathname != HOME_EVENT_URL
            ) {
            this.props.getLinks({ page: 2, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
            this.setState({ page: 2 })
          } else {
            this.props.getLinks({ page: 1, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
            this.setState({ page: 1 })
          }
        } else {
          if (pathname.split("/").length == 3) {
            this.props.getLinks({ page: 3, partnerId: this.props.customWebData.partnerId, link: pathname.substring(1) });
            this.setState({ page: 3 })
          }
        }
      }
    }
  }

  render() {
    return (
      <div id="offering" className="offering bcwhite" ref={this.handleOfferingContainerMount}>
        <div className="text-align-center color-Black">Offerings</div>
        {this.state.page == 1 && <div className="color-Black offering-container margin20">
          {this.state.page == 1 && this.props.linksList && this.props.linksList.length > 0 && this.props.linksList.map((eachLink, index) => (
            // <a className="color-Black" href={eachLink.link}>

            <div className={`box-shadow-common offering-container__item offering-container__item--position-${index} bg-light-grey`}>
              <div className="padding10">
                <p className="fS18e border-static-small">
                  <Link
                    to={{
                      pathname: `/${eachLink.link}`,
                    }}
                    className="color-Black"
                  >
                    {eachLink.description}
                  </Link>
                </p>
                <br />
                <p className="fS18 ">
                  {eachLink.paragraph}
                </p>
              </div>
            </div>

          ))}
        </div>
        }
        {this.state.page == 2 && this.props.linksList &&
          Object.keys(this.state.allCategories).map(category => {
            return (
              <div>
                <div className="offerings_category_page--2_subheader">{category.replace(/_/g, " ")}</div>
                <div className="offerings_category_page--2-container">
                  {
                    this.state.allCategories[category].map((eachLink, index) => (
                      // <a className="color-Black" href={eachLink.link}>
                      <Link
                        to={{
                          pathname: `/${eachLink.link}`,
                        }}
                        className="color-Black offering__links"
                      >
                        <div className={``}>
                          <div className="fS18e">{eachLink.paragraph}</div>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </div>
            )
          })
        }
        {this.state.page == 3 && this.props.linkInfo && 
             (
              // <a className="color-Black" href={eachLink.link}>
              <div
                className="color-Black marginTop10"
              >
                <div className={`box-shadow-common offering-container-page--3 margin20 bg-light-grey`}>
                  <div className="padding10">
                    <p className="fS18e border-static-small">{this.props.linkInfo.description}</p>
                    <br />
                    <p className="fS18 ">
                      {this.props.linkInfo.paragraph}
                    </p>
                  </div>
                </div>
              </div>
            )
          // }
        // })
      }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const stateObj = state.bookings;
  const appCommonState = state.appCommonReducer;

  return {
    customWebData: stateObj.customWebData,
    links: stateObj.links,
    linksList: stateObj.linksList,
    linkInfo: stateObj.linkInfo,
  };
}

export default connect(
  mapStateToProps,
  { getLinks }
)(Offering);
